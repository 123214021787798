import { chartTooltip } from "../../utils"

export const barChartOptions = {
    legend: {
      position: "bottom",
      labels: {
        padding: 30,
        usePointStyle: true,
        fontSize: 12
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            lineWidth: 2,
            color: "rgba(0,0,0,0.1)",
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            callback: function(value) {
                  if (Math.floor(value) === value) {
                      return value;
                  }
              },
            min: 0,
            // max: 100,
            padding: 20
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ]
    },
    tooltips: chartTooltip
  };

  export const radarChartOptions = {
    legend: {
      position: "bottom",
      labels: {
        padding: 30,
        usePointStyle: true,
        fontSize: 12
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scale: {
      ticks: {
        display: false
      }
    },
    tooltips: chartTooltip
  };