import * as types from './type';

const state = {
  claim: ""
};

const mutations = {
  [types.MUTATE_CLAIM]: (state, value) => {
    state.customer = value
  }
};

const actions = {
};

const getters = {
};

export default {
  state,
  mutations,
  actions,
  getters
}