<template>
  <v-card flat>
    <isttoolbar
      title="Configuration"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer />
      <v-btn
        v-show="$route.query.statusPage === 'view'"
        @click="edit"
        outlined
        class="mx-1"
        min-width="100px"
        >Edit</v-btn
      >
      <v-btn
        v-show="$route.query.statusPage !== 'view'"
        @click="save"
        outlined
        class="mx-1"
        min-width="100px"
        >Save</v-btn
      >
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>
    <isttoolbar
      :title="
        $route.query.statusPage === 'view' ? 'View Parameter' : 'Edit Parameter'
      "
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>
    <!-- View page -->
    <v-form class="mb-8" :readonly="$route.query.statusPage === 'view'">
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Params Group
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Params Group"
            ref="paramsGroup"
            v-model="param.paramsGroup"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Params
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Params"
            ref="params"
            v-model="param.params"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Table Values Gas List -->
      <v-row align="center" class="">
        <v-col>
          <v-data-table
            class="elevation-1 mt-8"
            :headers="headersListGas"
            :items="itemsGas"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>List Gas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template
              v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item, 'model')" v-show="$route.query.statusPage !== 'view'">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item, 'model')" v-show="$route.query.statusPage !== 'view'">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data></template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Table Values Certificate -->
      <v-row>
        <v-col>
          <v-data-table
            class="elevation-1 mt-8"
            :headers="headersCertificate"
            :items="certificateList"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Certificate List</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template
              v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item, 'certificate')" v-show="$route.query.statusPage !== 'view'">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item, 'certificate')" v-show="$route.query.statusPage !== 'view'">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data></template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Table Values Calibration -->
      <v-row>
        <v-col>
          <v-data-table
            class="elevation-1 mt-8"
            :headers="headersCalibration"
            :items="calibrationList"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Calibration List</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template
              v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item, 'calibration')" v-show="$route.query.statusPage !== 'view'">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item, 'calibration')" v-show="$route.query.statusPage !== 'view'">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data></template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Table Values Inspection -->
      <v-row>
        <v-col>
          <v-data-table
            class="elevation-1 mt-8"
            :headers="headersInspection"
            :items="inspectionList"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Inspection List</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template
              v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item, 'inspection')" v-show="$route.query.statusPage !== 'view'">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item, 'inspection')" v-show="$route.query.statusPage !== 'view'">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data></template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Values
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="9" align-self="start" class="pa-1">
          <v-textarea
            hide-details="auto"
            v-model="param.values"
            solo
            flat
            outlined
            dense
            ref="values"
            name="input-7-4"
            label="Values"
            :rows="20"
            :rules="[rules.required]"
          ></v-textarea>
        </v-col>
      </v-row> -->
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">Status</span>
        </v-col>
        <v-col cols="8" class="pa-1">
          <v-switch
            ref="status"
            name="status"
            color="success"
            v-model="param.status"
            inset
            :label="`${param.status ? 'Active' : 'Inactive'}`"
          ></v-switch>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="mt-6 mb-6">
      <v-row>
        <v-col cols="6">
          <v-row align="center" class="ml-2 mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">Created By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="create"
                v-model="param.createdBy"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="createDate"
                v-model="param.createdAt"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="ml-2 mr-2"> </v-row>
        </v-col>
        <v-col cols="6">
          <v-row align="center" class="mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">Updated By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="close"
                v-model="param.updatedBy"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="closeDate"
                v-model="param.updatedAt"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mr-2"> </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>

    <!-- DIALOG -->
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Saved</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Saved</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- DIALOG EDIT -->

    <v-dialog v-model="dialogDelete" persistent width="unset" min-width="290px">
      <v-card min-width="290px">
        <v-card-title class="justify-center" style="word-break: inherit;"
          >Are you sure want to Delete ?</v-card-title
        >
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            min-width="100px"
            @click="deleteItemConfirm"
            outlined
            >Yes</v-btn
          >
          <v-btn color="red" min-width="100px" @click="closeDelete" outlined
            >No</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <editProductDialog
      v-show="dialogEdit"
      :dialog="dialogEdit"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    />

    <editInspectionDialog
      v-show="dialogEditInspection"
      :dialog="dialogEditInspection"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    />

    <editCalibrationDialog
      v-show="dialogEditCalibration"
      :dialog="dialogEditCalibration"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    />

    <editCertificationDialog
      v-show="dialogEditCertificate"
      :dialog="dialogEditCertificate"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    ></editCertificationDialog>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { firebaseDB } from "../../../firebase";
import editProductDialog from "@/components/dialog/NewParameter/editProductDialog";
import editInspectionDialog from "@/components/dialog/NewParameter/editInspectionDialog.vue";
import editCalibrationDialog from "@/components/dialog/NewParameter/editCalibrationDialog.vue";
import editCertificationDialog from "../../../components/dialog/NewParameter/editCertificationDialog.vue";

export default {
  components: {
    isttoolbar,
    editProductDialog,
    editInspectionDialog,
    editCalibrationDialog,
    editCertificationDialog,
  },
  props: {
    source: String,
  },

  data: () => ({
    param: {
      paramsGroup: "",
      params: "",
      values: "",
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    //semisal uti udah ada , headerListGas bisa di ganti ke model list gas , tapi masih belum modular , jadi ada yang perlu di ubah
    headersListGas: [
      {
        text: "Gas List",
        align: "start",
        value: "type",
        width: "auto",
      },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersInspection: [
      { text: "Inspection Name", align: "start", value: "reportType" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersCertificate: [
      { text: "Certificate Name", align: "start", value: "certificateType" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersCalibration: [
      { text: "No Tube", align: "start", value: "noTube" },
      { text: "Gas Type", value: "gasType" },
      { text: "Concentration", value: "concentration" },
      { text: "Unit", value: "unit" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    itemsGas: [],
    inspectionList: [],
    calibrationList: [],
    certificateList: [],
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    dialogEdit: false,
    dialogEditCalibration: false,
    dialogEditInspection: false,
    dialogEditCertificate: false,
    dialogDelete: false,
    selectEditTarget: "",
    selectedDeletedItem: "",
    selectedDeletedItemObj: {},
    selectedValue: "",
    editedItem: [],
  }),
  computed: {
    form() {
      return {
        paramsGroup: this.param.paramsGroup,
        params: this.param.params,
        values: this.param.values,
      };
    },
  },
  created() {
    this.dialog.success = false;
    this.dialog.error = false;
    this.dialog.show = true;
    let newData = {};
    firebaseDB
      .doc(`/params/${this.$route.query.paramsId}`)
      .get()
      .then((doc) => {
        newData = doc.data();
        newData.paramsId = doc.id;
        let original = doc.data().values;
        newData.values = this.convertData(original);
        newData.createdAt = doc
          .data()
          .createdAt.match(/([^T]+)/)[0]
          .split("-")
          .reverse()
          .join("/");
        newData.updatedAt =
          doc.data().updatedAt !== ""
            ? doc
                .data()
                .updatedAt.match(/([^T]+)/)[0]
                .split("-")
                .reverse()
                .join("/")
            : "";

        let getParsedData = JSON.parse(newData.values);

        if (getParsedData.listGas) this.itemsGas = getParsedData.listGas;
        if (getParsedData.certificate)
          this.certificateList = getParsedData.certificate;
        if (getParsedData.inspectionReport)
          this.inspectionList = getParsedData.inspectionReport;
        if (getParsedData.listCalibration)
          this.calibrationList = getParsedData.listCalibration;

        this.param = newData;
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = false;
      })
      .catch(() => {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = false;
      });
  },
  methods: {
    deleteItem(item, str) {
      this.dialogDelete = true;
      this.selectedDeletedItemObj = item;
      this.selectedDeletedItem = str;
    },
    deleteItemConfirm() {
      let previousData = localStorage.getItem("userInput");

      if (this.selectedDeletedItem == "model")
        this.itemsGas = this.itemsGas.filter(
          (el) => el.id !== this.selectedDeletedItemObj.id
        );
      if (this.selectedDeletedItem == "inspection")
        this.inspectionList = this.inspectionList.filter(
          (el) => el.id !== this.selectedDeletedItemObj.id
        );
      if (this.selectedDeletedItem == "calibration")
        this.calibrationList = this.calibrationList.filter(
          (el) => el.id !== this.selectedDeletedItemObj.id
        );
      if (this.selectedDeletedItem == "certificate")
        this.certificateList = this.certificateList.filter(
          (el) => el.id !== this.selectedDeletedItemObj.id
        );

      if (previousData) {
        let dataBefore = JSON.parse(previousData);

        if (this.modelList.length > 0 && this.selectedValue === "Gas") {
          dataBefore.listGas = this.modelList;
          dataBefore.totalGas = this.modelList.length;
        }
        if (this.calibrationList.length > 0) {
          dataBefore.listCalibration = this.calibrationList;
          dataBefore.totalCalibration = this.calibrationList.length;
        }
        if (this.certificateList.length > 0) {
          dataBefore.certificate = this.certificateList;
          dataBefore.totalCertificate = this.certificateList.length;
        }
        if (this.inspectionList.length > 0) {
          dataBefore.inspectionReport = this.inspectionList;
          dataBefore.totalInspection = this.inspectionList.length;
        }
        localStorage.setItem("userInput", JSON.stringify(dataBefore));
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedDeletedItem = "";
      this.selectedDeletedItemObj = {};
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    editItem(item, str) {
      this.selectEditTarget = str;

      if (str == "model") {
        this.editedItem = {
          id: item.id,
          type: item.type,
        };
        this.dialogEdit = true;
      }
      if (str == "inspection") {
        this.editedItem = {
          id: item.id,
          reportType: item.reportType,
        };
        this.dialogEditInspection = true;
      }
      if (str == "calibration") {
        this.editedItem = {
          id: item.id,
          noTube: item.noTube,
          gasType: item.gasType,
          concentration: item.concentration,
          unit: item.unit,
        };
        this.dialogEditCalibration = true;
      }
      if (str == "certificate") {
        this.editedItem = {
          id: item.id,
          certificateType: item.certificateType,
        };
        this.dialogEditCertificate = true;
      }
    },
    saveEditedItem() {
      let index = 0;
      let previousData = localStorage.getItem("userInput");

      if (this.selectEditTarget === "model") {
        index = this.itemsGas.findIndex((el) => el.id == this.editedItem.id);

        this.itemsGas.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          type: "",
        };
        this.dialogEdit = false;
      }

      if (this.selectEditTarget === "inspection") {
        index = this.inspectionList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.inspectionList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          reportType: "",
        };
        this.dialogEditInspection = false;
      }

      if (this.selectEditTarget == "calibration") {
        index = this.calibrationList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.calibrationList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          noTube: "",
          gasType: "",
          concentration: "",
          unit: "",
        };
        this.dialogEditCalibration = false;
      }

      if (this.selectEditTarget == "certificate") {
        index = this.certificateList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.certificateList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          certificateType: "",
        };
        this.dialogEditCertificate = false;
      }

      if (previousData) {
        let dataBefore = JSON.parse(previousData);

        dataBefore.listGas = this.itemsGas;
        dataBefore.totalGas = this.itemsGas.length;
        dataBefore.listCalibration = this.calibrationList;
        dataBefore.totalCalibration = this.calibrationList.length;
        dataBefore.certificate = this.certificateList;
        dataBefore.totalCertificate = this.certificateList.length;
        dataBefore.inspectionReport = this.inspectionList;
        dataBefore.totalInspection = this.inspectionList.length;

        localStorage.setItem("userInput", JSON.stringify(dataBefore));
      }
    },
    closeEditItem() {
      this.dialogEdit = false;
      this.dialogEditCalibration = false;
      this.dialogEditCertificate = false;
      this.dialogEditInspection = false;
    },
    edit() {
      this.$router.replace({
        name: "parameter-configuration-edit",
        query: { paramsId: this.$route.query.paramsId, statusPage: "edit" },
      });
    },
    back() {
      localStorage.removeItem("userInput");
      localStorage.removeItem("selectedItemValues");

      this.$router.replace({
        name: "parameter-configuration-list",
      });
    },
    async save() {
      // Object.keys(this.form).forEach((f) => {
      //   if (!this.form[f]) this.formHasErrors = true;
      //   this.$refs[f].validate(true);
      // });
      if (!this.formHasErrors) {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        await this.doAdd();
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      let original = this.param.values;
      let stringify = JSON.stringify(original);
      let remove = stringify.replace(/\\n/g, "");
      const newData = {
        paramsGroup: this.param.paramsGroup,
        params: this.param.params,
        values: remove,
        status: this.param.status,
        updatedAt: new Date().toISOString(),
        updatedBy: "Admin",
      };
      firebaseDB
        .doc(`/params/${this.$route.query.paramsId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Params not found";
          }
          localStorage.removeItem("userInput");
          localStorage.removeItem("selectedItemValues");
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Something Went Wrong";
        });
    },
    convertData(item) {
      let data = JSON.parse(item);
      if (typeof data === "string" || typeof data === "number") {
        return data;
      } else {
        let stringify = JSON.stringify(data, null, 4);
        return stringify;
      }
    },
  },
};
</script>
