const blue = "#00529b";
const white = "#ffffff";


export const chartTooltip = {
    backgroundColor: white,
    titleFontColor: blue,
    borderColor: blue,
    borderWidth: 0.5,
    bodyFontColor: blue,
    bodySpacing: 10,
    xPadding: 30,
    yPadding: 30,
    cornerRadius: 10
  }

  