//OBJECT INSURED
import * as types from './type';
const defaultState = () => {
    return {
        toObjectInsuredList: [],
    }
  }
  
  const state = defaultState()
  const mutations = {
  [types.MUTATE_TO_OBJECT_INSURED_LIST]: (state, value) => {
    state.toObjectInsuredList = value
  },
};
  export default {
    state,
    mutations,
  }
// const defaultState = () => {
//     return {
// toObjectInsuredList:[],
// }
// }

