<template>
    <div>
        
    </div>
</template>

<script>
export default {
    name: 'AdminIndosafetymarineOptionProductTypeEdit',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>