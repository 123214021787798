<template>
  <v-card flat>
    <v-alert
      v-if="errorMessage"
      type="warning"
      variant="outlined"
      border="left"
      icon="mdi-alert-circle-outline"
    >
      {{ errorMessage }}
    </v-alert>
    <isttoolbar
      title="Configuration"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer />
      <v-btn @click="save" outlined class="mx-1" min-width="100px">Save</v-btn>
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>
    <isttoolbar
      title="New Parameter"
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>

    <v-form>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Params Group / Brand Product
            <span class="error--text" v-if="this.param.paramsGroup.length == 0"
              >*</span
            >
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Params Group"
            ref="paramsGroup"
            v-model="param.paramsGroup"
            solo
            flat
            outlined
            dense
            class="ma-0"
            @input="handleInputPG"
          ></v-text-field>
          <span v-if="errorPG" class="error--text"
            >Field Tidak Boleh Kosong!</span
          >
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Params / Product Series
            <span class="error--text" v-if="this.param.params.length == 0"
              >*</span
            >
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Params"
            ref="params"
            v-model="param.params"
            solo
            flat
            outlined
            dense
            class="ma-0"
            @input="handleInputPS"
          ></v-text-field>
          <span v-if="errorPS" class="error--text"
            >Field Tidak Boleh Kosong!</span
          >
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Product Models
            <span class="error--text" v-if="this.menuOpen.length == 0">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-menu v-model="menuOpen" :close-on-content-click="false">
            <template v-slot:activator="{ on, props }">
              <v-btn v-on="on" v-bind="props">
                {{ selectedValue ? selectedValue : "Select an item" }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="selectItem(item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-container max-width="600">
        <div v-if="selectedValue">
          <v-row>
            <v-col cols="6" v-show="selectedValue == 'Gas'">
              <!-- table gas list -->
              <v-data-table
                class="elevation-1 mt-8"
                :headers="headersModels"
                :items="modelList"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Model Gas List</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        min-width="100px"
                        outlined
                        @click="openAddModal($event)"
                        >Add {{ selectedValue }}</v-btn
                      >
                    </v-card-actions>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item, 'model')">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item, 'model')"
                    >{{ item }} mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>
            <v-col cols="6" class="mt-4" v-show="selectedValue == 'UTI Meter'">
              <!-- Uti Meter Input -->
              <v-row align="center" class="mt-8 ml-2">
                <v-col cols="5" class="pa-1">
                  <span class="title">
                    Tape Length
                    <span class="error--text" v-if="this.tapeLength.length == 0"
                      >*</span
                    >
                  </span>
                </v-col>
                <v-col cols="6" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    label="Tape Length"
                    ref="tapeLength"
                    v-model="param.tapeLength"
                    solo
                    flat
                    outlined
                    dense
                    class="ma-0"
                    @input="handleInputTL"
                  ></v-text-field>
                  <span v-if="errorUTITapeLength" class="error--text"
                    >Field Tape Length Tidak Boleh Kosong!</span
                  >
                </v-col>
              </v-row>
              <v-row align="center" class="ml-2">
                <v-col cols="5" class="pa-1">
                  <span class="title">
                    Valve Connector
                    <span
                      class="error--text"
                      v-if="this.valveConector.length == 0"
                      >*</span
                    >
                  </span>
                </v-col>
                <v-col cols="6" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    label="Valve Conector"
                    ref="valveConector"
                    v-model="param.valveConector"
                    solo
                    flat
                    outlined
                    dense
                    class="ma-0"
                    @input="handleInputVC"
                  ></v-text-field>
                  <span v-if="errorUTIValveConector" class="error--text"
                    >Field Valve Conector Tidak Boleh Kosong!</span
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <!-- table inspection list -->
              <v-data-table
                class="elevation-1 mt-8"
                :headers="headersInspection"
                :items="inspectionList"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Inspection List</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        min-width="100px"
                        outlined
                        @click="openAddModal($event)"
                        >Add Inspection</v-btn
                      >
                    </v-card-actions>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, 'inspection')"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item, 'inspection')">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" v-show="this.selectedValue == 'Gas'">
              <!-- table calibration -->
              <v-data-table
                class="elevation-1 mt-8"
                :headers="headersCalibration"
                :items="calibrationList"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Calibration List</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        min-width="100px"
                        outlined
                        @click="openAddModal($event)"
                        >Add Calibration</v-btn
                      >
                    </v-card-actions>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, 'calibration')"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item, 'calibration')">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>
            <v-col cols="6" v-show="this.selectedValue == 'UTI Meter'">
              <!-- table calibration -->
              <v-data-table
                class="elevation-1 mt-8"
                :headers="headersCalibrationUti"
                :items="calibrationList"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Calibration List</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        min-width="100px"
                        outlined
                        @click="openAddModal($event)"
                        >Add Calibration</v-btn
                      >
                    </v-card-actions>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.preCalibration`]="{ item }">
                  <p>
                    <b>Pre Calibration</b>
                    <br />
                    {{ item.preCalibration }}
                  </p>
                </template>
                <template v-slot:[`item.postCalibration`]="{ item }">
                  <p>
                    <b>Post Calibration</b>
                    <br />
                    {{ item.postCalibration }}
                  </p>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, 'calibration')"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item, 'calibration')">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>
            <v-col cols="6">
              <!-- table certificate -->
              <v-data-table
                class="elevation-1 mt-8"
                :headers="headersCertificate"
                :items="certificateList"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Certificate List</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        min-width="100px"
                        outlined
                        @click="openAddModal($event)"
                        >Add Certificate</v-btn
                      >
                    </v-card-actions>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, 'certificate')"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item, 'certificate')">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <!-- {{ param }} -->
    </v-form>

    <!-- SCRIPT LAMA -->
    <!-- <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Values
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="9" align-self="start" class="pa-1">
          <v-textarea
            hide-details="auto"
            v-model="param.values"
            solo
            flat
            outlined
            dense
            ref="values"
            name="input-7-4"
            label="Values"
            :rows="20"
            :rules="[rules.required]"
          ></v-textarea>
        </v-col>
      </v-row> -->

    <!-- DIALOG ACTION  -->
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Saved</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Saved</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <addProductDialog
      v-show="dialogAddProduct"
      :dialog="dialogAddProduct"
      :model="selectedValue"
      :data="modelList"
      v-on:save="saveItem"
      v-on:cancel="closeItem"
    />

    <addCertificateDialog
      v-show="dialogAddCertificate"
      :dialog="dialogAddCertificate"
      :data="certificateList"
      v-on:save="saveItem"
      v-on:cancel="closeItem"
    />

    <addInspectionNameDialog
      v-show="dialogAddInspectionName"
      :dialog="dialogAddInspectionName"
      :data="inspectionList"
      v-on:save="saveItem"
      v-on:cancel="closeItem"
    />

    <addCalibration
      v-show="dialogAddCalibration"
      :dialog="dialogAddCalibration"
      :data="calibrationList"
      :selectedValue="selectedValue"
      v-on:save="saveItem"
      v-on:cancel="closeItem"
    />

    <editProductDialog
      v-show="dialogEdit"
      :dialog="dialogEdit"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    />

    <editInspectionDialog
      v-show="dialogEditInspection"
      :dialog="dialogEditInspection"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    />

    <editCalibrationDialog
      v-show="dialogEditCalibration"
      :dialog="dialogEditCalibration"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    />

    <editCertificationDialog
      v-show="dialogEditCertificate"
      :dialog="dialogEditCertificate"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    ></editCertificationDialog>
  </v-card>
</template>

<script>
import addProductDialog from "@/components/dialog/NewParameter/addProductDialog";
import addCertificateDialog from "@/components/dialog/NewParameter/addCertificateDialog";
import addInspectionNameDialog from "@/components/dialog/NewParameter/addInspectionNameDialog";
import addCalibration from "@/components/dialog/NewParameter/addCalibration";
import editProductDialog from "@/components/dialog/NewParameter/editProductDialog";
import editInspectionDialog from "@/components/dialog/NewParameter/editInspectionDialog.vue";
import editCalibrationDialog from "@/components/dialog/NewParameter/editCalibrationDialog.vue";
import editCertificationDialog from "../../../components/dialog/NewParameter/editCertificationDialog.vue";
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { paramsCollection } from "../../../firebase";

export default {
  components: {
    isttoolbar,
    addProductDialog,
    addCertificateDialog,
    addInspectionNameDialog,
    addCalibration,
    editProductDialog,
    editInspectionDialog,
    editCalibrationDialog,
    editCertificationDialog,
  },
  data: () => ({
    errorPG: false,
    errorPS: false,
    errorUTITapeLength: false,
    errorUTIValveConector: false,
    errorMessage: null,
    menuOpen: false,
    headersModels: [
      { text: "Type", align: "start", value: "type" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersInspection: [
      { text: "Inspection Name", align: "start", value: "reportType" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersCertificate: [
      { text: "Certificate Name", align: "start", value: "certificateType" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersCalibrationUti: [
      { text: "Deskripsi", align: "start", value: "deskripsi" },
      { text: "Reference Thermometer", value: "preCalibration" },
      { text: "", value: "postCalibration" },
      { text: "Maximum Error Allowed", value: "maximumErrorAllowed" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersCalibration: [
      { text: "No Tube", align: "start", value: "noTube" },
      { text: "Gas Type", value: "gasType" },
      { text: "Concentration", value: "concentration" },
      { text: "Unit", value: "unit" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    selectEditedItem: "",
    tapeLength: "",
    valveConector: "",
    editedItem: [],
    modelList: [],
    inspectionList: [],
    calibrationList: [],
    certificateList: [],
    items: ["Gas", "UTI Meter"],
    selectedValue: null,
    param: {
      paramsGroup: "",
      params: "",
      values: "",
      tapeLength: "",
      valveConector: "",
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    dialogAddProduct: false,
    dialogAddCertificate: false,
    dialogAddInspectionName: false,
    dialogAddCalibration: false,
    dialogEdit: false,
    dialogEditCalibration: false,
    dialogEditInspection: false,
    dialogEditCertificate: false,
    dialogDelete: false,
  }),
  computed: {
    form() {
      return {
        paramsGroup: this.param.paramsGroup,
        params: this.param.params,
        values: this.param.values,
        tapeLength: this.param.tapeLength,
        valveConector: this.param.valveConector,
      };
    },
  },
  created() {
    this.checkInputLocal();
  },
  methods: {
    handleInputPG(value) {
      let checkLocalStorage = localStorage.getItem("userInput");
      let newData = JSON.parse(checkLocalStorage);

      if (value.length > 0) {
        this.formHasErrors = false;
        this.errorPG = false;
        newData.calibrationName = value;
      } else {
        this.formHasErrors = true;
        this.errorPG = true;
        newData.calibrationName = value;
      }
      localStorage.setItem("userInput", JSON.stringify(newData));
    },
    handleInputPS(value) {
      let checkLocalStorage = localStorage.getItem("userInput");
      let newData = JSON.parse(checkLocalStorage);
      if (value.length > 0) {
        this.formHasErrors = false;
        this.errorPS = false;
        newData.inspectionName = value;
      } else {
        newData.inspectionName = value;
        this.formHasErrors = true;
        this.errorPS = true;
      }
      localStorage.setItem("userInput", JSON.stringify(newData));
    },
    handleInputTL(value) {
      let checkLocalStorage = localStorage.getItem("userInput");
      let newData = JSON.parse(checkLocalStorage);
      if (this.selectedValue == "UTI Meter" && value.length == 0) {
        // tambahkan keterangan error
        this.formHasErrors = true;
        this.errorUTITapeLength = true;
        newData.tapeLength = value;
      } else {
        this.errorUTITapeLength = false;
        this.formHasErrors = false;
        newData.tapeLength = value;
      }
      localStorage.setItem("userInput", JSON.stringify(newData));
    },
    handleInputVC(value) {
      let checkLocalStorage = localStorage.getItem("userInput");
      let newData = JSON.parse(checkLocalStorage);
      if (this.selectedValue == "UTI Meter" && value.length == 0) {
        // tambahkan keterangan error
        this.formHasErrors = true;
        this.errorUTIValveConector = true;
        newData.valveConector = value;
      } else {
        this.errorUTIValveConector = false;
        this.formHasErrors = false;
        newData.valveConector = value;
      }
      localStorage.setItem("userInput", JSON.stringify(newData));
    },
    selectItem(item) {
      this.selectedValue = item;
      this.menuOpen = false;
      let perviousCalibration = JSON.parse(localStorage.getItem("userInput"))
        .listCalibration;

      let requiredUTIProps = [
        "deskripsi",
        "preCalibration",
        "postCalibration",
        "maximumErrorAllowed",
      ];
      let requiredGASProps = ["noTube", "gasType", "concentration", "unit"];

      // Filter array berdasarkan properti yang dimiliki
      if (perviousCalibration && item == "UTI Meter") {
        this.calibrationList = perviousCalibration.filter((item) => {
          return requiredUTIProps.every((prop) =>
            Object.prototype.hasOwnProperty.call(item, prop)
          );
        });
      } else if (perviousCalibration && item == "Gas") {
        this.calibrationList = perviousCalibration.filter((item) => {
          return requiredGASProps.every((prop) =>
            Object.prototype.hasOwnProperty.call(item, prop)
          );
        });
      }

      localStorage.setItem("selectedItemValues", this.selectedValue);
    },
    openAddModal(val) {
      switch (val.target.textContent) {
        case "Add Gas":
          this.dialogAddProduct = true;
          break;
        case "Add UTI Meter":
          this.dialogAddProduct = true;
          break;
        case "Add Inspection":
          this.dialogAddInspectionName = true;
          break;
        case "Add Certificate":
          this.dialogAddCertificate = true;
          break;
        case "Add Calibration":
          this.dialogAddCalibration = true;
          break;
        default:
          break;
      }
    },
    closeItem() {
      this.dialogAddProduct = false;
      this.dialogAddInspectionName = false;
      this.dialogAddCertificate = false;
      this.dialogAddCalibration = false;
    },
    // check local storage untuk isi values jika di refresh
    checkInputLocal() {
      let perviousData = JSON.parse(localStorage.getItem("userInput"));
      let perviousSelectedItem = localStorage.getItem("selectedItemValues");
      if (perviousSelectedItem) {
        this.selectedValue = perviousSelectedItem;
      }
      if (perviousData && typeof perviousData == "object") {
        if (perviousData.inspectionName) {
          this.param.params = perviousData.inspectionName;
        }
        if (perviousData.calibrationName) {
          this.param.paramsGroup = perviousData.calibrationName;
        }
        if (perviousData.listGas) {
          this.modelList = perviousData.listGas;
        }
        if (perviousData.listCalibration) {
          let requiredUTIProps = [
            "deskripsi",
            "preCalibration",
            "postCalibration",
            "maximumErrorAllowed",
          ];
          let requiredGASProps = ["noTube", "gasType", "concentration", "unit"];

          if (this.selectedValue == "Gas") {
            this.calibrationList = perviousData.listCalibration.filter(
              (item) => {
                return requiredGASProps.every((prop) =>
                  Object.prototype.hasOwnProperty.call(item, prop)
                );
              }
            );
          } else if (this.selectedValue == "UTI Meter") {
            this.calibrationList = perviousData.listCalibration.filter(
              (item) => {
                return requiredUTIProps.every((prop) =>
                  Object.prototype.hasOwnProperty.call(item, prop)
                );
              }
            );
          }
        }
        if (perviousData.certificate) {
          this.certificateList = perviousData.certificate;
        }
        if (perviousData.inspectionReport) {
          this.inspectionList = perviousData.inspectionReport;
        }
        if (perviousData.tapeLength) {
          this.tapeLength = perviousData.tapeLength;
        }
        if (perviousData.valveConector) {
          this.valveConector = perviousData.valveConector;
        }
      } else {
        let obj = {};
        localStorage.setItem("userInput", JSON.stringify(obj));
      }
    },
    // Edit Item Order
    // PR NANTI UNTUK PENYESUAIAN CALIBRATION
    editItem(item, str) {
      if (str == "model") {
        this.editedItem = {
          id: item.id,
          type: item.type,
        };
        this.dialogEdit = true;
      }
      if (str == "inspection") {
        this.editedItem = {
          id: item.id,
          reportType: item.reportType,
        };
        this.dialogEditInspection = true;
      }
      if (str == "calibration") {
        // PR NANTI
        if (this.selectedValue == "Gas") {
          this.editedItem = {
            id: item.id,
            noTube: item.noTube,
            gasType: item.gasType,
            concentration: item.concentration,
            unit: item.unit,
          };
        } else if (this.selectedValue == "UTI Meter") {
          this.editedItem = {
            id: item.id,
            deskripsi: item.deskripsi,
            maximumErrorAllowed: item.maximumErrorAllowed,
            preCalibration: item.preCalibration,
            postCalibration: item.postCalibration,
          };
        }
        this.dialogEditCalibration = true;
      }
      if (str == "certificate") {
        this.editedItem = {
          id: item.id,
          certificateType: item.certificateType,
        };
        this.dialogEditCertificate = true;
      }

      this.selectEditedItem = str;
    },
    saveEditedItem() {
      let index = 0;

      let perviousData = localStorage.getItem("userInput");

      // let index = this.modelList.findIndex((el) => el.id == this.editedItem.id);
      if (this.selectEditedItem === "model") {
        index = this.modelList.findIndex((el) => el.id == this.editedItem.id);
        if (this.selectedValue === "Gas") {
          this.modelList.splice(index, 1, this.editedItem);
          this.editedItem = {
            id: null,
            type: "",
          };
        }
        this.dialogEdit = false;
      }

      if (this.selectEditedItem === "inspection") {
        index = this.inspectionList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.inspectionList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          reportType: "",
        };
        this.dialogEditInspection = false;
      }

      if (this.selectEditedItem == "calibration") {
        index = this.calibrationList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.calibrationList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          noTube: "",
          gasType: "",
          concentration: "",
          unit: "",
          deskripsi: "",
          maximumErrorAllowed: "",
          preCalibration: "",
          postCalibration: "",
        };
        this.dialogEditCalibration = false;
      }

      if (this.selectEditedItem == "certificate") {
        index = this.certificateList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.certificateList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          certificateType: "",
        };
        this.dialogEditCertificate = false;
      }

      if (perviousData) {
        let dataBefore = JSON.parse(perviousData);

        dataBefore.listGas = this.modelList;
        dataBefore.totalGas = this.modelList.length;
        dataBefore.listCalibration = this.calibrationList;
        dataBefore.totalCalibration = this.calibrationList.length;
        dataBefore.totalCertificate = this.certificateList.length;
        dataBefore.certificate = this.certificateList;
        dataBefore.inspectionReport = this.inspectionList;
        dataBefore.totalInspection = this.inspectionList.length;

        localStorage.setItem("userInput", JSON.stringify(dataBefore));
      }
    },
    closeEditItem() {
      this.dialogEdit = false;
      this.dialogEditCalibration = false;
      this.dialogEditCertificate = false;
      this.dialogEditInspection = false;
      // this.editedItem = {
      //   id: null,
      //   type: "",
      // };
    },
    // Delete Item Order
    async deleteItem(item, str) {
      let perviousData = localStorage.getItem("userInput");
      if (str == "model")
        this.modelList = this.modelList.filter((el) => el.id !== item.id);
      if (str == "inspection")
        this.inspectionList = this.inspectionList.filter(
          (el) => el.id !== item.id
        );
      if (str == "calibration")
        this.calibrationList = this.calibrationList.filter(
          (el) => el.id !== item.id
        );
      if (str == "certificate")
        this.certificateList = this.certificateList.filter(
          (el) => el.id !== item.id
        );

      if (perviousData) {
        let dataBefore = JSON.parse(perviousData);

        dataBefore.listGas = this.modelList;
        dataBefore.totalGas = this.modelList.length;
        dataBefore.listCalibration = this.calibrationList;
        dataBefore.totalCalibration = this.calibrationList.length;
        dataBefore.certificate = this.certificateList;
        dataBefore.totalCertificate = this.certificateList.length;
        dataBefore.inspectionReport = this.inspectionList;
        dataBefore.totalInspection = this.inspectionList.length;

        localStorage.setItem("userInput", JSON.stringify(dataBefore));
      }
    },
    // Save Item Order
    async saveItem() {
      this.dialogAddProduct = false;
      this.dialogAddInspectionName = false;
      this.dialogAddCertificate = false;
      this.dialogAddCalibration = false;

      let obj = {};
      if (this.certificateList.length > 0) {
        obj.certificate = this.certificateList;
        obj.totalCertificate = this.certificateList.length;
      }
      if (this.modelList.length > 0) {
        //  INI BELUM TERMASUK UTI NANTI KALO ADA HARUS DIRUBAH !
        if (this.selectedValue === "Gas") {
          obj.listGas = this.modelList;
          obj.totalGas = this.modelList.length;
        }
      }
      if (this.calibrationList.length > 0) {
        obj.listCalibration = this.calibrationList;
        obj.totalCalibration = this.calibrationList.length;
      }
      if (this.inspectionList.length > 0) {
        obj.inspectionReport = this.inspectionList;
        obj.totalInspection = this.inspectionList.length;
      }
      if (this.param.params !== "") {
        obj.orderProductSeries = this.param.params;
        obj.inspectionName = this.param.params;
      }
      if (this.param.paramsGroup !== "") {
        obj.calibrationName = this.param.paramsGroup;
      }
      if (this.selectedValue == "UTI Meter" && this.tapeLength !== 0) {
        obj.tapeLength = this.tapeLength;
      }

      if (this.selectedValue == "UTI Meter" && this.valveConector !== 0) {
        obj.valveConector = this.valveConector;
      }

      localStorage.setItem("userInput", JSON.stringify(obj));

      // Object.keys(this.form).forEach((f) => {
      //   if (
      //     f === "orderProduct" ||
      //     f === "orderProductType" ||
      //     f === "orderProductSeries" ||
      //     f === "orderProductSN" ||
      //     f === "notes"
      //   ) {
      //     if (!this.form[f]) this.formHasErrors = true;
      //     this.$refs[f].validate(true);
      //   }
      // });
      // if (!this.formHasErrors) {
      //   if (this.editedIndex > -1) {
      //     Object.assign(this.items[this.editedIndex], this.editedItem);
      //   } else {
      //     this.items.push(this.editedItem);
      //   }
      //   this.close();
      // } else {
      //   this.formHasErrors = false;
      // }
    },
    back() {
      localStorage.removeItem("userInput");
      localStorage.removeItem("selectedItemValues");

      this.$router.replace({
        name: "parameter-configuration-list",
      });
    },
    doubleCheck() {
      if (!this.param.paramsGroup) {
        this.errorPG = true;
        this.formHasErrors = true;
        this.dialog.error = true;
        this.dialog.show = true;
        this.dialog.success = false;
        this.dialog.errorMessage = "Field Params Group / Brand Product kosong!";
      }
      if (!this.param.params) {
        this.errorPS = true;
        this.formHasErrors = true;
        this.dialog.error = true;
        this.dialog.show = true;
        this.dialog.success = false;
        this.dialog.errorMessage = "Field Params / Product Series kosong!";
      }
      if (this.selectedValue == "UTI Meter" && !this.param.tapeLength) {
        this.errorUTITapeLength = true;
        this.formHasErrors = true;
        this.dialog.error = true;
        this.dialog.show = true;
        this.dialog.success = false;
        this.dialog.errorMessage = "Field Tape Length kosong!";
      }
      if (this.selectedValue == "UTI Meter" && !this.param.valveConector) {
        this.errorUTIValveConector = true;
        this.formHasErrors = true;
        this.dialog.error = true;
        this.dialog.show = true;
        this.dialog.success = false;
        this.dialog.errorMessage = "Field Valve Connector kosong!";
      }
    },
    async save() {
      this.doubleCheck();
      // this.errorMessage = "Save Function belum dijalankan pada branch 'NewParameter_Featured' karena proses ini masih berlangsung, mohon cek dan sesuaikan dahulu param values yang akan disimpan. Untuk membuat setelan awal silahkan pilih branch development atau rizky_branch pada Git lalu mulai kembali"

      let dataInLocalStorage = JSON.parse(localStorage.getItem("userInput"));

      this.param.paramsGroup = dataInLocalStorage.calibrationName;
      this.param.params = dataInLocalStorage.inspectionName;

      // Object.keys(this.form).forEach((f) => {
      //   if (!this.form[f]) this.formHasErrors = true;
      //   this.$refs[f].validate(true);
      // });
      if (!this.formHasErrors) {
        this.param.values = JSON.stringify(dataInLocalStorage);
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        await this.doAdd();
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      let original = this.param.values;
      let stringify = JSON.stringify(original);
      let remove = stringify.replace(/\\n/g, "");
      const newData = {
        paramsGroup: this.param.paramsGroup,
        params: this.param.params,
        valveConector: this.param.valveConector,
        tapeLength: this.param.tapeLength,
        values: remove,
        status: true,
        isDeleted: false,
        createdAt: new Date().toISOString(),
        createdBy: "Admin",
        updatedBy: "",
        updatedAt: "",
        deletedAt: "",
        deletedBy: "",
      };
      console.log(newData,'<< cek')
      paramsCollection
        .add(newData)
        .then(() => {
          this.dialog.success = true;
          localStorage.removeItem("userInput");
          localStorage.removeItem("selectedItemValues");
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Something Went Wrong";
        });
    },
  },
};
</script>
