<template>
  <v-card flat>
    <isttoolbar
      title="User Management"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer></v-spacer>
      <v-btn outlined color="white" @click="goToAdd">
        <v-icon left>mdi-plus</v-icon>Add User
      </v-btn>
    </isttoolbar>
    <v-card>
      <v-card-title>
        List User
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
      >
        <template v-slot:[`item.isActive`]="{ item }">
          {{ item.isActive ? "Active" : "Inactive" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left :offset-x="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="pointer" @click="clickView(item)">
                <v-list-item-title>View</v-list-item-title>
              </v-list-item>
              <v-list-item class="pointer" @click="clickEdit(item)">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item class="pointer" @click="clickDelete(item)">
                <v-list-item-title>Inactive</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data></template>
      </v-data-table>
    </v-card>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { usersCollection } from "../../../firebase";
export default {
  components: {
    isttoolbar,
  },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "Name", align: "start", value: "name" },
      { text: "Username", value: "username" },
      { text: "Email", value: "email" },
      { text: "Role", value: "role" },
      { text: "Status", value: "isActive" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    items: [],
  }),
  watch: {},
  created() {
    this.loading = true;
    usersCollection
      .orderBy("createdAt", "desc")
      .get()
      .then((data) => {
        let users = [];
        data.forEach((doc) => {
          console.log(doc,'<< cek')
          if (doc.data().role !== "customer") {
            users.push({
              userId: doc.data().userId,
              name: doc.data().name,
              email: doc.data().email,
              username: doc.data().username,
              role: doc.data().role,
              isActive: doc.data().isActive,
              createdAt: doc.data().createdAt,
              createdBy: doc.data().createdBy,
              updatedAt: doc.data().updatedAt,
              updatedBy: doc.data().updatedBy,
            });
          }
          this.loading = false;
          this.items = users;
        });
      })
      .catch((err) => {
        this.loading = false;
        console.debug(err, "users list");
      });
  },
  methods: {
    goToAdd() {
      this.$router.replace({ name: "user-management-add" });
    },
    clickView(item) {
      this.$router.replace({
        name: "user-management-edit",
        query: { username: item.username, statusPage: "view" },
      });
    },
    clickEdit(item) {
      this.$router.replace({
        name: "user-management-edit",
        query: { username: item.username, statusPage: "edit" },
      });
    },
    clickDelete(item) {
      console.debug(item);
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.pointer:hover {
  background: #eeeeee;
}
</style>
