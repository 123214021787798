<template>
  <v-toolbar flat class="pb-1" dense v-bind="$attrs">
    <v-btn v-if="icon != null" icon class="hidden-xs-only btn-disabled">
      <v-icon>{{icon}}</v-icon>
    </v-btn>
    <v-toolbar-title class="pl-0">{{title}}</v-toolbar-title>
    <slot></slot>
  </v-toolbar>
</template>

<script>
export default {
  props: ["title", "icon"]
};
</script>