<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" :readonly="this.$route.query.statusPage === 'view'">
      <v-card>
        <isttoolbar
          title="Add Inspection"
          dark
          color="secondary"
          icon="mdi-format-list-checkbox"
        />
        <v-container>
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">
                Inspection Name
              </span>
            </v-col>
            <v-col cols="8" align-self="start" class="pa-1">
              <v-text-field
                v-model="reportType"
                hide-details="auto"
                ref="orderProduct"
                solo
                flat
                outlined
                dense
                class="ma-0"
                @keydown.enter.prevent="saveNewInspection()"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="saveNewInspection()">
            Save
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="$emit('cancel')">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";

export default {
  components: {
    isttoolbar,
  },
  props: ["dialog", "data"],
  data: () => ({
    id: 0,
    reportType: "",
    noInput: false,
    rules: {
      required: (value) => !!value || "Required",
    },
  }),
  watch: {
    dialog: {
      handler: function(after) {
        if (after) {
          console.log(true);
        }
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    saveNewInspection() {
      // event.preventDefault();
      if (this.reportType.length == 0) {
        this.noInput = true;
        return;
      } else {
        this.noInput = false;
      }
      let obj = {
        id: 0,
        reportType: "",
        type: "checkboxes",
        no: 0,
        value: false,
        checked: "",
        jenis: "inspection",
      };
      obj.reportType = this.reportType;
      obj.id = this.data.length + 1;
      obj.no = this.data.length + 1;
      this.data.push(obj);
      this.reportType = "";
      this.id = 0;
      this.$emit("save");
    },
  },
};
</script>
