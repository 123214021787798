<template>
  <router-view/>
</template>
<script>
export default {
  name: "App",
  data: () => ({
    //
  })
};
</script>
<style>
@import './assets/style/ISTStyle.css';
</style>
