<template>
  <v-card flat>
    <isttoolbar
      title="User Management"
      dark
      color="secondary"
      icon="mdi-folder-account-outline"
    >
      <v-spacer />
      <v-btn
        v-show="this.$route.query.statusPage === 'view'"
        @click="edit"
        outlined
        class="mx-1"
        min-width="100px"
        >Edit</v-btn
      >
      <v-btn
        v-show="this.$route.query.statusPage !== 'view'"
        @click="save"
        outlined
        class="mx-1"
        min-width="100px"
        >Save</v-btn
      >
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>
    <isttoolbar
      :title="
        this.$route.query.statusPage === 'view' ? 'View User' : 'Edit User'
      "
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>
    <v-form class="mb-8" :readonly="this.$route.query.statusPage === 'view'">
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Role
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            v-if="this.$route.query.statusPage === 'view'"
            hide-details="auto"
            label="Select Role"
            ref="role"
            v-model="param.role"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
          <v-autocomplete
            v-else
            hide-details="auto"
            label="Select Role"
            @input="setDataRole"
            v-model="param.role"
            :items="dataRole"
            item-value="role"
            item-text="role"
            ref="role"
            class="ma-0"
            return-object
            flat
            outlined
            dense
            solo
            clearable
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Name
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Name"
            ref="name"
            v-model="param.name"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Email
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Email"
            ref="email"
            v-model="param.email"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.email]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">Status</span>
        </v-col>
        <v-col cols="8" class="pa-1">
          <v-switch
            ref="isActive"
            name="status"
            color="success"
            v-model="param.isActive"
            inset
            :label="`${param.isActive ? 'Active' : 'Inactive'}`"
          ></v-switch>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="mt-6 mb-6">
      <v-row>
        <v-col cols="6">
          <v-row align="center" class="ml-2 mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">Created By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="create"
                v-model="param.createdBy"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="createDate"
                v-model="param.createdAt"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="ml-2 mr-2"> </v-row>
        </v-col>
        <v-col cols="6">
          <v-row align="center" class="mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">Updated By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="close"
                v-model="param.updatedBy"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="closeDate"
                v-model="param.updatedAt"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mr-2"> </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>

    <!-- DIALOG -->
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Saved</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Saved</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { firebaseDB } from "../../../firebase"; // firebaseAuth

export default {
  components: {
    isttoolbar,
  },
  props: {
    source: String,
  },

  data: () => ({
    statusUserTaken: false,
    statusPassword: true,
    statusConfirm: true,
    dataRole: [{ role: "Admin" }, { role: "Engineer" }, { role: "Staff" }],
    param: {
      role: "",
      name: "",
      email: "",
      isActive: true,
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      password: (value) => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/;
        return (
          pattern.test(value) ||
          "Min. 8 characters with at least one capital letter, a number and a special character."
        );
      },
    },
    dialog: { show: false, success: false, error: false, errorMessage: "" },
  }),
  computed: {
    form() {
      return {
        role: this.param.role,
        name: this.param.name,
        email: this.param.email,
      };
    },
  },
  created() {
    this.dialog.success = false;
    this.dialog.error = false;
    this.dialog.show = true;
    let newData = {};
    firebaseDB
      .doc(`/users/${this.$route.query.username}`)
      .get()
      .then((doc) => {
        newData = doc.data();
        newData.userId = doc.id;
        newData.createdAt = doc
          .data()
          .createdAt.match(/([^T]+)/)[0]
          .split("-")
          .reverse()
          .join("/");
        newData.updatedAt =
          doc.data().updatedAt !== ""
            ? doc
                .data()
                .updatedAt.match(/([^T]+)/)[0]
                .split("-")
                .reverse()
                .join("/")
            : "";
        this.param = newData;
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = false;
      })
      .catch(() => {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = false;
      });
  },
  methods: {
    edit() {
      this.$router.replace({
        name: "user-management-edit",
        query: { username: this.$route.query.username, statusPage: "edit" },
      });
    },
    setDataRole(item) {
      if (item === null) {
        this.param.role = "";
      } else {
        this.param.role = item.role;
      }
    },
    back() {
      this.$router.replace({
        name: "user-management-list",
      });
    },
    async save() {
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });
      if (!this.formHasErrors) {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        await this.doAdd();
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      const newData = {
        email: this.param.email,
        name: this.param.name,
        role: this.param.role,
        isActive: this.param.isActive,
        updatedAt: new Date().toISOString(),
        updatedBy: "Admin",
      };
      firebaseDB
        .doc(`/users/${this.$route.query.username}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "User not found";
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Somthing Went Wrong";
        });
    },
  },
};
</script>
