import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#00529B",
        secondary: "#003565",
        accent: "#ADC2D7",
        error: "#FF0000",
        filtering: "#ADC2D7",
        secondToolbar: "#EAEAEA",
      },
    },
  },
});
