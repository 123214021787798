<template>
  <v-dialog v-model="dialog" persistent max-width="1200px">
    <v-form
      ref="form"
    >
      <!-- :readonly="
        this.$route.query.statusPage === 'view' ||
          this.$route.name === 'service-add'
      " -->
      <v-card>
        <isttoolbar
          title="Service Form Details"
          dark
          color="secondary"
          icon="mdi-briefcase"
        />
        <v-container>
          <v-row class="mt-1">
            <v-col cols="6">
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProduct"
                    :value="data.orderProduct"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product Type </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProductType"
                    :value="data.orderProductType"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product Series </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProductSeries"
                    :value="data.orderProductSeries"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product Serial Number </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProductSN"
                    :value="data.orderProductSN"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Notes </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-textarea
                    hide-details="auto"
                    :value="data.reportType"
                    ref="notes"
                    class="ma-0"
                    flat
                    outlined
                    readonly
                    dense
                    solo
                    :rows="3"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Calibration </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="calibrationName"
                    :value="data.calibrationName"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mb-4 mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Inspection </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="inspeactionName"
                    :value="data.inspectionName"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- JIKA DATANYA ADALAH GAS METER -->
              <div v-if="data.orderProduct == 'Gas Meter'">
                <v-row align="center" class="mt-4">
                  <v-col cols="12" class="pa-1" style="text-align: center">
                    <span class="title"> Reference Gas </span>
                  </v-col>
                </v-row>
                <v-row align="center" class="mr-2 ml-2">
                  <v-col cols="12" class="pa-1">
                    <v-data-table
                      :headers="headers"
                      :items="dataGas"
                      class="elevation-1"
                      :options.sync="options"
                      :server-items-length="Number(data.totalGas)"
                      hide-default-footer
                    >
                      <template v-slot:[`item.lot`]="{ item }">
                        <v-text-field
                          v-model="item.lot"
                          hide-details="auto"
                          class="ma-0"
                          ref="lot"
                          flat
                          outlined
                          dense
                          solo
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.cyl`]="{ item }">
                        <v-text-field
                          v-model="item.cyl"
                          hide-details="auto"
                          class="ma-0"
                          ref="cyl"
                          flat
                          outlined
                          dense
                          solo
                        ></v-text-field>
                      </template>
                      <template v-slot:no-data></template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>

              <!-- JIKA DATANYA ADALAH UTI METER -->
              <div v-if="data.orderProduct == 'UTI Meter'">
                <v-row align="center" class="mr-2 ml-2">
                  <v-col cols="4" class="pa-1">
                    <span class="title">
                      Tape Length
                    </span>
                  </v-col>
                  <v-col cols="8" align-self="start" class="pa-1">
                    <v-row style="display: flex; flex-direction: column;">
                      <v-col
                        style="display: flex; flex-direction: row; gap: 20px;"
                      >
                        <v-checkbox
                          dense
                          v-model="checkbox.tl10"
                          label="10 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl15"
                          label="15 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl20"
                          label="20 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl25"
                          label="25 M"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        style="display: flex; flex-direction: row; gap: 20px;"
                      >
                        <v-checkbox
                          dense
                          v-model="checkbox.tl30"
                          label="30 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl35"
                          label="35 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl40"
                          label="40 M"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <br />

                <v-row align="center" class="mr-2 ml-2">
                  <v-col cols="4" class="pa-1">
                    <span class="title">
                      Valve Connection
                    </span>
                  </v-col>
                  <v-col cols="8" align-self="start" class="pa-1">
                    <v-row style="display: flex; flex-direction: column;">
                      <v-col
                        style="display: flex; flex-direction: row; gap: 20px;"
                      >
                        <v-checkbox
                          dense
                          v-model="checkbox.vcf50"
                          label="F/C50"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.vcmb"
                          label="MB"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.vcb"
                          label="B"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        style="display: flex; flex-direction: row; gap: 20px;"
                      >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <isttoolbar
          title="Inspection Result cok"
          class="mb-2 mt-2"
          dark
          color="primary"
        ></isttoolbar>
        <div>
        </div>
        <v-data-table
        class="elevation-1 mt-2 mb-2 w-md-80 pa pa-md mr-10 ml-10"
        :headers="headersInspector"
        :items="dataInspector"
        >
        <!-- :readonly="this.$route.query.statusPage === 'add'" -->
          <template v-slot:item.checkbox="{ item }">
            <v-checkbox v-model="item.checked" />
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-card>
    </v-form>
    <v-form ref="form" :readonly="this.$route.query.statusPage === 'view'">
      <v-card v-if="data.orderProduct == 'Gas Meter'">
        <isttoolbar
          title="Result of Works"
          class="mb-2 mt-5"
          dark
          color="primary"
        ></isttoolbar>
        <v-row>
          <v-col>
            <small class="mt-2 w-md-80 mr-10 ml-10 mt-5">*Gunakan titik (.) untuk Nilai Desimal</small>
            <v-data-table
              :headers="workResult"
              :items="dataCalibration"
              class="elevation-1 mt-2 mb-2 w-md-80 pa pa-md mr-10 ml-10"
              :options.sync="options"
              :server-items-length="Number(data.totalGas)"
              hide-default-footer
            >
              <template v-slot:[`item.preCalibration`]="{ item }">
                <v-text-field
                  v-model="item.preCalibration"
                  hide-details="auto"
                  class="ma-0"
                  ref="lot"
                  flat
                  outlined
                  dense
                  solo
                  :rules="[noLettersRule]"
                ></v-text-field>
              </template>
              <template v-slot:[`item.postCalibration`]="{ item }">
                <v-text-field
                  v-model="item.postCalibration"
                  hide-details="auto"
                  class="ma-0"
                  ref="cyl"
                  flat
                  outlined
                  dense
                  solo
                  :rules="[noLettersRule]"
                ></v-text-field>
              </template>
              <template v-slot:no-data></template>
            </v-data-table>
          </v-col>
          <v-col cols="4">
            <span class="title"> Call Period : </span>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="callPeriodSelected"
                  :label="`-1 Day`"
                  value="-1 day"
                ></v-checkbox>
                <v-checkbox
                  v-model="callPeriodSelected"
                  :label="`12 Months`"
                  value="12 months"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="callPeriodSelected"
                  :label="`6 Months`"
                  value="6 months"
                ></v-checkbox>
                <div class="d-flex">
                  <v-checkbox v-model="enabled" class="pe-1"></v-checkbox>
                  <v-text-field
                    :disabled="!enabled"
                    hide-details
                    label="Custom Date"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <isttoolbar
          title="Replacement Parts"
          class="mb-2 mt-5"
          dark
          color="primary"
        ></isttoolbar>

        <v-data-table
          :headers="headersChangeParts"
          :items="dataReplacementsParts"
          class="elevation-1 mt-2 mb-2 w-md-80 pa pa-md mr-10 ml-10 mt-5"
          :options.sync="options"
          :server-items-length="Number(data.totalGas)"
          hide-default-footer
        >
          <template v-slot:[`item.oldProduct`]="{ item }">
            <v-text-field
              v-model="item.oldProduct"
              hide-details="auto"
              class="ma-0"
              ref="oldProduct"
              flat
              outlined
              dense
              solo
            ></v-text-field>
          </template>
          <template v-slot:[`item.newProduct`]="{ item }">
            <v-text-field
              v-model="item.newProduct"
              hide-details="auto"
              class="ma-0"
              ref="newProduct"
              flat
              outlined
              dense
              solo
            ></v-text-field>
          </template>
          <template v-slot:item.checkbox="{ item }">
            <v-checkbox v-model="item.checked" />
          </template>
          <template v-slot:no-data></template>
        </v-data-table>

        <v-card-actions class="pr-10 mb-10">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="saveNewService()">
            Save Service
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="$emit('cancel')">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="data.orderProduct == 'UTI Meter'">
        <isttoolbar
          title="Result of Works"
          class="mb-2 mt-5"
          dark
          color="primary"
        ></isttoolbar>
        <v-row>
          <v-col>
            <v-data-table
              :headers="workResultUTI"
              :items="dataCalibration"
              class="elevation-1 mt-2 mb-2 w-md-80 pa pa-md mr-10 ml-10 mt-5"
              :options.sync="options"
              :server-items-length="Number(data.totalGas)"
              hide-default-footer
            >
              <template v-slot:[`item.preCalibration`]="{ item }">
                <v-row
                  style="display: flex; justify-content: center; flex-direction: row; width: 25vh;"
                >
                  <v-col>
                    <p>
                      <b>Reference Pre</b>
                    </p>
                    <v-text-field
                      v-model="item.preCalibration"
                      hide-details="auto"
                      class="ma-0"
                      ref="lot"
                      flat
                      outlined
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <p>
                      <b>Under Test Pre</b>
                    </p>
                    <v-text-field
                      v-model="underTestPre"
                      hide-details="auto"
                      class="ma-0"
                      ref="lot"
                      flat
                      outlined
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.postCalibration`]="{ item }">
                <v-row
                  style="display: flex; justify-content: center; flex-direction: row; width: 25vh;"
                >
                  <v-col>
                    <p>
                      <b>Reference Post</b>
                    </p>
                    <v-text-field
                      v-model="item.postCalibration"
                      hide-details="auto"
                      class="ma-0"
                      ref="lot"
                      flat
                      outlined
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <p>
                      <b>Under Test Post</b>
                    </p>
                    <v-text-field
                      v-model="underTestPost"
                      hide-details="auto"
                      class="ma-0"
                      ref="lot"
                      flat
                      outlined
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item, 'calibration')"
                >
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item, 'calibration')">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data></template>
            </v-data-table>
          </v-col>
          <v-col cols="4">
            <span class="title"> Call Period : </span>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="callPeriodSelected"
                  :label="`-1 Day`"
                  value="-1 day"
                ></v-checkbox>
                <v-checkbox
                  v-model="callPeriodSelected"
                  :label="`12 Months`"
                  value="12 months"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="callPeriodSelected"
                  :label="`6 Months`"
                  value="6 months"
                ></v-checkbox>
                <div class="d-flex">
                  <v-checkbox
                  v-model="enabled" class="pe-1"></v-checkbox>
                  <v-text-field
                    :disabled="!enabled"
                    hide-details
                    label="Custom Date"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <isttoolbar
          title="Replacement Parts"
          class="mb-2 mt-5"
          dark
          color="primary"
        ></isttoolbar>

        <v-data-table
          :headers="headersChangeParts"
          :items="dataReplacementsParts"
          class="elevation-1 mt-2 mb-2 w-md-80 pa pa-md mr-10 ml-10 mt-5"
          :options.sync="options"
          :server-items-length="Number(data.totalGas)"
          hide-default-footer
        >
          <template v-slot:[`item.oldProduct`]="{ item }">
            <v-text-field
              v-model="item.oldProduct"
              hide-details="auto"
              class="ma-0"
              ref="oldProduct"
              flat
              outlined
              dense
              solo
            ></v-text-field>
          </template>
          <template v-slot:[`item.newProduct`]="{ item }">
            <v-text-field
              v-model="item.newProduct"
              hide-details="auto"
              class="ma-0"
              ref="newProduct"
              flat
              outlined
              dense
              solo
            ></v-text-field>
          </template>
          <template v-slot:item.checkbox="{ item }">
            <v-checkbox v-model="item.checked" />
          </template>
          <template v-slot:no-data></template>
        </v-data-table>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="saveNewService()">
            Save Service
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="$emit('cancel')">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";

export default {
  components: {
    isttoolbar,
  },
  props: ["dialog", "data"],
  data: () => ({
    noLettersRule: value => {
        // Mengizinkan angka dan titik
        const regex = /^[0-9]*\.?[0-9]*$/;
        return regex.test(value) || 'Input must be a valid number';
      },
    checkbox: {
      tl10: false,
      tl15: false,
      tl20: false,
      tl25: false,
      tl30: false,
      tl35: false,
      tl40: false,
      vcf50: false,
      vcmb: false,
      vcb: false,
    },
    workformInspection: [],
    callPeriodSelected: '',
    enabled: false,
    headers: [
      {
        text: "Lot",
        align: "start",
        sortable: false,
        value: "lot",
      },
      {
        text: "Cyl",
        align: "center",
        sortable: false,
        value: "cyl",
      },
      {
        text: "Gas Type",
        align: "center",
        sortable: false,
        value: "gasType",
      },
    ],
    inspectorDescription: "",
    options: {
      page: 0,
      size: -1,
    },
    headersInspector: [
      {
        text: "No",
        align: "start",
        value: "no",
      },
      // {
      //   text: "Jenis",
      //   value: "type",
      // },
      {
        text: "Report Types",
        value: "reportType",
        width: "60%",
      },
      {
        text: "Option",
        value: "checkbox",
      },
    ],
    headersChangeParts: [
      {
        text: "No",
        align: "start",
        value: "no",
      },
      {
        text: "Change Parts",
        value: "reportType",
        width: "50%",
      },
      {
        text: "Old Product S/N",
        value: "oldProduct",
      },
      {
        text: "New Product S/N",
        value: "newProduct",
      },
      {
        text: "Option",
        value: "checkbox",
      },
    ],
    workResult: [
      { text: "Gas Type", align: "start", value: "gasType" },
      {
        text: "Reference Gas Concentration",
        value: "concentration",
      },
      { text: "Pre-Calibration", value: "preCalibration" },
      { text: "Post-Calibration", value: "postCalibration" },
      { text: "Unit", value: "unit" },
      // { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    workResultUTI: [
      {
        text: "Pre-Calibration Readings",
        value: "preCalibration",
        sortable: false,
      },
      {
        text: "Post-Calibration Readings",
        value: "postCalibration",
        sortable: false,
      },
    ],
    dataGas: [],
    dataCalibration: [],
    dataInspector: [],
    dataReplacementsParts: [],
    underTestPost: "",
    underTestPre: "",
  }),
  watch: {
    enabled(newValue) {
      if (newValue) {
        this.callPeriodSelected = '';
      }
    },
    callPeriodSelected(newValue) {
      if (newValue) {
        this.enabled = '';
      }
    },
    dialog: {
      handler: function(after) {
        if (after) {
          // Menyiapkan List Data Gas
          let newArray = [];
          let product = this.data.orderProduct;
          if (product == "Gas Meter") {
            console.log(JSON.stringify(this.data.listGas))
            this.data.listGas.forEach((element) => {
              if(!element.lot && !element.cyl){
                element.lot = "";
                element.cyl = "";
              }
              newArray.push({ ...element });
            });
            this.dataGas = newArray;
            console.log(this.dataGas)
          }

          if (product == "UTI Meter") {
            if (this.data.tapeLength == "10") {
              this.checkbox.tl10 = true;
            } else if (this.data.tapeLength == "15") {
              this.checkbox.tl15 = true;
            } else if (this.data.tapeLength == "20") {
              this.checkbox.tl20 = true;
            } else if (this.data.tapeLength == "25") {
              this.checkbox.tl25 = true;
            } else if (this.data.tapeLength == "30") {
              this.checkbox.tl30 = true;
            } else if (this.data.tapeLength == "35") {
              this.checkbox.tl35 = true;
            } else if (this.data.tapeLength == "40") {
              this.checkbox.tl40 = true;
            }

            if (this.data.valveConector == "20") {
              this.checkbox.vcf50 = true;
            } else if (this.data.tapeLength == "MB") {
              this.checkbox.vcmb = true;
            } else if (this.data.tapeLength == "B") {
              this.checkbox.vcb = true;
            }
          }

          if (product == "Other") {
            console.log(this.data);
          }

          // Menyiapkan List Data Calibration Gas
          let calibrationArray = [];
          this.data.listCalibration.forEach((el) => {
            calibrationArray.push({ ...el });
          });
          this.dataCalibration = calibrationArray;

          // Menyiapkan data inspection report berdasarkan params configuration
          let newArrayInspector = [];
          this.data.inspectionReport.forEach((el) => {
            if (el.checked != true) {
              el.checked = "";
            }
            el.jenis = "inspection";
            newArrayInspector.push({ ...el });
          });
          this.dataInspector = newArrayInspector;

          if (
            this.data.workResult === undefined ||
            this.data.replacementsParts === undefined
          ) {
            // Menyiapkan data service berdasarkan form insepction reports
            let newArrayReplacementsParts = [];
            this.data.inspectionReport.forEach((el) => {
              if (
                // jika belum ada workResults dan replacementParts
                this.data.workResult === undefined ||
                this.data.replacementsParts === undefined
              ) {
                el.checked = "";
              }

              if (el.reportType) {
                el.reportType = el.reportType.replace("must be replaced.", ".");
                el.reportType = el.reportType.replace(
                  "must be repaired/replaced.",
                  "."
                );
                el.reportType = el.reportType.replace(
                  "is still working, but near the end of it’s service life. Sensor replacement is recommended.",
                  "."
                );
                el.reportType = el.reportType.replace(
                  "is still working, but near the end of it’s service life. Customer can request for replacement or use it as is.",
                  "."
                );
                el.reportType = el.reportType.replace(
                  "is still working, but reaching the end of it’s service life. Sensor replacement is recommended.",
                  "."
                );
                el.reportType = `Replaced ${el.reportType}`;
              }
              el.jenis = "service";

              newArrayReplacementsParts.push({ ...el });
            });

            // setelah menyesuaikan data service text dengan replace, sekarang menghapus value kembar
            const filteredReport = newArrayReplacementsParts.filter(
              (obj, index, arr) => {
                return (
                  arr
                    .map((mapObj) => mapObj.reportType)
                    .indexOf(obj.reportType) === index
                );
              }
            );

            // mengurutkan data service kembali
            filteredReport.sort((a, b) => a.no - b.no);

            // Mengupdate properti no pada data service secara urut
            for (let i = 0; i < filteredReport.length; i++) {
              filteredReport[i].no = String(i + 1);
            }
            this.dataReplacementsParts = filteredReport;
          } else {
            console.log("masuk jika sudah ada datanya");
            let newArrayReplacementsParts = [];
            this.data.replacementsParts.forEach((el) => {
              if (el.checked != true) {
                el.checked = "";
              }
              el.jenis = "service";

              newArrayReplacementsParts.push({ ...el });
            });
            this.dataReplacementsParts = newArrayReplacementsParts;

            this.callPeriodSelected = this.data.workResult.callPeriod;
            this.dataCalibration = this.data.workResult.resultCalibration;
          }
        }
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    saveNewService() {
      let product = this.data.orderProduct;
      if (product == "UTI Meter") {
        this.dataCalibration.forEach((obj) => {
          obj.preDeviceUnderTest = this.underTestPre;
          obj.postDeviceUnderTest = this.underTestPost;
        });
      }

      // input if value True or Cheklist
      let result = {
        resultCalibration: this.dataCalibration,
        callPeriod: this.callPeriodSelected,
      };

      this.doAdd(result, this.dataReplacementsParts);
    },
    doAdd(wf, rp) {
      this.data["workResult"] = wf;
      this.data["replacementsParts"] = rp;
      this.data.inspectionReport = this.dataInspector;
      this.$emit("save");
    },
  },
};
</script>
