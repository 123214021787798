import * as types from "./type";

const defaultState = () => {
  return {
    remittanceDataDto: {
      amount: "",
      amountEq: "",
      bankCode: "",
      bankName: "",
      currency: "",
      customerCode: "",
      customerType: "",
      customerName: "",
      exchangeRate: "",
      listInvoice: [],
      paymentMethod: "",
      reference: "",
      tranDate: "",
    },
    currency: "",
    customer: {},
    excRate: "",
    exchangeRate: "",
    idRemittance: "",
    param: {},
    listInvoice: [],
    listInvoiceRow: [],
  };
};

const state = defaultState();

const mutations = {
  [types.MUTATE_ADD_STATE]: (state, value) => {
    let invoiceClass = value.invoiceClass;
    let invoiceValue = value.invoiceValue;
    let invoiceInsurance = value.invoiceInsurance;
    let invoiceInstallment = value.invoiceInstallment;
    // let invoiceAgent = value.invoiceAgent

    state.invoiceAgentList = value.invoiceAgentList
      ? value.invoiceAgentList
      : [];
    state.totalAmountAgent = value.totalAmountAgent
      ? value.totalAmountAgent
      : 0;

    state.approvedBy = value.approvedBy ? value.approvedBy : "";
    state.approvedDate = value.approvedDate ? value.approvedDate : "";
    state.closedBy = value.closedBy ? value.closedBy : "";
    state.closedDate = value.closedDate ? value.closedDate : "";
    state.createdBy = value.createdBy ? value.createdBy : "";
    state.createdDate = value.createdDate ? value.createdDate : "";
    state.customerId = value.customerId ? value.customerId : "";
    state.customerName = value.customerName ? value.customerName : "";
    state.invoiceNo = value.invoiceNo ? value.invoiceNo : 0;
    state.idInvoice = value.idInvoice ? value.idInvoice : 0;
    state.invoiceType = value.invoiceType ? value.invoiceType : 1;
    state.refNo = value.refNo ? value.refNo : "";
    state.statusDesc = value.statusDesc ? value.statusDesc : "";
    state.idCoverNote = value.idCoverNote ? value.idCoverNote : 0;
    state.modifiedBy = value.modifiedBy ? value.modifiedBy : "";
    state.modifiedDate = value.modifiedDate ? value.modifiedDate : "";

    state.attachActiveDate = invoiceClass.attachActiveDate
      ? invoiceClass.attachActiveDate
      : "";
    state.businessCategory = invoiceClass.businessCategory
      ? invoiceClass.businessCategory
      : "";
    state.businessName = invoiceClass.businessName
      ? invoiceClass.businessName
      : "";
    state.description = invoiceClass.description
      ? invoiceClass.description
      : "";
    state.insurancePeriodEnd = invoiceClass.insurancePeriodEnd
      ? invoiceClass.insurancePeriodEnd
      : "";
    state.insurancePeriodFrom = invoiceClass.insurancePeriodFrom
      ? invoiceClass.insurancePeriodFrom
      : "";
    state.insured = invoiceClass.insured ? invoiceClass.insured : "";
    state.location = invoiceClass.location ? invoiceClass.location : "";
    state.masterPolicy = invoiceClass.masterPolicy
      ? invoiceClass.masterPolicy
      : "";
    state.policyNo = invoiceClass.policyNo ? invoiceClass.policyNo : "";
    state.riskCode = invoiceClass.riskCode ? invoiceClass.riskCode : "";
    state.riskName = invoiceClass.riskName ? invoiceClass.riskName : "";
    state.idEmployee = value.idEmployee ? value.idEmployee : 0;
    state.segmentCode = value.segmentCode ? value.segmentCode : "";
    state.subSegmentCode = value.subSegmentCode ? value.subSegmentCode : "";

    state.clientVatPercentage = invoiceValue.clientVatPercentage
      ? invoiceValue.clientVatPercentage
      : 10;
    state.currency = invoiceValue.currency ? invoiceValue.currency : "";
    state.exchangeRate = invoiceValue.exchangeRate
      ? invoiceValue.exchangeRate
      : 0;
    state.incomeBrokerage = invoiceValue.incomeBrokerage
      ? invoiceValue.incomeBrokerage
      : 0;
    state.incomeNet = invoiceValue.incomeNet ? invoiceValue.incomeNet : 0;
    state.incomeTotal = invoiceValue.incomeTotal ? invoiceValue.incomeTotal : 0;
    state.incomeVarious = invoiceValue.incomeVarious
      ? invoiceValue.incomeVarious
      : 0;
    state.incomeVat = invoiceValue.incomeVat ? invoiceValue.incomeVat : 0;
    state.insurerPph23Percentage = invoiceValue.insurerPph23Percentage
      ? invoiceValue.insurerPph23Percentage
      : 2;
    state.insurerVatPercentage = invoiceValue.insurerVatPercentage
      ? invoiceValue.insurerVatPercentage
      : 10;
    state.premiumRate = invoiceValue.premiumRate ? invoiceValue.premiumRate : 0;
    state.sumInsured = invoiceValue.sumInsured ? invoiceValue.sumInsured : 0;
    state.toClientAdm = invoiceValue.toClientAdm ? invoiceValue.toClientAdm : 0;
    state.toClientGross = invoiceValue.toClientGross
      ? invoiceValue.toClientGross
      : 0;
    state.toClientGrossTotal = invoiceValue.toClientGrossTotal
      ? invoiceValue.toClientGrossTotal
      : 0;
    state.toClientPolEndCost = invoiceValue.toClientPolEndCost
      ? invoiceValue.toClientPolEndCost
      : 0;
    state.toClientPremium = invoiceValue.toClientPremium
      ? invoiceValue.toClientPremium
      : 0;
    state.toClientStampDuty = invoiceValue.toClientStampDuty
      ? invoiceValue.toClientStampDuty
      : 0;
    state.toClientVat = invoiceValue.toClientVat ? invoiceValue.toClientVat : 0;
    state.toUnderwriterGross = invoiceValue.toUnderwriterGross
      ? invoiceValue.toUnderwriterGross
      : 0;
    state.toUnderwriterNet = invoiceValue.toUnderwriterNet
      ? invoiceValue.toUnderwriterNet
      : 0;
    state.toUnderwriterPolEndCost = invoiceValue.toUnderwriterPolEndCost
      ? invoiceValue.toUnderwriterPolEndCost
      : 0;
    state.toUnderwriterPph23 = invoiceValue.toUnderwriterPph23
      ? invoiceValue.toUnderwriterPph23
      : 0;
    state.toUnderwriterPremium = invoiceValue.toUnderwriterPremium
      ? invoiceValue.toUnderwriterPremium
      : 0;
    state.toUnderwriterStampDuty = invoiceValue.toUnderwriterStampDuty
      ? invoiceValue.toUnderwriterStampDuty
      : 0;
    state.toUnderwriterVat = invoiceValue.toUnderwriterVat
      ? invoiceValue.toUnderwriterVat
      : 0;
    state.variousAddBrokerage = invoiceValue.variousAddBrokerage
      ? invoiceValue.variousAddBrokerage
      : 0;
    state.variousCommissionOut = invoiceValue.variousCommissionOut
      ? invoiceValue.variousCommissionOut
      : 0;
    state.variousOthers = invoiceValue.variousOthers
      ? invoiceValue.variousOthers
      : 0;
    state.variousTotal = invoiceValue.variousTotal
      ? invoiceValue.variousTotal
      : 0;

    state.insuranceSharingList = invoiceInsurance.insuranceSharingList
      ? invoiceInsurance.insuranceSharingList
      : [];
    state.premium = invoiceInsurance.premium ? invoiceInsurance.premium : 0;
    state.efPremium = invoiceInsurance.efPremium
      ? invoiceInsurance.efPremium
      : 0;
    state.totalAdmincostAmount = invoiceInsurance.totalAdmincostAmount
      ? invoiceInsurance.totalAdmincostAmount
      : 0;
    state.totalAmount = invoiceInsurance.totalAmount
      ? invoiceInsurance.totalAmount
      : 0;
    state.totalBrokerage = invoiceInsurance.totalBrokerage
      ? invoiceInsurance.totalBrokerage
      : 0;
    state.totalBrokerageAmount = invoiceInsurance.totalBrokerageAmount
      ? invoiceInsurance.totalBrokerageAmount
      : 0;
    state.totalEfAmount = invoiceInsurance.totalEfAmount
      ? invoiceInsurance.totalEfAmount
      : 0;
    state.totalEfPercentage = invoiceInsurance.totalEfPercentage
      ? invoiceInsurance.totalEfPercentage
      : 0;
    state.totalEfSharingAmount = invoiceInsurance.totalEfSharingAmount
      ? invoiceInsurance.totalEfSharingAmount
      : 0;
    state.totalGrossAmount = invoiceInsurance.totalGrossAmount
      ? invoiceInsurance.totalGrossAmount
      : 0;
    state.totalPPH23 = invoiceInsurance.totalPPH23
      ? invoiceInsurance.totalPPH23
      : 0;
    state.totalPercentage = invoiceInsurance.totalPercentage
      ? invoiceInsurance.totalPercentage
      : 0;
    state.totalStampdutyAmount = invoiceInsurance.totalStampdutyAmount
      ? invoiceInsurance.totalStampdutyAmount
      : 0;
    state.totalTotalAmount = invoiceInsurance.totalTotalAmount
      ? invoiceInsurance.totalTotalAmount
      : 0;
    state.totalVat = invoiceInsurance.totalVat ? invoiceInsurance.totalVat : 0;
    state.multiInsured = invoiceInsurance.multiInsured
      ? invoiceInsurance.multiInsured
      : "";

    state.docType = invoiceInstallment.docType
      ? invoiceInstallment.docType
      : "";
    state.filename = invoiceInstallment.filename
      ? invoiceInstallment.filename
      : "";
    state.toClientInstallmentList = invoiceInstallment.toClientInstallmentList
      ? invoiceInstallment.toClientInstallmentList
      : [];
    state.toClientTotalAdminCost = invoiceInstallment.toClientTotalAdminCost
      ? invoiceInstallment.toClientTotalAdminCost
      : 0;
    state.toClientTotalAmount = invoiceInstallment.toClientTotalAmount
      ? invoiceInstallment.toClientTotalAmount
      : 0;
    state.toClientTotalGrossAmount = invoiceInstallment.toClientTotalGrossAmount
      ? invoiceInstallment.toClientTotalGrossAmount
      : 0;
    state.toClientTotalPercentage = invoiceInstallment.toClientTotalPercentage
      ? invoiceInstallment.toClientTotalPercentage
      : 0;
    state.toClientTotalStampdutyAmount = invoiceInstallment.toClientTotalStampdutyAmount
      ? invoiceInstallment.toClientTotalStampdutyAmount
      : 0;
    state.toClientTotalTotalAmount = invoiceInstallment.toClientTotalTotalAmount
      ? invoiceInstallment.toClientTotalTotalAmount
      : 0;
    state.toClientTotalVatAmount = invoiceInstallment.toClientTotalVatAmount
      ? invoiceInstallment.toClientTotalVatAmount
      : 0;
    state.toUnderwriterInstallmentList = invoiceInstallment.toUnderwriterInstallmentList
      ? invoiceInstallment.toUnderwriterInstallmentList
      : [];
    state.toUnderwriterTotalAdminCost = invoiceInstallment.toUnderwriterTotalAdminCost
      ? invoiceInstallment.toUnderwriterTotalAdminCost
      : 0;
    state.toUnderwriterTotalAmount = invoiceInstallment.toUnderwriterTotalAmount
      ? invoiceInstallment.toUnderwriterTotalAmount
      : 0;
    state.toUnderwriterTotalBrokerageAmount = invoiceInstallment.toUnderwriterTotalBrokerageAmount
      ? invoiceInstallment.toUnderwriterTotalBrokerageAmount
      : 0;
    state.toUnderwriterTotalGrossAmount = invoiceInstallment.toUnderwriterTotalGrossAmount
      ? invoiceInstallment.toUnderwriterTotalGrossAmount
      : 0;
    state.toUnderwriterTotalPercentage = invoiceInstallment.toUnderwriterTotalPercentage
      ? invoiceInstallment.toUnderwriterTotalPercentage
      : 0;
    state.toUnderwriterTotalPph23Amount = invoiceInstallment.toUnderwriterTotalPph23Amount
      ? invoiceInstallment.toUnderwriterTotalPph23Amount
      : 0;
    state.toUnderwriterTotalStampdutyAmount = invoiceInstallment.toUnderwriterTotalStampdutyAmount
      ? invoiceInstallment.toUnderwriterTotalStampdutyAmount
      : 0;
    state.toUnderwriterTotalTotalAmount = invoiceInstallment.toUnderwriterTotalTotalAmount
      ? invoiceInstallment.toUnderwriterTotalTotalAmount
      : 0;
    state.toUnderwriterTotalVatAmount = invoiceInstallment.toUnderwriterTotalVatAmount
      ? invoiceInstallment.toUnderwriterTotalVatAmount
      : 0;

    // state.agentId = invoiceAgent.agentId ? invoiceAgent.agentId : ""
    // state.agentName = invoiceAgent.agentName ? invoiceAgent.agentName : ""
    // state.amount = invoiceAgent.amount ? invoiceAgent.amount : 0
  },
  [types.MUTATE_TAB_INVOICE]: (state, value) => {
    state.tabInvoice = value;
  },
  [types.MUTATE_STATUS_SUBMIT]: (state, value) => {
    state.statusSubmit = value;
  },
  [types.MUTATE_STATUS_ENGINEERING_FEE]: (state, value) => {
    state.statusEngineeringFee = value;
  },
  [types.MUTATE_STATUS_PAGE]: (state, value) => {
    state.statusPage = value;
  },
  [types.MUTATE_RESET_STATE_REMITTANCE]: (state) => {
    Object.assign(state, defaultState());
  },
  [types.MUTATE_REQUIRED_CUSTOMER]: (state, value) => {
    state.errorCostumer = value;
  },
  [types.MUTATE_REQUIRED_CLASS_OF_RISK]: (state, value) => {
    state.errorClassOfRisk = value;
  },
  [types.MUTATE_REQUIRED_INSURENCE_PERIODE_END]: (state, value) => {
    state.errorPeriodeEnd = value;
  },
  [types.MUTATE_REQUIRED_INSURENCE_PERIODE_FROM]: (state, value) => {
    state.errorPeriodeFrom = value;
  },
  [types.MUTATE_REQUIRED_ACTIVE_DATE]: (state, value) => {
    state.errorActiveDate = value;
  },
  [types.MUTATE_REQUIRED_BUSINESS_CATEGORY]: (state, value) => {
    state.errorBusinessCategory = value;
  },
  [types.MUTATE_REQUIRED_ACCOUNT_EXECUTIVE]: (state, value) => {
    state.errorAccountExecutive = value;
  },
  [types.MUTATE_REQUIRED_SEGMENT]: (state, value) => {
    state.errorSegment = value;
  },
  [types.MUTATE_STATUS_APPROVE]: (state, value) => {
    state.statusApprove = value;
  },
  [types.MUTATE_STATUS_DATA]: (state, value) => {
    state.statusData = value;
  },

  [types.MUTATE_ID_INVOICE]: (state, value) => {
    state.idInvoice = value;
  },
  [types.MUTATE_CUSTOMER]: (state, value) => {
    state.customer = value;
  },
  [types.MUTATE_CUSTOMER_ID]: (state, value) => {
    state.customerId = value;
  },
  [types.MUTATE_CUSTOMER_NAME]: (state, value) => {
    state.customerName = value;
  },
  [types.MUTATE_REFF_NO]: (state, value) => {
    state.refNo = value;
  },
  [types.MUTATE_STATUS_DESC]: (state, value) => {
    state.statusDesc = value;
  },
  [types.MUTATE_ID_COVER_NOTE]: (state, value) => {
    state.idCoverNote = value;
  },
  [types.MUTATE_INVOICE_TYPE]: (state, value) => {
    state.invoiceType = value;
  },
  [types.MUTATE_CREATE_BY]: (state, value) => {
    state.createdBy = value;
  },
  [types.MUTATE_CREATE_DATE]: (state, value) => {
    state.createdDate = value;
  },
  [types.MUTATE_MODIFIED_BY]: (state, value) => {
    state.modifiedBy = value;
  },
  [types.MUTATE_MODIFIED_DATE]: (state, value) => {
    state.modifiedDate = value;
  },
  [types.MUTATE_APPROVE_BY]: (state, value) => {
    state.approvedBy = value;
  },
  [types.MUTATE_APPROVE_DATE]: (state, value) => {
    state.approvedDate = value;
  },
  [types.MUTATE_CLOSE_BY]: (state, value) => {
    state.closedBy = value;
  },
  [types.MUTATE_CLOSE_DATE]: (state, value) => {
    state.closedDate = value;
  },
  [types.MUTATE_DATA_EMPLOYEE]: (state, value) => {
    state.dataEmployee = value;
  },
  [types.MUTATE_DATA_SEGMENT]: (state, value) => {
    state.dataSegment = value;
  },
  [types.MUTATE_DATA_SUB_SEGMENT]: (state, value) => {
    state.dataSubSegment = value;
  },

  // ------CLASS TAB------
  [types.MUTATE_ACTIVE_DATE]: (state, value) => {
    state.attachActiveDate = value;
  },
  [types.MUTATE_BUSINESS_CATEGORY]: (state, value) => {
    state.businessCategory = value;
  },
  [types.MUTATE_BUSINESS_NAME]: (state, value) => {
    state.businessName = value;
  },
  [types.MUTATE_DESCRIPTION]: (state, value) => {
    state.description = value;
  },
  [types.MUTATE_INSURENCE_PERIODE_END]: (state, value) => {
    state.insurancePeriodEnd = value;
  },
  [types.MUTATE_INSURENCE_PERIODE_FROM]: (state, value) => {
    state.insurancePeriodFrom = value;
  },
  [types.MUTATE_INSURED]: (state, value) => {
    state.insured = value;
  },
  [types.MUTATE_LOCATION]: (state, value) => {
    state.location = value;
  },
  [types.MUTATE_MASTER_POLICY]: (state, value) => {
    state.masterPolicy = value;
  },
  [types.MUTATE_POLICY_NO]: (state, value) => {
    state.policyNo = value;
  },
  [types.MUTATE_RISK_CODE]: (state, value) => {
    state.riskCode = value;
  },
  [types.MUTATE_RISK_NAME]: (state, value) => {
    state.riskName = value;
  },
  [types.MUTATE_ID_ACCOUNT_EXECUTIVE]: (state, value) => {
    state.idEmployee = value;
  },
  [types.MUTATE_SEGMENT_CODE]: (state, value) => {
    state.segmentCode = value;
  },
  [types.MUTATE_SUB_SEGMENT_CODE]: (state, value) => {
    state.subSegmentCode = value;
  },

  // ------VALUE TAB------
  [types.MUTATE_ENGINEERING_PERCENTAGE]: (state, value) => {
    state.engineeringPercentage = value;
  },
  [types.MUTATE_CLIENT_VAT_PERCENTAGE]: (state, value) => {
    state.clientVatPercentage = value;
  },
  [types.MUTATE_INSURER_VAT_PERCENTAGE]: (state, value) => {
    state.insurerVatPercentage = value;
  },
  [types.MUTATE_INSURER_PPH_PERCENTAGE]: (state, value) => {
    state.insurerPph23Percentage = value;
  },
  [types.MUTATE_CURRENCY]: (state, value) => {
    state.currency = value;
  },
  [types.MUTATE_EXCHANGE_RATE]: (state, value) => {
    state.exchangeRate = value;
  },
  [types.MUTATE_INCOME_BROKERAGE]: (state, value) => {
    state.incomeBrokerage = value;
  },
  [types.MUTATE_INCOME_NET]: (state, value) => {
    state.incomeNet = value;
  },
  [types.MUTATE_INCOME_TOTAL]: (state, value) => {
    state.incomeTotal = value;
  },
  [types.MUTATE_INCOME_VARIOUS]: (state, value) => {
    state.incomeVarious = value;
  },
  [types.MUTATE_INCOME_VAT]: (state, value) => {
    state.incomeVat = value;
  },
  [types.MUTATE_PREMIUM_RATE]: (state, value) => {
    state.premiumRate = value;
  },
  [types.MUTATE_SUM_INSURED]: (state, value) => {
    state.sumInsured = value;
  },
  [types.MUTATE_TO_CLIENT_ADM]: (state, value) => {
    state.toClientAdm = value;
  },
  [types.MUTATE_TO_CLIENT_GROSS]: (state, value) => {
    state.toClientGross = value;
  },
  [types.MUTATE_TO_CLIENT_GROSS_TOTAL]: (state, value) => {
    state.toClientGrossTotal = value;
  },
  [types.MUTATE_TO_CLIENT_POL_END_COST]: (state, value) => {
    state.toClientPolEndCost = value;
  },
  [types.MUTATE_TO_CLIENT_PREMIUM]: (state, value) => {
    state.toClientPremium = value;
  },
  [types.MUTATE_TO_CLIENT_STAMP_DUTY]: (state, value) => {
    state.toClientStampDuty = value;
  },
  [types.MUTATE_TO_CLIENT_VAT]: (state, value) => {
    state.toClientVat = value;
  },
  [types.MUTATE_TO_UNDERWRITER_GROSS]: (state, value) => {
    state.toUnderwriterGross = value;
  },
  [types.MUTATE_TO_UNDERWRITER_NET]: (state, value) => {
    state.toUnderwriterNet = value;
  },
  [types.MUTATE_TO_UNDERWRITER_POL_END_COST]: (state, value) => {
    state.toUnderwriterPolEndCost = value;
  },
  [types.MUTATE_TO_UNDERWRITER_PPH23]: (state, value) => {
    state.toUnderwriterPph23 = value;
  },
  [types.MUTATE_TO_UNDERWRITER_PREMIUM]: (state, value) => {
    state.toUnderwriterPremium = value;
  },
  [types.MUTATE_TO_UNDERWRITER_STAMP_DUTY]: (state, value) => {
    state.toUnderwriterStampDuty = value;
  },
  [types.MUTATE_TO_UNDERWRITER_VAT]: (state, value) => {
    state.toUnderwriterVat = value;
  },
  [types.MUTATE_VARIOUS_ADD_BROKERAGE]: (state, value) => {
    state.variousAddBrokerage = value;
  },
  [types.MUTATE_VARIOUS_COMMISSION_OUT]: (state, value) => {
    state.variousCommissionOut = value;
  },
  [types.MUTATE_VARIOUS_OTHERS]: (state, value) => {
    state.variousOthers = value;
  },
  [types.MUTATE_VARIOUS_TOTAL]: (state, value) => {
    state.variousTotal = value;
  },
  [types.MUTATE_PREMIUM_CLIENT_INSTALLMENT]: (state, value) => {
    state.premiumClientInstallment = value;
  },

  // ------INSURANCE TAB------
  [types.MUTATE_INSURANCE_SHARING_LIST_UNDER]: (state, value) => {
    state.insuranceSharingListUnder = value;
  },
  [types.MUTATE_INSURANCE_SHARING_LIST]: (state, value) => {
    state.insuranceSharingList = value;
  },
  [types.MUTATE_PREMIUM]: (state, value) => {
    state.premium = value;
  },
  [types.MUTATE_EF_PREMIUM]: (state, value) => {
    state.efPremium = value;
  },
  [types.MUTATE_TOTAL_ADMIN_COST]: (state, value) => {
    state.totalAdmincostAmount = value;
  },
  [types.MUTATE_TOTAL_AMOUNT]: (state, value) => {
    state.totalAmount = value;
  },
  [types.MUTATE_TOTAL_BROKERAGE]: (state, value) => {
    state.totalBrokerage = value;
  },
  [types.MUTATE_TOTAL_BROKERAGE_AMOUNT]: (state, value) => {
    state.totalBrokerageAmount = value;
  },
  [types.MUTATE_TOTAL_EF_AMOUNT]: (state, value) => {
    state.totalEfAmount = value;
  },
  [types.MUTATE_TOTAL_EF_PERCENTAGE]: (state, value) => {
    state.totalEfPercentage = value;
  },
  [types.MUTATE_TOTAL_EF_SHARING_AMOUNT]: (state, value) => {
    state.totalEfSharingAmount = value;
  },
  [types.MUTATE_TOTAL_GROSS]: (state, value) => {
    state.totalGrossAmount = value;
  },
  [types.MUTATE_TOTAL_PPH23]: (state, value) => {
    state.totalPPH23 = value;
  },
  [types.MUTATE_TOTAL_PERCENTAGE]: (state, value) => {
    state.totalPercentage = value;
  },
  [types.MUTATE_TOTAL_STAMP_DUTY]: (state, value) => {
    state.totalStampdutyAmount = value;
  },
  [types.MUTATE_TOTAL_TOTAL_AMOUNT]: (state, value) => {
    state.totalTotalAmount = value;
  },
  [types.MUTATE_TOTAL_VAT]: (state, value) => {
    state.totalVat = value;
  },
  [types.MUTATE_MULTI_INSURED]: (state, value) => {
    state.multiInsured = value;
  },

  // ------INSTALLMENT TAB------
  [types.MUTATE_DOC_TYPE]: (state, value) => {
    state.docType = value;
  },
  [types.MUTATE_FILE_NAME]: (state, value) => {
    state.filename = value;
  },
  [types.MUTATE_TO_CLIENT_INSTALLMENT_LIST]: (state, value) => {
    state.toClientInstallmentList = value;
  },
  [types.MUTATE_TO_CLIENT_TOTAL_ADMIN_COST]: (state, value) => {
    state.toClientTotalAdminCost = value;
  },
  [types.MUTATE_TO_CLIENT_TOTAL_AMOUNT]: (state, value) => {
    state.toClientTotalAmount = value;
  },
  [types.MUTATE_TO_CLIENT_TOTAL_GROSS]: (state, value) => {
    state.toClientTotalGrossAmount = value;
  },
  [types.MUTATE_TO_CLIENT_TOTAL_PERCENTAGE]: (state, value) => {
    state.toClientTotalPercentage = value;
  },
  [types.MUTATE_TO_CLIENT_TOTAL_STAMP_DUTY]: (state, value) => {
    state.toClientTotalStampdutyAmount = value;
  },
  [types.MUTATE_TO_CLIENT_TOTAL_TOTAL_AMOUNT]: (state, value) => {
    state.toClientTotalTotalAmount = value;
  },
  [types.MUTATE_TO_CLIENT_TOTAL_VAT]: (state, value) => {
    state.toClientTotalVatAmount = value;
  },
  [types.MUTATE_TO_UNDERWRITER_INSTALLMENT_LIST]: (state, value) => {
    state.toUnderwriterInstallmentList = value;
  },
  [types.MUTATE_TO_UNDERWRITER_TOTAL_ADMIN_COST]: (state, value) => {
    state.toUnderwriterTotalAdminCost = value;
  },
  [types.MUTATE_TO_UNDERWRITER_TOTAL_AMOUNT]: (state, value) => {
    state.toUnderwriterTotalAmount = value;
  },
  [types.MUTATE_TO_UNDERWRITER_TOTAL_BROKERAGE]: (state, value) => {
    state.toUnderwriterTotalBrokerageAmount = value;
  },
  [types.MUTATE_TO_UNDERWRITER_TOTAL_GROSS]: (state, value) => {
    state.toUnderwriterTotalGrossAmount = value;
  },
  [types.MUTATE_TO_UNDERWRITER_TOTAL_PERCENTAGE]: (state, value) => {
    state.toUnderwriterTotalPercentage = value;
  },
  [types.MUTATE_TO_UNDERWRITER_TOTAL_PPH]: (state, value) => {
    state.toUnderwriterTotalPph23Amount = value;
  },
  [types.MUTATE_TO_UNDERWRITER_TOTAL_STAMP_DUTY]: (state, value) => {
    state.toUnderwriterTotalStampdutyAmount = value;
  },
  [types.MUTATE_TO_UNDERWRITER_TOTAL_TOTAL_AMOUNT]: (state, value) => {
    state.toUnderwriterTotalTotalAmount = value;
  },
  [types.MUTATE_TO_UNDERWRITER_TOTAL_VAT]: (state, value) => {
    state.toUnderwriterTotalVatAmount = value;
  },

  // ------VALUE TAB------
  [types.MUTATE_AGENT_ID]: (state, value) => {
    state.agentId = value;
  },
  [types.MUTATE_AGENT_NAME]: (state, value) => {
    state.agentName = value;
  },
  [types.MUTATE_AMOUNT]: (state, value) => {
    state.amount = value;
  },
  [types.MUTATE_AGENT_LIST]: (state, value) => {
    state.invoiceAgentList = value;
  },
  [types.MUTATE_AGENT_TOTAL_AMOUNT]: (state, value) => {
    state.totalAmountAgent = value;
  },
};

const actions = {
  [types.ACTION_TAB_INVOICE]: ({ commit }, payload) => {
    commit(types.MUTATE_TAB_INVOICE, payload);
  },
  [types.ACTION_STATUS_SUBMIT]: ({ commit }, payload) => {
    commit(types.MUTATE_STATUS_SUBMIT, payload);
  },
  [types.ACTION_ADD_STATE]: ({ commit }, payload) => {
    commit(types.MUTATE_ADD_STATE, payload);
  },
  [types.ACTION_STATUS_ENGINEERING_FEE]: ({ commit }, payload) => {
    commit(types.MUTATE_STATUS_ENGINEERING_FEE, payload);
  },
  [types.ACTION_STATUS_PAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_STATUS_PAGE, payload);
  },
  [types.ACTION_RESET_STATE_REMITTANCE]: ({ commit }) => {
    commit(types.MUTATE_RESET_STATE_REMITTANCE);
  },
  [types.ACTION_REQUIRED_CUSTOMER]: ({ commit }, payload) => {
    commit(types.MUTATE_REQUIRED_CUSTOMER, payload);
  },
  [types.ACTION_REQUIRED_CLASS_OF_RISK]: ({ commit }, payload) => {
    commit(types.MUTATE_REQUIRED_CLASS_OF_RISK, payload);
  },
  [types.ACTION_REQUIRED_INSURENCE_PERIODE_END]: ({ commit }, payload) => {
    commit(types.MUTATE_REQUIRED_INSURENCE_PERIODE_END, payload);
  },
  [types.ACTION_REQUIRED_INSURENCE_PERIODE_FROM]: ({ commit }, payload) => {
    commit(types.MUTATE_REQUIRED_INSURENCE_PERIODE_FROM, payload);
  },
  [types.ACTION_REQUIRED_ACTIVE_DATE]: ({ commit }, payload) => {
    commit(types.MUTATE_REQUIRED_ACTIVE_DATE, payload);
  },
  [types.ACTION_REQUIRED_BUSINESS_CATEGORY]: ({ commit }, payload) => {
    commit(types.MUTATE_REQUIRED_BUSINESS_CATEGORY, payload);
  },
  [types.ACTION_REQUIRED_ACCOUNT_EXECUTIVE]: ({ commit }, payload) => {
    commit(types.MUTATE_REQUIRED_ACCOUNT_EXECUTIVE, payload);
  },
  [types.ACTION_REQUIRED_SEGMENT]: ({ commit }, payload) => {
    commit(types.MUTATE_REQUIRED_SEGMENT, payload);
  },
  [types.ACTION_STATUS_APPROVE]: ({ commit }, payload) => {
    commit(types.MUTATE_STATUS_APPROVE, payload);
  },
  [types.ACTION_STATUS_DATA]: ({ commit }, payload) => {
    commit(types.MUTATE_STATUS_DATA, payload);
  },

  [types.ACTION_ID_INVOICE]: ({ commit }, payload) => {
    commit(types.MUTATE_ID_INVOICE, payload);
  },
  [types.ACTION_CUSTOMER]: ({ commit }, payload) => {
    commit(types.MUTATE_CUSTOMER, payload);
  },
  [types.ACTION_CUSTOMER_ID]: ({ commit }, payload) => {
    commit(types.MUTATE_CUSTOMER_ID, payload);
  },
  [types.ACTION_CUSTOMER_NAME]: ({ commit }, payload) => {
    commit(types.MUTATE_CUSTOMER_NAME, payload);
  },
  [types.ACTION_REFF_NO]: ({ commit }, payload) => {
    commit(types.MUTATE_REFF_NO, payload);
  },
  [types.ACTION_STATUS_DESC]: ({ commit }, payload) => {
    commit(types.MUTATE_STATUS_DESC, payload);
  },
  [types.ACTION_ID_COVER_NOTE]: ({ commit }, payload) => {
    commit(types.MUTATE_ID_COVER_NOTE, payload);
  },
  [types.ACTION_INVOICE_TYPE]: ({ commit }, payload) => {
    commit(types.MUTATE_INVOICE_TYPE, payload);
  },
  [types.ACTION_CREATE_BY]: ({ commit }, payload) => {
    commit(types.MUTATE_CREATE_BY, payload);
  },
  [types.ACTION_CREATE_DATE]: ({ commit }, payload) => {
    commit(types.MUTATE_CREATE_DATE, payload);
  },
  [types.ACTION_MODIFIED_BY]: ({ commit }, payload) => {
    commit(types.MUTATE_MODIFIED_BY, payload);
  },
  [types.ACTION_MODIFIED_DATE]: ({ commit }, payload) => {
    commit(types.MUTATE_MODIFIED_DATE, payload);
  },
  [types.ACTION_DATA_EMPLOYEE]: ({ commit }, payload) => {
    commit(types.MUTATE_DATA_EMPLOYEE, payload);
  },
  [types.ACTION_DATA_SEGMENT]: ({ commit }, payload) => {
    commit(types.MUTATE_DATA_SEGMENT, payload);
  },
  [types.ACTION_DATA_SUB_SEGMENT]: ({ commit }, payload) => {
    commit(types.MUTATE_DATA_SUB_SEGMENT, payload);
  },

  // ------CLASS TAB------
  [types.ACTION_ACTIVE_DATE]: ({ commit }, payload) => {
    commit(types.MUTATE_ACTIVE_DATE, payload);
  },
  [types.ACTION_BUSINESS_CATEGORY]: ({ commit }, payload) => {
    commit(types.MUTATE_BUSINESS_CATEGORY, payload);
  },
  [types.ACTION_BUSINESS_NAME]: ({ commit }, payload) => {
    commit(types.MUTATE_BUSINESS_NAME, payload);
  },
  [types.ACTION_DESCRIPTION]: ({ commit }, payload) => {
    commit(types.MUTATE_DESCRIPTION, payload);
  },
  [types.ACTION_INSURENCE_PERIODE_END]: ({ commit }, payload) => {
    commit(types.MUTATE_INSURENCE_PERIODE_END, payload);
  },
  [types.ACTION_INSURENCE_PERIODE_FROM]: ({ commit }, payload) => {
    commit(types.MUTATE_INSURENCE_PERIODE_FROM, payload);
  },
  [types.ACTION_INSURED]: ({ commit }, payload) => {
    commit(types.MUTATE_INSURED, payload);
  },
  [types.ACTION_LOCATION]: ({ commit }, payload) => {
    commit(types.MUTATE_LOCATION, payload);
  },
  [types.ACTION_MASTER_POLICY]: ({ commit }, payload) => {
    commit(types.MUTATE_MASTER_POLICY, payload);
  },
  [types.ACTION_POLICY_NO]: ({ commit }, payload) => {
    commit(types.MUTATE_POLICY_NO, payload);
  },
  [types.ACTION_RISK_CODE]: ({ commit }, payload) => {
    commit(types.MUTATE_RISK_CODE, payload);
  },
  [types.ACTION_RISK_NAME]: ({ commit }, payload) => {
    commit(types.MUTATE_RISK_NAME, payload);
  },
  [types.ACTION_ID_ACCOUNT_EXECUTIVE]: ({ commit }, payload) => {
    commit(types.MUTATE_ID_ACCOUNT_EXECUTIVE, payload);
  },
  [types.ACTION_SEGMENT_CODE]: ({ commit }, payload) => {
    commit(types.MUTATE_SEGMENT_CODE, payload);
  },
  [types.ACTION_SUB_SEGMENT_CODE]: ({ commit }, payload) => {
    commit(types.MUTATE_SUB_SEGMENT_CODE, payload);
  },

  // ------VALUE TAB------
  [types.ACTION_ENGINEERING_PERCENTAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_ENGINEERING_PERCENTAGE, payload);
  },
  [types.ACTION_CLIENT_VAT_PERCENTAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_CLIENT_VAT_PERCENTAGE, payload);
  },
  [types.ACTION_INSURER_VAT_PERCENTAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_INSURER_VAT_PERCENTAGE, payload);
  },
  [types.ACTION_INSURER_PPH_PERCENTAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_INSURER_PPH_PERCENTAGE, payload);
  },
  [types.ACTION_CURRENCY]: ({ commit }, payload) => {
    commit(types.MUTATE_CURRENCY, payload);
  },
  [types.ACTION_EXCHANGE_RATE]: ({ commit }, payload) => {
    commit(types.MUTATE_EXCHANGE_RATE, payload);
  },
  [types.ACTION_INCOME_BROKERAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_INCOME_BROKERAGE, payload);
  },
  [types.ACTION_INCOME_NET]: ({ commit }, payload) => {
    commit(types.MUTATE_INCOME_NET, payload);
  },
  [types.ACTION_INCOME_TOTAL]: ({ commit }, payload) => {
    commit(types.MUTATE_INCOME_TOTAL, payload);
  },
  [types.ACTION_INCOME_VARIOUS]: ({ commit }, payload) => {
    commit(types.MUTATE_INCOME_VARIOUS, payload);
  },
  [types.ACTION_INCOME_VAT]: ({ commit }, payload) => {
    commit(types.MUTATE_INCOME_VAT, payload);
  },
  [types.ACTION_PREMIUM_RATE]: ({ commit }, payload) => {
    commit(types.MUTATE_PREMIUM_RATE, payload);
  },
  [types.ACTION_SUM_INSURED]: ({ commit }, payload) => {
    commit(types.MUTATE_SUM_INSURED, payload);
  },
  [types.ACTION_TO_CLIENT_ADM]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_ADM, payload);
  },
  [types.ACTION_TO_CLIENT_GROSS]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_GROSS, payload);
  },
  [types.ACTION_TO_CLIENT_GROSS_TOTAL]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_GROSS_TOTAL, payload);
  },
  [types.ACTION_TO_CLIENT_POL_END_COST]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_POL_END_COST, payload);
  },
  [types.ACTION_TO_CLIENT_PREMIUM]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_PREMIUM, payload);
  },
  [types.ACTION_TO_CLIENT_STAMP_DUTY]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_STAMP_DUTY, payload);
  },
  [types.ACTION_TO_CLIENT_VAT]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_VAT, payload);
  },
  [types.ACTION_TO_UNDERWRITER_GROSS]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_GROSS, payload);
  },
  [types.ACTION_TO_UNDERWRITER_NET]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_NET, payload);
  },
  [types.ACTION_TO_UNDERWRITER_POL_END_COST]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_POL_END_COST, payload);
  },
  [types.ACTION_TO_UNDERWRITER_PPH23]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_PPH23, payload);
  },
  [types.ACTION_TO_UNDERWRITER_PREMIUM]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_PREMIUM, payload);
  },
  [types.ACTION_TO_UNDERWRITER_STAMP_DUTY]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_STAMP_DUTY, payload);
  },
  [types.ACTION_TO_UNDERWRITER_VAT]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_VAT, payload);
  },
  [types.ACTION_VARIOUS_ADD_BROKERAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_VARIOUS_ADD_BROKERAGE, payload);
  },
  [types.ACTION_VARIOUS_COMMISSION_OUT]: ({ commit }, payload) => {
    commit(types.MUTATE_VARIOUS_COMMISSION_OUT, payload);
  },
  [types.ACTION_VARIOUS_OTHERS]: ({ commit }, payload) => {
    commit(types.MUTATE_VARIOUS_OTHERS, payload);
  },
  [types.ACTION_VARIOUS_TOTAL]: ({ commit }, payload) => {
    commit(types.MUTATE_VARIOUS_TOTAL, payload);
  },
  [types.ACTION_PREMIUM_CLIENT_INSTALLMENT]: ({ commit }, payload) => {
    commit(types.MUTATE_PREMIUM_CLIENT_INSTALLMENT, payload);
  },

  // ------INSURANCE TAB------
  [types.ACTION_INSURANCE_SHARING_LIST_UNDER]: ({ commit }, payload) => {
    commit(types.MUTATE_INSURANCE_SHARING_LIST_UNDER, payload);
  },
  [types.ACTION_INSURANCE_SHARING_LIST]: ({ commit }, payload) => {
    commit(types.MUTATE_INSURANCE_SHARING_LIST, payload);
  },
  [types.ACTION_PREMIUM]: ({ commit }, payload) => {
    commit(types.MUTATE_PREMIUM, payload);
  },
  [types.ACTION_EF_PREMIUM]: ({ commit }, payload) => {
    commit(types.MUTATE_EF_PREMIUM, payload);
  },
  [types.ACTION_TOTAL_ADMIN_COST]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_ADMIN_COST, payload);
  },
  [types.ACTION_TOTAL_AMOUNT]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_AMOUNT, payload);
  },
  [types.ACTION_TOTAL_BROKERAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_BROKERAGE, payload);
  },
  [types.ACTION_TOTAL_BROKERAGE_AMOUNT]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_BROKERAGE_AMOUNT, payload);
  },
  [types.ACTION_TOTAL_EF_AMOUNT]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_EF_AMOUNT, payload);
  },
  [types.ACTION_TOTAL_EF_PERCENTAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_EF_PERCENTAGE, payload);
  },
  [types.ACTION_TOTAL_EF_SHARING_AMOUNT]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_EF_SHARING_AMOUNT, payload);
  },
  [types.ACTION_TOTAL_GROSS]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_GROSS, payload);
  },
  [types.ACTION_TOTAL_PPH23]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_PPH23, payload);
  },
  [types.ACTION_TOTAL_PERCENTAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_PERCENTAGE, payload);
  },
  [types.ACTION_TOTAL_STAMP_DUTY]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_STAMP_DUTY, payload);
  },
  [types.ACTION_TOTAL_TOTAL_AMOUNT]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_TOTAL_AMOUNT, payload);
  },
  [types.ACTION_TOTAL_VAT]: ({ commit }, payload) => {
    commit(types.MUTATE_TOTAL_VAT, payload);
  },
  [types.ACTION_MULTI_INSURED]: ({ commit }, payload) => {
    commit(types.MUTATE_MULTI_INSURED, payload);
  },

  // ------INSTALLMENT TAB------
  [types.ACTION_DOC_TYPE]: ({ commit }, payload) => {
    commit(types.MUTATE_DOC_TYPE, payload);
  },
  [types.ACTION_FILE_NAME]: ({ commit }, payload) => {
    commit(types.MUTATE_FILE_NAME, payload);
  },
  [types.ACTION_TO_CLIENT_INSTALLMENT_LIST]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_INSTALLMENT_LIST, payload);
  },
  [types.ACTION_TO_CLIENT_TOTAL_ADMIN_COST]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_TOTAL_ADMIN_COST, payload);
  },
  [types.ACTION_TO_CLIENT_TOTAL_AMOUNT]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_TOTAL_AMOUNT, payload);
  },
  [types.ACTION_TO_CLIENT_TOTAL_GROSS]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_TOTAL_GROSS, payload);
  },
  [types.ACTION_TO_CLIENT_TOTAL_PERCENTAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_TOTAL_PERCENTAGE, payload);
  },
  [types.ACTION_TO_CLIENT_TOTAL_STAMP_DUTY]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_TOTAL_STAMP_DUTY, payload);
  },
  [types.ACTION_TO_CLIENT_TOTAL_TOTAL_AMOUNT]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_TOTAL_TOTAL_AMOUNT, payload);
  },
  [types.ACTION_TO_CLIENT_TOTAL_VAT]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_CLIENT_TOTAL_VAT, payload);
  },
  [types.ACTION_TO_UNDERWRITER_INSTALLMENT_LIST]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_INSTALLMENT_LIST, payload);
  },
  [types.ACTION_TO_UNDERWRITER_TOTAL_ADMIN_COST]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_TOTAL_ADMIN_COST, payload);
  },
  [types.ACTION_TO_UNDERWRITER_TOTAL_AMOUNT]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_TOTAL_AMOUNT, payload);
  },
  [types.ACTION_TO_UNDERWRITER_TOTAL_BROKERAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_TOTAL_BROKERAGE, payload);
  },
  [types.ACTION_TO_UNDERWRITER_TOTAL_GROSS]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_TOTAL_GROSS, payload);
  },
  [types.ACTION_TO_UNDERWRITER_TOTAL_PERCENTAGE]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_TOTAL_PERCENTAGE, payload);
  },
  [types.ACTION_TO_UNDERWRITER_TOTAL_PPH]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_TOTAL_PPH, payload);
  },
  [types.ACTION_TO_UNDERWRITER_TOTAL_STAMP_DUTY]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_TOTAL_STAMP_DUTY, payload);
  },
  [types.ACTION_TO_UNDERWRITER_TOTAL_TOTAL_AMOUNT]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_TOTAL_TOTAL_AMOUNT, payload);
  },
  [types.ACTION_TO_UNDERWRITER_TOTAL_VAT]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_UNDERWRITER_TOTAL_VAT, payload);
  },

  // ------CLASS TAB------
  [types.ACTION_AGENT_ID]: ({ commit }, payload) => {
    commit(types.MUTATE_AGENT_ID, payload);
  },
  [types.ACTION_AGENT_NAME]: ({ commit }, payload) => {
    commit(types.MUTATE_AGENT_NAME, payload);
  },
  [types.ACTION_AMOUNT]: ({ commit }, payload) => {
    commit(types.MUTATE_AMOUNT, payload);
  },
  [types.ACTION_AGENT_LIST]: ({ commit }, payload) => {
    commit(types.MUTATE_AGENT_LIST, payload);
  },
  [types.ACTION_AGENT_TOTAL_AMOUNT]: ({ commit }, payload) => {
    commit(types.MUTATE_AGENT_TOTAL_AMOUNT, payload);
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
