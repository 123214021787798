<template>
  <v-app id="inspire">
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
    >
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
      <v-btn icon class="ms-5 px-1">
        <v-img
          src="@/assets/images/logo-ism.png"
          @click="goToHome"
          aspect-ratio="1"
          contain
        ></v-img>
      </v-btn>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Indo Safety Marine</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            elevation="0"
            :ripple="false"
          >
            <v-icon class="pr-2">mdi-account-outline</v-icon>
            <span style="text-transform: capitalize;" class="hidden-sm-and-down"
              >Halo, {{ user.name }}</span
            >
            <v-icon class="pl-2">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list nav>
          <v-list-item link color="primary" @click="logout">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <div
        style=" height: 100%;"
        v-show="this.$router.history.current.name === `customers`"
      >
        <v-container v-show="!activated" fluid class="hero" id="home">
          <div style="z-index: 999;">
            <h1>{{ wording }}</h1>
          </div>
        </v-container>

        <!-- Hero Section -->
        <v-container v-show="activated" fluid class="hero" id="home">
          <div style="z-index: 999;">
            <v-row align="center" justify="center">
              <v-col>
                <h1
                  elevation="10"
                  class="text-center titleBG"
                  style="margin-bottom: 10px;"
                >
                  Welcome
                  <span
                    style="color: rgb(234, 255, 0); border-style: solid; border-radius: 15px; padding: 5px;"
                    >{{ user.username }}</span
                  >
                </h1>
                <h1 v-if="userIsActive">Please make a product order here</h1>

                <v-btn
                  v-show="userIsActive && !waitingVerify"
                  v-if="userIsActive"
                  color="white"
                  elevation="5"
                  class="mt-5"
                  @click="goTo('formorder')"
                >
                  Click here to request order !
                </v-btn>
                <v-alert
                  v-show="!userIsActive && !waitingVerify"
                  tonal
                  v-if="alertMessage"
                  style="color: red; top: 0; background-color: yellow;"
                >
                  {{ alertMessage }}
                </v-alert>
                <v-alert
                  v-show="!userIsActive && waitingVerify"
                  tonal
                  v-if="alertMessage"
                  style="color: red; top: 0; background-color: yellow;"
                >
                  {{ alertWaiting }}
                </v-alert>
                <v-btn
                  v-show="
                    (!userIsActive && !waitingVerify) ||
                      (!userIsActive && waitingVerify)
                  "
                  color="lime"
                  elevation="5"
                  class="mt-5"
                  @click="goTo('customers-verify-form')"
                >
                  Activated Account
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
      <v-container
        v-show="this.$router.history.current.name != `customers`"
        fluid
        style="height: 100vh;"
      >
        <router-view />
      </v-container>
    </v-main>

    <footer class="footer" color="primary" elevation="5">
      <p>
        &copy; 2023 Indo Safety Marine
      </p>
    </footer>
  </v-app>
</template>

<script>
import {
  paramsCollection,
  firebaseAuth,
  customersCollection,
} from "../../firebase";

export default {
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    user: "",
    alertMessage:
      "Sorry, your account is not active yet! Click the Activated Account Button, to activate your account as an Indo Safety Marine Customer",
    alertWaiting: "Waiting for Admin Confirmation Your's Account",
    alertType: "amber",
    userIsActive: null,
    waitingVerify: null,
    activated: null,
    wording: "",
  }),
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     this.getListData();
  //   })
  // },
  // async beforeRouteUpdate(to, from, next) {
  //   await this.getListData();
  //   next();
  // },

  async created() {
    await this.getListData();
    await this.checkToken();
    let getUser = await localStorage.getItem("ismUser");
    this.user = JSON.parse(getUser);
  },
  computed: {},
  methods: {
    getListData() {
      this.loading = true;
      customersCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              customerId: doc.id,
              customerName: doc.data().customerName,
              address: doc.data().address,
              // npwp: doc.data().npwp,
              email: doc.data().email,
              telp: doc.data().telp,
              phone: doc.data().phone,
              isActive: doc.data().isActive,
              isVerify: doc.data().isVerify,
              isRegistered: doc.data().isRegistered,
              createdAt: doc.data().createdAt,
              createdBy: doc.data().createdBy,
              updatedAt: doc.data().updatedAt,
              updatedBy: doc.data().updatedBy,
            });
          });
          this.loading = false;

          this.items = newData.sort(function(a, b) {
            return a.createdAt > b.createdAt
              ? -1
              : a.createdAt < b.createdAt
              ? 1
              : 0;
          });

          let listCust = newData.sort(function(a, b) {
            return a.createdAt > b.createdAt
              ? -1
              : a.createdAt < b.createdAt
              ? 1
              : 0;
          });

          let cekDataByEmail = this.user.email;
          const result = listCust.find((item) => item.email === cekDataByEmail);
          if (result) {
            if (result.isRegistered && !result.isVerify && !result.isActive) {
              this.activated = true;
              // Kondisi jika user sudah register namun belum di verifikasi
              this.waitingVerify = true;
              localStorage.setItem("waitingVerify", true);
            } else if (
              result.isRegistered &&
              result.isVerify &&
              !result.isActive
            ) {
              // Kondisi jika user sudah register namun belum Aktif
              this.activated = false;
              localStorage.setItem("activated", false);
              this.wording =
                "MAAF AKUN ANDA SUDAH TIDAK AKTIF, SILAHKAN HUBUNGI ADMIN";
            } else {
              // Kondisi jika user sudah register namun sudah Aktif
              this.activated = true;
              this.waitingVerify = false;
              this.userIsActive = true;
              localStorage.setItem("activated", true);
              localStorage.setItem("waitingVerify", false);
              localStorage.setItem("userIsActive", true);
            }
          } else {
            // Kondisi jika user belum terdaftar
            this.activated = true;
            this.waitingVerify = false;
            this.userIsActive = false;
            localStorage.setItem("waitingVerify", false);
            localStorage.setItem("userIsActive", false);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async goTo(payload) {
      if (payload == "formorder") {
        this.$router.push({ path: `/customers/${payload}` });
      } else {
        this.$router.push({ path: `/customers/${payload}` });
      }
    },
    goToHome() {
      const isSuper = JSON.parse(localStorage.getItem("ismUser"));
      if (isSuper.role == "Admin") {
        this.$router.push("/home");
      } else {
        this.$router.push("/customers");
      }
    },
    async logout() {
      firebaseAuth
        .signOut()
        .then(() => {
          this.$router.replace({ name: "loginCust" });
          localStorage.clear();
        })
        .catch(() => {});
    },
    getAllDataParam() {
      paramsCollection
        .orderBy("createdAt", "desc")
        .get()
        .then((data) => {
          let params = [];
          data.forEach((doc) => {
            params.push({
              paramsId: doc.id,
              paramsGroup: doc.data().paramsGroup,
              params: doc.data().params,
              values: doc.data().values,
              status: doc.data().status,
              createdAt: doc.data().createdAt,
              createdBy: doc.data().createdBy,
              updatedAt: doc.data().updatedAt,
              updatedBy: doc.data().updatedBy,
            });
          });
          localStorage.setItem("masterParams", JSON.stringify(params));
        })
        .catch(() => {});
    },
    checkToken() {
      let currentToken = JSON.parse(localStorage.getItem("ismToken"));
      if (!currentToken) {
        this.logout();
      } else {
        this.getAllDataParam();
      }
    },
  },
};
</script>

<style scoped>
th.smaller-label .v-label {
  font-size: smaller;
}
th.smaller-label .v-input__append-outer {
  margin-left: auto;
}

.hero {
  background: url("https://energyintel.brightspotcdn.com/dims4/default/8346e02/2147483647/strip/true/crop/5464x3070+0+0/resize/1920x1079!/quality/90/?url=http%3A%2F%2Fenergy-intelligence-brightspot.s3.us-east-2.amazonaws.com%2F09%2F7c%2F1c7b9168496681aea65fa2ad1d38%2Fss-1470572174-ship-exports-imports.jpg")
    center center no-repeat;
  background-size: cover;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(30, 91, 189, 0.656) 2%,
    rgba(9, 114, 235, 0) 100%
  );
  z-index: 1;
}

.titleBG {
  font-size: 40px !important;
  font-weight: bold;
  color: #fff;
}

.display-3 {
  margin: 0; /* Hapus margin bawaan untuk tata letak yang lebih baik */
}

.accent-color {
  color: #1d7fff; /* Ganti dengan warna aksen yang Anda inginkan */
}

footer {
  background-color: #00529b;
  color: #fff;
  padding-top: 15px;
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  margin-top: 100px;
}

/* Stil tambahan jika diperlukan */
footer a {
  color: #fff; /* Warna tautan pada footer */
  text-decoration: none; /* Menghilangkan dekorasi tautan (underline) */
}

/* Stil hover pada tautan jika diperlukan */
footer a:hover {
  text-decoration: underline; /* Menampilkan underline pada hover tautan */
}
</style>
