import * as types from "./type";

const defaultState = () => {
  return {
    snackbars: [],
    drawer: true,
  };
};

const state = defaultState();

const mutations = {
  [types.SET_SNACKBAR]: (state, snackbar) => {
    state.snackbars = state.snackbars.concat(snackbar);

    // hapus yang ga berguna
    for (var i = 0; i < state.snackbars; i++) {
      if (state.snackbars[i].showing == false) {
        state.snackbars.splice(i, 1);
      }
    }
  },
  [types.SET_DRAWER]: (state, value) => {
    state.drawer = value;
  },
};

const actions = {
  [types.ACTION_SET_SNACKBAR]: ({ commit }, snackbar) => {
    snackbar.showing = true;
    snackbar.color = snackbar.color || "success";
    commit(types.SET_SNACKBAR, snackbar);
  },
  [types.ACTION_SET_DRAWER]: ({ commit }, payload) => {
    commit(types.SET_DRAWER, payload);
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
