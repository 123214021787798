import Vue from 'vue';
const authHelper = Vue.mixin({
    methods: {
        checkAuthorities: function (name) {
            if (localStorage.getItem("skipLogin") == "true") {
                return true;
            } else {
                let checkToken = JSON.parse(localStorage.getItem("checkToken"));
                console.log(name, checkToken.authorities.indexOf(name) != -1);
                return checkToken.authorities.indexOf(name) != -1;
            }
        }
    }
})
export default authHelper;