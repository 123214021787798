<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list>
        <template v-for="item in items">
          <v-list-group
            v-if="item.children && item.show"
            :key="item.text"
            color="primary"
            v-model="item.model"
            link
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            style="cursor: pointer"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </template>
            <div v-for="(child, i) in item.children" :key="i">
              <v-list-item
                v-if="child.children == null && child.show"
                v-model="child.i"
                :to="{ name: child.routeName }"
                link
              >
                <v-list-item-action>
                  <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                </v-list-item-action>

                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item>

              <v-list-group
                v-else-if="child.children != null && child.show"
                sub-group
                no-action
                value="true"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ child.text }}</v-list-item-title>
                </template>
                <template v-for="(children, i) in child.children">
                  <v-list-item
                    v-if="children.show"
                    :key="i"
                    v-model="children.i"
                    link
                    :to="{ name: children.routeName }"
                  >
                    <v-list-item-title>{{ children.text }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list-group>
            </div>
          </v-list-group>

          <v-list-item
            v-else-if="item.show"
            :key="item.text"
            link
            :to="{ name: item.routeName }"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-btn icon>
        <v-img
          src="@/assets/images/logo-ism.png"
          @click="goToHome"
          aspect-ratio="1"
          contain
        ></v-img>
      </v-btn>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Indo Safety Marine</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            elevation="0"
            :ripple="false"
          >
            <v-icon class="pr-2">mdi-account-outline</v-icon>
            <span class="hidden-sm-and-down">Halo, Indo Safety Marine</span>
            <v-icon class="pl-2">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list nav>
          <v-list-item link color="primary" @click="logout">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <div v-show="this.$router.history.current.name === `home`">
    

          <v-container class="bg-surface-variant mb-6" fluid>
            <v-card class="mb-6 mt-4">
            <v-row>
              <v-col cols="1">
                <div class="pa-2">
                <v-img
                  src="@/assets/images/logo-ism.png"
                  @click="goToHome"
                  aspect-ratio="1"
                  contain
                ></v-img>
              </div>
              </v-col>
              <v-col style="height: 100%;">
                <div style="text-align: start; ">
                  <div class="text-h4 font-weight-bold">Indo Safety Marine</div>
                  <div class="text-h6">Indo Safety Marine</div>
                  <div class="text-h6">Phone : +62 99 999 9999</div>


                </div>
              </v-col>
            </v-row>
          </v-card >
          
                <v-card >
                  <div style="padding-left: 20px; padding-top: 20px;"> 
                    <div class="text-h5 font-weight-bold">
              Dashboard Information
                    </div>

                  </div>
                  <div style="padding:80px">
                    <bar-chart :data="statusFlow" :height="350" />
                  </div>
              </v-card>
            
          </v-container>
      </div>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { paramsCollection, firebaseAuth } from "../../firebase";
import BarChart from "@/components/charts/Bar";
import { barChartOptions } from "@/components/charts/config";

export default {
  components: {
    "bar-chart": BarChart,
  },
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    items: [
      {
        icon: "mdi-cog-outline",
        "icon-alt": "mdi-cog-outline",
        text: "CONFIGURATION",
        model: false,
        routeName: "configuration",
        show: true,
        children: [
          {
            text: "Parameter Configuration",
            routeName: "parameter-configuration-list",
            show: true,
          },
          {
            text: "User Management",
            routeName: "user-management-list",
            show: true,
          },
        ],
      },
      {
        icon: "mdi-contacts",
        "icon-alt": "mdi-contacts",
        text: "CUSTOMERS",
        model: false,
        routeName: "customers",
        show: true,
        children: [
          {
            text: "Orders",
            routeName: "orders-list",
            show: true,
          },
          {
            text: "Customers",
            routeName: "customers-list",
            show: true,
          },
          {
            text: "Vessels",
            routeName: "vessels-list",
            show: true,
          },
          {
            text: "Certificates",
            routeName: "certificate-list",
            show: true,
          },
        ],
      },
      {
        icon: "mdi-briefcase",
        "icon-alt": "mdi-briefcase",
        text: "FORM",
        model: false,
        routeName: "workform",
        show: true,
        children: [
          {
            text: "Work Form",
            routeName: "workform-list",
            show: true,
          },
          // {
          //   text: "Inspection Report",
          //   routeName: "inspection-list",
          //   show: true,
          // },
          // {
          //   text: "Certificate",
          //   routeName: "certificate-list",
          //   show: true,
          // },
          // {
          //   text: "Delivery Order",
          //   routeName: "delivery-order-list",
          //   show: true,
          // }
        ],
      },
      {
        icon: "mdi-note-multiple",
        "icon-alt": "mdi-note-multiple",
        text: "INSPECTION",
        model: false,
        routeName: "inspection",
        show: true,
        children: [
          {
            text: "Inspection Report",
            routeName: "inspection-list",
            show: true,
          },
        ],
      },
      {
        icon: "mdi-cog",
        "icon-alt": "mdi-cog",
        text: "SERVICES",
        model: false,
        routeName: "services",
        show: true,
        children: [
          {
            text: "Service Order",
            routeName: "service-list",
            show: true,
          },
        ],
      },
      {
        "icon-alt": "mdi-history",
        text: "HISTORY",
        model: false,
        routeName: "history",
        show: true,
        children: [
          {
            icon: "mdi-history",
            text: "Active Order History",
            routeName: "history",
            show: true,
          },
          {
            icon: "mdi-cube-send",
            text: "Delivery Order",
            routeName: "delivery-order-list",
            show: true,
          },
          {
            icon: "mdi-trash-can",
            text: "Trash",
            routeName: "trash",
            show: true,
          },
        ],
      },
    ],
    username: "",
    statusFlow: {
      labels: ["Entry Orders", "Waiting Approval", "Done", "Total"],
      datasets: [
        {
          label: "Gas Meter",
          borderColor: "#ffffff",
          backgroundColor: "#00529b",
          data: [12, 7, 3, 22],
          borderWidth: 1,
        },
        {
          label: "UTI Meter",
          borderColor: "#ffffff",
          backgroundColor: "#00cdff ",
          data: [5, 10, 6, 21],
          borderWidth: 1,
        },
      ],
    },
  }),
  created() {
    this.checkToken();
    barChartOptions.scales.yAxes[0].ticks.max = 50;
  },
  computed: {},
  watch: {},
  methods: {
    goToHome() {
      const isSuper = JSON.parse(localStorage.getItem("ismUser"));
      if (isSuper.role == "Admin") {
        this.$router.push("/home");
      } else {
        this.$router.push("/customers");
      }
    },
    async logout() {
      firebaseAuth
        .signOut()
        .then(() => {
          this.$router.replace({ name: "login" });
          localStorage.clear();
        })
        .catch(() => {});
    },
    getAllDataParam() {
      paramsCollection
        .orderBy("createdAt", "desc")
        .get()
        .then((data) => {
          let params = [];
          data.forEach((doc) => {
            params.push({
              paramsId: doc.id,
              paramsGroup: doc.data().paramsGroup,
              params: doc.data().params,
              values: doc.data().values,
              status: doc.data().status,
              createdAt: doc.data().createdAt,
              createdBy: doc.data().createdBy,
              updatedAt: doc.data().updatedAt,
              updatedBy: doc.data().updatedBy,
            });
          });
          localStorage.setItem("masterParams", JSON.stringify(params));
        })
        .catch(() => {});
    },
    checkToken() {
      let currentToken = JSON.parse(localStorage.getItem("ismToken"));
      if (!currentToken) {
        this.logout();
      } else {
        this.getAllDataParam();
      }
    },
  },
};
</script>

<style>
th.smaller-label .v-label {
  font-size: smaller;
}
th.smaller-label .v-input__append-outer {
  margin-left: auto;
}
</style>
