<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <img
            src="@/assets/images/vector-login.png"
            style="right: 0; bottom: 0; position: absolute"
          />
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-0">
              <v-img
                src="@/assets/images/logo-ism.png"
                aspect-ratio="3"
                contain
              ></v-img>
              <v-spacer></v-spacer>
              <v-toolbar color="white" dark flat>
                <v-toolbar-title style="width: 100%" class="text-center">
                  <span class="primary--text text-xl-h4"
                    >Please enter your Email and Password</span
                  >
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-0">
                <v-form v-on:submit.prevent="login()">
                  <v-text-field
                    label="Login"
                    placeholder="Input your Email"
                    name="login"
                    type="text"
                    ref="email"
                    v-model="email"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    ref="password"
                    autocomplete
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    placeholder="Input your Password"
                    hint="At least 8 characters"
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions
                style="justify-content: center; display: flex;"
              >
                <v-btn
                  color="primary"
                  style=" width: 15vw;"
                  :rounded="true"
                  @click="login"
                  :loading="isLoading"
                >
                  LOGIN as ADMIN
                </v-btn>

                <!-- <v-btn
                  style=" width: 15vw;"
                  color="#4285F4"
                  :dark="true"
                  :rounded="true"
                  class="google-login-btn"
                  @click="loginWithGoogle"
                  :loading="isGoogleLoading"
                >
                  <v-icon left>
                    mdi-google
                  </v-icon>
                  LOGIN as CUSTOMER
                </v-btn> -->
              </v-card-actions>
              <br />
              <v-alert v-show="snackbar.show" type="error" outlined dense>
                {{ snackbar.errorText }}
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { firebaseAuth, usersCollection } from "../../firebase";
// import firebase from "firebase";
export default {
  props: {
    source: String,
  },
  data() {
    return {
      show1: false,
      show2: true,
      show3: false,
      show4: false,
      password: "",
      email: "",
      formHasErrors: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      isLoading: false,
      isGoogleLoading: false,
      snackbar: {
        show: false,
        errorText: "",
      },
    };
  },
  computed: {
    form() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  watch: {
    form: function() {
      this.snackbar.show = false;
      this.snackbar.errorText = "";
    },
  },
  created() {},
  methods: {
    login() {
      let vm = this;
      vm.snackbar.show = false;
      vm.snackbar.errorText = "";
      vm.formHasErrors = false;
      Object.keys(vm.form).forEach((f) => {
        if (!vm.form[f]) vm.formHasErrors = true;
        vm.$refs[f].validate(true);
      });
      if (!vm.formHasErrors) vm.doLogin();
    },

    async doLogin() {
      let vm = this;
      vm.isLoading = true;
      let newData = {
        email: vm.email,
        password: vm.password,
      };
      let idToken, userId;
      firebaseAuth
        .signInWithEmailAndPassword(newData.email, newData.password)
        .then((data) => {
          userId = data.user.uid;
          return data.user.getIdToken();
        })
        .then((token) => {
          idToken = token;
          localStorage.setItem("ismToken", JSON.stringify(idToken));
          return usersCollection
            .where("userId", "==", userId)
            .limit(1)
            .get();
        })
        .then((data) => {
          const userData = data.docs[0].data();
          if (userData.role == "customer") {
            throw new Error("Login failed. Admin are not allowed to access.");
          }
          let user = {
            token: idToken,
            userId: userId,
            username: data.docs[0].data().username,
            name: data.docs[0].data().name,
            email: data.docs[0].data().email,
            role: data.docs[0].data().role,
          };
          localStorage.setItem("ismUser", JSON.stringify(user));
            vm.$router.replace({ name: "home" });
            vm.isLoading = false;
          // if (user.role == "Admin") {
            // vm.$router.replace({ name: "home" });
            // vm.isLoading = false;
          // } else {
          //   vm.$router.replace({ name: "customers" });
          //   vm.isLoading = false;
          // }
        })
        .catch((error) => {
          vm.isLoading = false;
          vm.snackbar.show = true;
          vm.snackbar.errorText = error.message;
        });
    },
    // async loginWithGoogle() {
    //   let vm = this;
    //   vm.isGoogleLoading = true;

    //   try {
    //     const provider = new firebase.auth.GoogleAuthProvider();
    //     const result = await firebase.auth().signInWithPopup(provider);

    //     const userLogin = result.additionalUserInfo;
    //     const token = result.credential.idToken;
    //     const userId = result.additionalUserInfo.profile.id;

    //     const userSnapshot = await usersCollection
    //       .where("userId", "==", userId)
    //       .limit(1)
    //       .get();
    //     let userData = {
    //       userId: userId,
    //       name: userLogin.profile.name,
    //       username: userLogin.profile.given_name,
    //       email: userLogin.profile.email,
    //       role: "Customer",
    //       isActive: true,
    //     };
    //     if (userSnapshot.empty) {
    //       localStorage.setItem("ismUser", JSON.stringify(userData));
    //       localStorage.setItem("ismToken", JSON.stringify(token));
    //       await usersCollection.add(userData);
    //       vm.$router.replace({ name: "customers" });
    //     } else {
    //       localStorage.setItem("ismUser", JSON.stringify(userData));
    //       localStorage.setItem("ismToken", JSON.stringify(token));
    //       vm.$router.replace({ name: "customers" });
    //     }
    //   } catch (error) {
    //     vm.isGoogleLoading = false;
    //     vm.snackbar.show = true;
    //     vm.snackbar.errorText = error.message;
    //   }
    // },
  },
};
</script>

<style scoped>
.google-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.google-login-btn::before {
  content: "";
  width: 2em;
  height: 2em;
  margin-right: 1em;
  background: url("/path/to/google-icon.png") center/contain no-repeat;
}

.google-login-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 15px;
}

.google-login-btn:hover::after {
  opacity: 1;
}
</style>
