<template>
  <v-card flat>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="param.ordersNo"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="1"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      ref="pdfGasMeter"
    >
      <section slot="pdf-content">
        <ContentToPrintGasMeter
          :data="updateDataforPDF"
          v-if="updateDataforPDF"
        />
      </section>
    </vue-html2pdf>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="param.ordersNo"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="1"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      ref="pdfUtiMeter"
    >
      <section slot="pdf-content">
        <ContentToPrintUtiMeter
          :data="updateDataforPDF"
          v-if="updateDataforPDF"
        />
      </section>
    </vue-html2pdf>
    <isttoolbar
      title="Certificates"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer></v-spacer>
      <!-- <v-btn outlined color="white" @click="goToAdd">
              <v-icon left>mdi-plus</v-icon>Add Certificate
            </v-btn> -->
    </isttoolbar>
    <v-card>
      <v-card-title>
        Certificate List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1 pointer-table"
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
      >
        <template v-slot:[`item.statusCustomer`]="{ item }">
          {{ item.statusCustomer ? "B2B" : "End User" }}
        </template>
        <template v-slot:[`item.statusOrder`]="{ item }">
          {{
            item.statusOrder === 1
              ? "In Progress"
              : item.statusOrder === 2
              ? "Approve"
              : item.statusOrder === 3
              ? "Reject"
              : item.statusOrder === 4
              ? "Final"
              : "Entry"
          }}
        </template>
        <template v-slot:[`item.poNumber`]="{ item }">
          <v-btn
            v-show="!item.poNumber"
            icon
            color="primary"
            v-on="on"
            @click="openDialog(item)"
            width="17"
            height="17"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- <v-text-field
            v-show="!item.poNumber"
            outlined
            dense
            v-model="item.poNumber"
            @click="openDialog(item)"
          ></v-text-field> -->
          <p v-show="item.poNumber">{{ item.poNumber }}</p>
        </template>
        <template v-slot:[`item.actions`]="{ item, index }">
          <v-menu bottom left :offset-x="true">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-show="
                  item.statusDesc === 'Final Order'
                "
                class="pointer"
                ref="approval"
                @click="generatePDF(item, index)"
              >
                <v-list-item-title>Create Certificate</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-show="item.statusDesc === 'PO not finished yet'"
                class="pointer"
                ref="approval"
                @click="clickApproveorReject($event, item)"
              >
                <v-list-item-title>Approve PO</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data></template>
      </v-data-table>
    </v-card>

    <!-- Your existing dialog code -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogVisible"
        persistent
        width="unset"
        min-width="290px"
      >
        <!-- Your dialog content -->
        <v-card min-width="290px">
          <v-card-title class="justify-center" style="word-break: inherit;"
            >Input PO Number</v-card-title
          >
          <div class="pa-5">
            <v-text-field v-model="valuePO"> </v-text-field>
          </div>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              @click="cancelInputPO()"
              outlined
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              min-width="100px"
              @click="confirmInputPO(valuePO)"
              outlined
              >Confirm PO Number</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- DIALOG -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogDelete"
        persistent
        width="unset"
        min-width="290px"
      >
        <v-card min-width="290px">
          <v-card-title class="justify-center" style="word-break: inherit;"
            >Are you sure want to Delete ?</v-card-title
          >
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              @click="
                deleteData();
                dialogDelete = false;
              "
              outlined
              >Yes</v-btn
            >
            <v-btn
              color="red"
              min-width="100px"
              @click="dialogDelete = false"
              outlined
              >No</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Deleted</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Deleted</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="resetDialog"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import ContentToPrintGasMeter from "../../../components/pdfPrint/certificate_gas_pdf";
import ContentToPrintUtiMeter from "../../../components/pdfPrint/certificate_uti_pdf";
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { firebaseDB, ordersCollection } from "@/firebase";
export default {
  components: {
    isttoolbar,
    VueHtml2pdf,
    ContentToPrintGasMeter,
    ContentToPrintUtiMeter,
  },
  data: () => ({
    valuePO: "",
    dialogVisible: false,
    updateDataforPDF: {},
    search: "",
    loading: false,
    headers: [
      { text: "Order No", align: "start", value: "ordersNo" },
      { text: "Customer", value: "customerName" },
      { text: "Product", value: "products" },
      { text: "Status", value: "statusDesc" },
      { text: "No PO", value: "poNumber" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    items: [],
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    dialogDelete: false,
    item: {},
    param: {
      customerId: "",
      customerName: "",
      vesselId: "",
      vesselName: "",
      statusCustomer: true,
      description: "",
      products: "",
      productsList: [],
    },
    itemInDialog: null,
  }),
  watch: {},
  created() {
    this.getListData();
  },
  methods: {
    openDialog(item) {
      this.dialogVisible = true;
      this.itemInDialog = item;
    },
    cancelInputPO() {
      this.dialogVisible = false;
      this.itemInDialog = null;
      this.valuePO = "";
    },
    confirmInputPO(payload) {
      if (!payload) {
        return alert("isi dahulu PO nya");
      }

      this.itemInDialog.poNumber = payload;
      let newData = this.itemInDialog;
      firebaseDB
        .doc(`/orders/${this.itemInDialog.orderId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Order not found";
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
          this.dialogVisible = false;
          this.itemInDialog = null;
          this.valuePO = "";
          this.getListData();
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Something Went Wrong";
        });
    },
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    async generatePDF(val, index) {
      if (!val.poNumber) {
        // alert("po harus diisi");
        this.dialog.show = true;
        this.dialog.error = true;
        this.dialog.errorMessage =
          "There is no PO number yet, please fill it in first";
        return;
      }
      // sebelum PRINT Filter data dari param hanya inspection report yang valuenya True yang dicetak

      const updatedData = {
        ...this.param,
        productsList: val.productsList.map((product) => {
          const filteredInspectionReport = product.inspectionReport.filter(
            (report) => report.checked === true
          );
          return {
            ...product,
            inspectionReport: filteredInspectionReport,
          };
        }),
      };
      
      this.updateDataforPDF = updatedData[index];

      if (val.products == "Uti Meter") {
        this.$refs.pdfUtiMeter.generatePdf();
      } else if (val.products == "Gas Meter") {
        this.$refs.pdfGasMeter.generatePdf();
      }
    },
    async doAdd() {
      let gasMeter = this.items.filter((x) => x.orderProduct === "Gas Meter");
      let utiMeter = this.items.filter((x) => x.orderProduct === "UTI Meter");
      let others = this.items.filter((x) => x.orderProduct === "Others");
      let productDesc = [];

      if (gasMeter.length > 0) {
        productDesc.push("Gas Meter");
      }
      if (utiMeter.length > 0) {
        productDesc.push("Uti Meter");
      }
      if (others.length > 0) {
        productDesc.push("Others");
      }

      const newData = {
        customerId: this.param.customerId,
        customerName: this.param.customerName,
        vesselId: this.param.vesselId,
        vesselName: this.param.vesselName,
        statusCustomer: this.param.statusCustomer,
        description: this.param.description,
        products: productDesc.toString(),
        productsList: this.items,
        updatedAt: new Date().toISOString(),
        updatedBy: "Admin",
      };

      firebaseDB
        .doc(`/orders/${this.$route.query.orderId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Workform not found";
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Something Went Wrong";
        });
    },
    async clickApproveorReject(event, value) {
      if (!value.poNumber) {
        this.dialog.show = true;
        this.dialog.error = true;
        this.dialog.errorMessage =
          "There is no PO number yet, please fill it in first";
        return;
      }

      if (event.target.textContent == "Approve") {
        value.statusOrder = "2";
        value.statusDesc = "Progress for Quotation";
      }

      if (event.target.textContent == "Reject") {
        console.log(value, "<< here value button rejected eject");
      }

      Object.keys(this.form).forEach((f) => {
        if (f === "customerName" || f === "vesselName" || f === "description") {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
        }
      });
      if (!this.formHasErrors) {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        if (this.items.length === 0) {
          this.dialog.error = true;
          this.dialog.errorMessage = "Field list product must be filled";
        } else {
          await this.doAdd();
        }
      } else {
        this.formHasErrors = false;
      }
    },
    resetDialog() {
      this.dialog = {
        show: false,
        success: false,
        error: false,
        errorMessage: "",
      };
      this.item = {};
    },
    goToAdd() {
      this.$router.replace({ name: "certificate-add" });
    },
    clickDelete(item) {
      this.dialogDelete = true;
      this.item = item;
    },
    deleteData() {
      this.dialog.success = false;
      this.dialog.error = false;
      this.dialog.show = true;
      const newData = {
        isDeleted: true,
        deletedAt: new Date().toISOString(),
        deletedBy: "Admin",
      };
      firebaseDB
        .doc(`/orders/${this.item.orderId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Order not found";
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
          this.getListData();
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Something Went Wrong";
        });
    },

    getListData() {
      this.loading = true;
      ordersCollection
        .where("statusDesc", "in", [
          "Final Order",
          "PO not finished yet",
        ])
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          console.log(data, "<<");
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              orderId: doc.id,
              ordersNo: doc.data().ordersNo,
              customerId: doc.data().customerId,
              customerName: doc.data().customerName,
              vesselId: doc.data().vesselId,
              vesselName: doc.data().vesselName,
              statusCustomer: doc.data().statusCustomer,
              description: doc.data().description,
              products: doc.data().products,
              productsList: doc.data().productsList
                ? doc.data().productsList
                : [],
              poNumber: doc.data().poNumber,
              statusOrder: doc.data().statusOrder,
              statusDesc: doc.data().statusDesc,
              createdAt: doc.data().createdAt,
            });
          });
          this.loading = false;

          this.items = newData.sort(function(a, b) {
            return a.createdAt > b.createdAt
              ? -1
              : a.createdAt < b.createdAt
              ? 1
              : 0;
          });

          this.param = this.items;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.pointer-table {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.pointer:hover {
  background: #eeeeee;
}
</style>
