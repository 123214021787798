<template>
    <div class="surat-resmi">
        <v-container fluid style="border-bottom: 1px solid #000">
            <v-row align="center" justify="space-between">
                <v-col cols="3">
                    <img src="@/assets/images/logo-ism.png" contain alt="Indo Safety Marine Logo" width="100%"
                        style="padding-left: 40px; margin-top: 20px" />
                </v-col>
                <v-col cols="9">
                    <div class="header">
                        <h1>PT. Indo Safety Marine</h1>
                        <br />
                        <p>Ruko Sentra Menteng</p>
                        <p>
                            Jl. MH Thamrin Blok B7 MN32 3rd Floor, Bintaro Jaya Sektor 7
                            Sentra Menteng
                        </p>
                        <p>Tangerang Selatan 15224, Indonesia</p>
                        <p>Hotline : +62817-750838, Email : info@indosafetymarine.com</p>
                        <p>www.indosafetymarine.com</p>
                        <p>www.utimeterjakarta.com</p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="content">
            <h3 style="text-align: center"><strong>INSPECTION REPORT</strong></h3>
            <div class="surat-resmi">
                <div class="content">
                    <p>Checked On : {{ data.updatedAt }}</p>
                    <p>Vessel Name : {{ data.vesselName }}</p>
                    <p><br /></p>
                    <p><strong>Test Result</strong></p>

                    <v-data-table 
                    :headers="headers" 
                    :items="data.productsList" 
                    item-key="index"
                    >
                        <template #item="{ item }">
                            <tr>
                                <td>
                                    {{ item.calibrationName }}
                                </td>
                                <td>
                                    <span v-for="(data, index) in item.inspectionReport" :key="index">
                                        - {{ data.checked == true ? data.reportType : data.shift }}
                                        <span v-if="index !== item.inspectionReport.length - 1"><br /></span>
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </div>
            <p><br /></p>
            <div style="padding-left: 30px; padding-right: 30px; text-align: justify;">
                <v-row>
                    <v-col>
                        <p style="font-size: 12px;">
                            {{ data.description }} - Indonesia Version
                        </p>
                    </v-col>
                    <v-col>
                        <p style="font-style: italic; font-size: 12px;">
                            {{ data.description }} - English Version
                        </p>
                    </v-col>
                </v-row>
            </div>
        </div>

        <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="3"></v-col>
            <v-col cols="6">
                <div class="signature">
                    <p style="margin-bottom: 5vh;">Tested By :</p>
                    <p><strong>Odilo Spot</strong></p>
                    <p>Calibration Engineer</p>
                </div>
            </v-col>
        </v-row>

        <v-footer class="footer">
            <v-row>
                <v-col class="text-left" cols="9">
                    <p>IndoSafetyMarine</p>
                </v-col>
                <v-col class="text-right" cols="3">
                    <img src="@/assets/images/logo_letter2.png" contain alt="Calgaz Logo" width="100%" />
                </v-col>
            </v-row>
        </v-footer>
    </div>
</template>

  
<script>
export default {
    name: "workForm_vue",
    props: {
        data: Object,
    },
    data: () => ({
        headers: [
            { 
                text: "Device",
                key: 'device',
            },
            { 
                text: "Result",
                key: "result"
            },
        ],
    }),
    mounted() { },
};
</script>

<style>
.surat-resmi {
    padding-left: 30px;
    padding-right: 30px;
}

.header {
    /* border-bottom: 1px solid #000; */
    padding-bottom: 20px;
    margin-top: 30px;
    margin-bottom: 5px;
    text-align: center;
}

.header h1 {
    font-size: 22px;
    margin: 0;
    line-height: 0.5;
}

.header p {
    margin: 0;
    font-size: 12px;
    line-height: 1.2;
}

/* Style untuk content surat */
.content {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.5;
}

/* Style untuk tanda tangan */
.signature {
    margin-top: 50px;
    margin-bottom: 30%;
}

.signature p {
    text-align: center;
    font-size: 14px;
    line-height: 1.5;
    padding-left: 0;
}

.footer {
    position: absolute;
    background-color: none;
    bottom: 0;
    width: 93%;
}

</style>