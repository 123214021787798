<template>
  <div class="surat-resmi">
    <v-container fluid>
      <v-row align="center" justify="space-between">
        <v-col cols="2">
          <img
            src="@/assets/images/logo-ism.png"
            contain
            alt="Indo Safety Marine Logo"
            width="100%"
            style="padding-left: 40px; margin-top: 20px"
          />
        </v-col>
        <v-col cols="6">
          <div class="header">
            <h1>PT. Indo Safety Marine</h1>
            <p>{{ data.customerName }}</p>
            <br />
            <p>
              Ruko Sentra Menteng<span
                >- Jl. MH Thamrin Blok B7 MN32 3rd Floor, Bintaro Jaya Sektor 7
                Tangerang Selatan 15224, Indonesia</span
              >
            </p>
            <p>Hotline : +62817-750838, Email : info@indosafetymarine.com</p>
            <p>www.indosafetymarine.com</p>
            <p>www.utimeterjakarta.com</p>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="header-right">
            <h1>CALIBRATION DATA</h1>
          </div>
          <div class="subheader-right">
            <p>ISM.15-02.122022</p>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="space-between" style="margin-top: -15px">
        <v-col cols="8">
          <table id="customers">
            <tr>
              <th>Calibration Date</th>
              <th>Due Date</th>
            </tr>
            <tr>
              <td>15 Desember 2022</td>
              <td>15 Desember 2023</td>
            </tr>
          </table>
        </v-col>
        <v-col cols="4">
          <table id="customers">
            <tr>
              <th>Vessel Name</th>
            </tr>
            <tr>
              <td>{{ data.vesselName }}</td>
            </tr>
            <tr>
              <th>P.O. NUMBER</th>
            </tr>
            <tr>
              <td>{{ data.poNumber }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-container>
    <div class="content">
      <v-row>
        <v-col>
          <h4>Instrument Details</h4>
          <table id="customersData">
            <tr>
              <th colspan="2">General Information</th>
            </tr>
            <tr>
              <td colspan="2">Device Type : {{ data.products }}</td>
            </tr>
            <tr>
              <td>
                Manufacturer : {{ data.productsList[0].orderProductType }}
              </td>
              <td>
                Serial Number : {{ data.productsList[0].orderProductSN }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                Model : {{ data.productsList[0].orderProductSeries ? data.productsList[0].orderProductSeries : '-' }}
              </td>
            </tr>
            <tr>
              <th colspan="2">Additional Information</th>
            </tr>
            <tr>
              <td colspan="2">-</td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Calibration Standarts</h4>
          <table id="customersData">
            <tr>
              <th>Apparatus</th>
              <th>Manufacturer / Model</th>
              <th>Manuf No. / Serial</th>
            </tr>
            <tr>
              <td>Water Bath</td>
              <td>MEMMERT / WNB 7</td>
              <td>60529</td>
            </tr>
            <tr>
              <td>Thermometer</td>
              <td>Thermo Scientific / TEMP-360</td>
              <td>4009255</td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Test Results</h4>
          <table id="customersData">
            <tr>
              <th colspan="5">Measurement readings</th>
            </tr>
            <tr>
              <th
                rowspan="2"
                style="
                  background-color: white;
                  text-align: center;
                  color: black;
                  font-weight: bold;
                "
              >
                Gas Type
              </th>
              <th
                rowspan="2"
                style="
                  background-color: white;
                  text-align: center;
                  color: black;
                  font-weight: bold;
                "
              >
                Reference Gas <br />Concentration
              </th>
              <th
                colspan="2"
                style="
                  background-color: white;
                  text-align: center;
                  color: black;
                  font-weight: bold;
                "
              >
                Device Reading
              </th>
              <th
                rowspan="2"
                style="
                  background-color: white;
                  text-align: center;
                  color: black;
                  font-weight: bold;
                "
              >
                Unit
              </th>
            </tr>
            <tr>
              <th
                style="
                  background-color: white;
                  text-align: center;
                  color: black;
                  font-weight: bold;
                "
              >
                Pre-Calibration
              </th>
              <th
                style="
                  background-color: white;
                  text-align: center;
                  color: black;
                  font-weight: bold;
                "
              >
                Post-Calibration
              </th>
            </tr>
            <tr v-for="(gas, index) in data.productsList[0].listGas" :key="index">
              <td>{{ gas.gasType }}</td>
              <td style="text-align: center">50</td>
              <td style="text-align: center">23</td>
              <td style="text-align: center">12,6</td>
              <td style="text-align: center">% LEL</td>
            </tr>
            <!-- <tr>
              <td>O2 / Oxygen</td>
              <td style="text-align: center">12</td>
              <td style="text-align: center">50</td>
              <td style="text-align: center">11,9</td>
              <td style="text-align: center">% Vol</td>
            </tr> -->
          </table>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <table id="customersData">
            <tr>
              <th colspan="4">Function Test</th>
            </tr>
            <tr>
              <td>Display / Lights</td>
              <td style="text-align: center; font-weight: bold">OK</td>
              <td>Buttons / Knobs / Switches</td>
              <td style="text-align: center; font-weight: bold">OK</td>
            </tr>
            <tr>
              <td>Sensor(s)</td>
              <td style="text-align: center; font-weight: bold">OK</td>
              <td>Alarm / Buzzer / Alert System</td>
              <td style="text-align: center; font-weight: bold">OK</td>
            </tr>
            <tr>
              <td>Sample Drawing system / Pump / Sensor Filters</td>
              <td style="text-align: center; font-weight: bold">OK</td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <table id="customersData">
            <tr>
              <th>Additional Service / Repair / Maintenance Details</th>
            </tr>
            <tr>
              <td>Repaired tape spliced at 34M, 70cm position</td>
            </tr>
          </table>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h4>Notes</h4>
          <table id="customersData">
            <tr>
              <th>Remarks</th>
            </tr>
            <tr>
              <td>
                All calibration and test results are applicable at the time of
                calibration. After device has been delivered, it is
                customer/user's responsibility to verify that the devices is
                functioning properly (bump check) before each use.
              </td>
            </tr>
            <tr>
              <td>
                Test and calibration are performed in environtmentally
                controlled area with temperature set at 23 ± 2° C and 40-60%
                relative humidhty
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3"></v-col>
        <v-col cols="3"></v-col>
        <v-col cols="6">
          <div class="signature">
            <p style="margin-bottom: 5vh">Calibrated by :</p>
            <p><strong>Odilo Spot</strong></p>
          </div>
        </v-col>
      </v-row>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "certificate_vue",
  props: {
    data: Object,
  },
  data: () => ({
    headers: [
      {
        text: "Device",
        key: "device",
      },
      {
        text: "Result",
        key: "result",
      },
    ],
  }),
  mounted() {},
};
</script>

<style>
.surat-resmi {
  padding-left: 30px;
  padding-right: 30px;
}

.header {
  /* border-bottom: 1px solid #000; */
  padding-bottom: 20px;
  margin-top: 30px;
  margin-bottom: 5px;
  text-align: left;
}

.header-right {
  /* border-bottom: 1px solid #000; */
  padding-bottom: 20px;
  margin-top: 30px;
  text-align: center;
  background-color: black;
  color: white;
  padding: 6%;
}

.subheader-right {
  /* border-bottom: 1px solid #000; */
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  padding: 3%;
  border: 1px solid rgb(0, 0, 0);
}

.header h1 {
  font-size: 16px;
  margin: 0;
  line-height: 0.5;
}

.header p {
  margin: 0;
  font-size: 10px;
  line-height: 1.2;
}

/* Style untuk content surat */
.content {
  margin-top: 2px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 1.5;
}

/* Style untuk tanda tangan */
.signature {
  margin-top: -15px;
  margin-bottom: 30%;
  font-size: 9px;
}

.signature p {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  padding-left: 0;
}

.footer {
  position: absolute;
  background-color: none;
  bottom: 0;
  width: 93%;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 4px;
}

#customers th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: #000000;
  color: white;
}

#customersData {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 10px;
}

#customersData td,
#customersData th {
  border: 1px solid #ddd;
  padding: 4px;
}

#customersData th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: #000000;
  color: white;
}
</style>
