<template>
  <v-card flat>
    <isttoolbar
      title="Configuration"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer />
      <v-btn @click="save" outlined class="mx-1" min-width="100px">Save</v-btn>
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>
    <isttoolbar
      title="Add Option Product"
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>
    <v-form>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Type Product :
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="4" align-self="center" class="pa-1">
          <v-menu v-model="menuOpen" :close-on-content-click="false">
            <template v-slot:activator="{ on, props }">
              <v-btn v-on="on" v-bind="props">
                {{ selectedValue ? selectedValue : "Select Type Product" }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="selectItem(item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="2" class="pa-1">
          <span class="title">
            Product Name :
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="4" align-self="center" class="pr-8">
          <v-text-field
            hide-details="auto"
            label="Pilih produk di tabel List Product"
            ref="productName"
            item-value="productName"
            item-text="productName"
            :value="param.productName.paramsGroup"
            solo
            flat
            outlined
            dense
            class="ma-0"
            readonly
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" class="ml-2"> </v-row>
      <br />
      <v-card-title>
        List Product
        <v-spacer> </v-spacer>
        <small style="color: gray;"
          >*Pilih Type Product untuk menampilkan List Product</small
        >
      </v-card-title>
      <v-data-table
        class="elevation-1 pa-8"
        :headers="headers"
        :items="itemsOptionProduct"
        :loading="loading"
        :items-per-page="5"
        @click:row="handleClick"
        style="cursor: pointer;"
      >
      </v-data-table>
    </v-form>
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Saved</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Saved</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
// import { paramsCollection } from "../../../firebase";
import { paramsCollection } from "../../../firebase";
// import { ordersCollection } from "@/firebase";

export default {
  name: "AdminIndosafetymarineOptionProductAdd",
  components: {
    isttoolbar,
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: "Param Group",
        align: "start",
        value: "paramsGroup",
        width: "130px",
      },
      { text: "Params", value: "params", width: "130px" },
    ],
    itemsOptionProduct: [],
    items: ["Gas Meter", "UTI Meter"],
    selectedValue: null,
    selectedValueProduct: null,
    menuOpen: false,
    menuOpenListProduct: false,
    param: {
      productName: "",
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: { show: false, success: false, error: false, errorMessage: "" },
  }),
  computed: {
    form() {
      return {
        productName: this.param.productName,
      };
    },
  },
  created() {
    this.dialog.success = false;
    this.dialog.error = false;
  },
  methods: {
    getListData() {
      this.loading = true;
      paramsCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              paramsGroup: doc.data().paramsGroup,
              params: doc.data().params,
            });
          });
          this.loading = false;
          this.itemsOptionProduct = newData.sort(function(a, b) {
            return a.createdAt > b.createdAt
              ? -1
              : a.createdAt < b.createdAt
              ? 1
              : 0;
          });
        })
        .catch((err) => {
          this.loading = false;
          console.debug(err, "params list");
        });
    },
    handleClick(value) {
      this.param.productName = value;
    },
    selectItem(item) {
      this.selectedValue = item;
      this.menuOpen = false;
      this.getListData();
    },
    back() {
      this.$router.replace({
        name: "parameter-configuration-list",
      });
    },
    async save() {
      Object.keys(this.form).forEach((f) => {
        if (f === "productName") {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
        }
      });
      if (this.selectedValue == null) {
        this.dialog.show = true;
        this.dialog.error = true;
        this.dialog.errorMessage = "Pilih Type Product Anda !";
      }

      // if (this.selectedValueProduct == null) {
      //   this.dialog.show = true;
      //   this.dialog.error = true;
      //   this.dialog.errorMessage = "Pilih Type Product Anda !";
      // }

      if (!this.formHasErrors && this.selectedValue != null) {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        await this.doAdd();
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      paramsCollection
        .where("paramsGroup", "==", "Orders Product Type")
        .get()
        .then((data) => {
          data.forEach((doc) => {
            if (doc.data().params == `${this.selectedValue}`) {
              const newData = JSON.parse(doc.data().values);

              // cek duplikat data
              let result = newData.filter(
                (item) =>
                  item.orderProductType === this.param.productName.paramsGroup
              );
              if (result.length > 0) {
                this.dialog.error = true;
                this.dialog.errorMessage =
                  "Maaf duplikat data, product ini sudah ada";
                return;
              } else {
                newData.push({
                  orderProductType: `${this.param.productName.paramsGroup}`,
                });
                const newDataString = JSON.stringify(newData);
                return doc.ref.update({ values: newDataString });
              }
            } else {
              console.log("Dokumen tidak ditemukan");
            }
          });
        })
        .then(async () => {
          this.dialog.success = true;
        })
        .catch((error) => {
          console.error("Error updating customer:", error.message);
          this.dialog.error = true;
          this.dialog.errorMessage = "Something went wrong";
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
