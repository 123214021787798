<template>
  <v-card flat>
    <isttoolbar
      title="Configuration"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer />
      <v-btn @click="save" outlined class="mx-1" min-width="100px">Save</v-btn>
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>
    <isttoolbar
      title="Add Option Type Product"
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>
    <v-form>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Params Group
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Params Group"
            ref="paramsGroup"
            v-model="param.paramsGroup"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Params
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Params"
            ref="params"
            v-model="param.params"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Values
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="9" align-self="start" class="pa-1">
          <v-textarea
            hide-details="auto"
            v-model="param.values"
            solo
            flat
            outlined
            dense
            ref="values"
            name="input-7-4"
            label="Values"
            :rows="20"
            :rules="[rules.required]"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Saved</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Saved</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { paramsCollection } from "../../../firebase";
export default {
  name: "AdminIndosafetymarineOptionProductTypeAdd",
  components: {
    isttoolbar,
  },
  data: () => ({
    param: {
      paramsGroup: "",
      params: "",
      values: "",
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: { show: false, success: false, error: false, errorMessage: "" },
  }),
  computed: {
    form() {
      return {
        paramsGroup: this.param.paramsGroup,
        params: this.param.params,
        values: this.param.values,
      };
    },
  },
  created() {},
  methods: {
    back() {
      this.$router.replace({
        name: "parameter-configuration-list",
      });
    },
    async save() {
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });
      if (!this.formHasErrors) {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        await this.doAdd();
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      let original = this.param.values;
      let stringify = JSON.stringify(original);
      let remove = stringify.replace(/\\n/g, "");
      const newData = {
        paramsGroup: this.param.paramsGroup,
        params: this.param.params,
        values: remove,
        status: true,
        isDeleted: false,
        createdAt: new Date().toISOString(),
        createdBy: "Admin",
        updatedBy: "",
        updatedAt: "",
        deletedAt: "",
        deletedBy: "",
      };
      paramsCollection
        .add(newData)
        .then(() => {
          this.dialog.success = true;
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Somthing Went Wrong";
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
