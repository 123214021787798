<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" :readonly="this.$route.query.statusPage === 'view'">
      <v-card>
        <isttoolbar
          title="Edit Inspection"
          dark
          color="secondary"
          icon="mdi-format-list-checkbox"
        />
        <v-container>
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">
                Inspection Name
              </span>
            </v-col>
            <v-col cols="8" align-self="start" class="pa-1">
              <v-text-field
                v-model="reportType"
                hide-details="auto"
                ref="orderProduct"
                solo
                flat
                outlined
                dense
                class="ma-0"
                @keydown.enter="saveEditInspection()"
              ></v-text-field>
              <span class="error--text" v-if="noInput"
                >Please insert to the field!</span
              >
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="saveEditInspection()">
            Save
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="$emit('cancel')">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";

export default {
  components: {
    isttoolbar,
  },
  props: ["dialog", "data"],
  data: () => ({
    reportType: "",
    noInput: false,
  }),
  watch: {
    dialog: {
      handler: function(after) {
        if (after) {
          this.reportType = this.data.reportType;
        }
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    saveEditInspection() {
      event.preventDefault();
      if (!this.reportType.trim()) {
        this.noInput = true;
        return;
      } else {
        this.noInput = false;
      }
      this.data.reportType = this.reportType;
      this.reportType = "";
      this.noInput = false;
      this.$emit("save");
    },
  },
};
</script>
