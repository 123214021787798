import VueRouter from "vue-router";
import LoginView from "@/features/login/Login_view";
import LoginCustomersView from "@/features/login/Login_Customers_view";
import RegisterCustomersView from "@/features/login/Register_Customers_view";
import LayoutView from "@/components/layout/layout";
import ParamaterConfigurationView from "@/features/configuration/parameterConfiguration/paramConfiguration_list";
import ParamaterConfigurationAddView from "@/features/configuration/parameterConfiguration/paramConfiguration_add";
import ParamaterConfigurationEditView from "@/features/configuration/parameterConfiguration/paramConfiguration_edit";
import OptionProductAddView from "@/features/configuration/parameterConfiguration/optionProduct_add";
import OptionProductEditView from "@/features/configuration/parameterConfiguration/optionProduct_add";
import OptionProductTypeAddView from "@/features/configuration/parameterConfiguration/optionProductType_add";
import OptionProductTypeEditView from "@/features/configuration/parameterConfiguration/optionProductType_edit";
import UserManagementView from "@/features/configuration/userManagement/userManagement_list";
import UserManagementAddView from "@/features/configuration/userManagement/userManagement_add";
import UserManagementEditView from "@/features/configuration/userManagement/userManagement_edit";
import CustomersView from "@/features/customers/customers/customers_list";
import CustomersAddView from "@/features/customers/customers/customers_add";
import CustomersEditView from "@/features/customers/customers/customers_edit";
import OrdersView from "@/features/customers/orders/orders_list";
import OrdersAddView from "@/features/customers/orders/orders_add";
import OrdersEditView from "@/features/customers/orders/orders_edit";
import VesselsView from "@/features/customers/vessels/vessels_list";
import VesselsAddView from "@/features/customers/vessels/vessels_add";
import VesselsEditView from "@/features/customers/vessels/vessels_edit";
import WorkFormView from "@/features/form/workform/workform_list.vue";
import WorkFormAddView from "@/features/form/workform/workform_add.vue";
import WorkFormEditView from "@/features/form/workform/workform_edit.vue";
import InspectionList from "@/features/form/inspection/inspection_list.vue";
import InspectionView from "@/features/form/inspection/inspection_view.vue";
import CertificateView from "@/features/form/certificate/certificate_list.vue";
import CertificateAdd from "@/features/form/certificate/certificate_add.vue";
import CertificateEdit from "@/features/form/certificate/certificate_edit.vue";
import DeliveryOrderView from "@/features/form/deliveryOrder/delivery_order_list.vue";
import ServiceList from "@/features/services/service_list.vue";
import ServiceAdd from "@/features/services/service_add.vue";
import History from "@/features/history/history.vue";
import Trash from "@/features/history/trash.vue";
import Customers from "@/components/layout/customerLayout.vue";
import VerifyForm from "@/features/users/activated_account.vue";
import FormOrderCustomer from "@/features/users/form_order_customers.vue";

import Vue from "vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {
      name: "login",
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      requiresAuth: false,
      requiresPrivilege: false,
    },
  },
  {
    path: "/login-customers",
    name: "loginCust",
    component: LoginCustomersView,
    meta: {
      requiresAuth: false,
      requiresPrivilege: false,
    },
  },
  {
    path: "/register-customers",
    name: "registerCust",
    component: RegisterCustomersView,
  },
  {
    path: "/customers",
    name: "customers",
    component: Customers,
    beforeEnter: (to, from, next) => {
      const isSuper = JSON.parse(localStorage.getItem("ismUser"));
      if (isSuper.role == "Admin") {
        next({ name: "home" });
      } else {
        next();
      }
    },
    children: [
      {
        path: "customers-verify-form",
        name: "verify-form",
        component: VerifyForm,
      },
      {
        path: "formorder",
        name: "form-order",
        component: FormOrderCustomer,
      },
    ],
  },
  {
    path: "/home",
    name: "home",
    component: LayoutView,
    beforeEnter: (to, from, next) => {
      const isSuper = JSON.parse(localStorage.getItem("ismUser"));
      if (isSuper.role != "Admin") {
        next({ name: "customers" });
      } else {
        next();
      }
      // }
    },
    children: [
      {
        path: "parameterconfiguration",
        name: "parameter-configuration-list",
        component: ParamaterConfigurationView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "parameterconfiguration/add",
        name: "parameter-configuration-add",
        component: ParamaterConfigurationAddView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "parameterconfiguration/edit",
        name: "parameter-configuration-edit",
        component: ParamaterConfigurationEditView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "optionProduct/add",
        name: "option-product-add",
        component: OptionProductAddView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "optionProduct/edit",
        name: "option-product-edit",
        component: OptionProductEditView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "optionProductType/add",
        name: "option-product-type-add",
        component: OptionProductTypeAddView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "optionProductType/edit",
        name: "option-product-type-edit",
        component: OptionProductTypeEditView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "usermanagement",
        name: "user-management-list",
        component: UserManagementView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "usermanagement/add",
        name: "user-management-add",
        component: UserManagementAddView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "usermanagement/edit",
        name: "user-management-edit",
        component: UserManagementEditView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "customers",
        name: "customers-list",
        component: CustomersView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "customers/add",
        name: "customers-add",
        component: CustomersAddView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "customers/edit",
        name: "customers-edit",
        component: CustomersEditView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "orders",
        name: "orders-list",
        component: OrdersView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "orders/add",
        name: "orders-add",
        component: OrdersAddView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "orders/edit",
        name: "orders-edit",
        component: OrdersEditView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "vessels",
        name: "vessels-list",
        component: VesselsView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "vessels/add",
        name: "vessels-add",
        component: VesselsAddView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "vessels/edit",
        name: "vessels-edit",
        component: VesselsEditView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "workform",
        name: "workform-list",
        component: WorkFormView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "workform/add",
        name: "workform-add",
        component: WorkFormAddView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "workform/edit",
        name: "workform-edit",
        component: WorkFormEditView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "inspection",
        name: "inspection-list",
        component: InspectionList,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "inspection/view",
        name: "inspection-view",
        component: InspectionView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "certificate",
        name: "certificate-list",
        component: CertificateView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "certificate/add",
        name: "certificate-add",
        component: CertificateAdd,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "certificate/edit",
        name: "certificate-edit",
        component: CertificateEdit,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "deliveryorder",
        name: "delivery-order-list",
        component: DeliveryOrderView,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "service",
        name: "service-list",
        component: ServiceList,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "service/add",
        name: "service-add",
        component: ServiceAdd,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "history",
        name: "history",
        component: History,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
      {
        path: "trash",
        name: "trash",
        component: Trash,
        // meta: {
        //   requiresAuth: true,
        //   requiresPrivilege: true,
        // },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  linkActiveClass: "is-active",
  mode: "history",
});

export default router;
