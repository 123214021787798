import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyB5SvT09cdU0vuRHZzFRhvjyZXknlbOhE8",
  authDomain: "indosafetymarine-9b920.firebaseapp.com",
  projectId: "indosafetymarine-9b920",
  storageBucket: "indosafetymarine-9b920.appspot.com",
  messagingSenderId: "695491409634",
  appId: "1:695491409634:web:a2fd9d4ff5a857eca810bb",
  measurementId: "G-D6NFPE0MDS"
};

firebase.initializeApp(firebaseConfig);

// utils
const firebaseDB = firebase.firestore();
const firebaseAuth = firebase.auth();

// collection references
const paramsCollection = firebaseDB.collection("params");
const usersCollection = firebaseDB.collection("users");
const vesselsCollection = firebaseDB.collection("vessels");
const customersCollection = firebaseDB.collection("customers");
const ordersCollection = firebaseDB.collection("orders");

// export utils/refs
export {
  firebaseDB,
  firebaseAuth,
  paramsCollection,
  usersCollection,
  vesselsCollection,
  customersCollection,
  ordersCollection,
};
