<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" :readonly="this.$route.query.statusPage === 'view'">
      <v-card>
        <isttoolbar
          title="Add Calibration"
          dark
          color="secondary"
          icon="mdi-cog-box"
        />
        <!-- TYPE GAS -->
        <v-container v-show="this.selectedValue == 'Gas'">
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">
                No Tube
              </span>
            </v-col>
            <v-col cols="8" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="typeProduct"
                solo
                flat
                outlined
                v-model="noTube"
                dense
                class="ma-0"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">
                Gas Type
              </span>
            </v-col>
            <v-col cols="8" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="typeProduct"
                solo
                flat
                outlined
                v-model="gasType"
                dense
                class="ma-0"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">
                Concentration
              </span>
            </v-col>
            <v-col cols="8" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="typeProduct"
                solo
                flat
                outlined
                v-model="concentration"
                dense
                class="ma-0"
              ></v-text-field>
              <span class="error--text" v-if="noInput"
                >Please Insert to the field!</span
              >
            </v-col>
          </v-row>
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">
                Unit
              </span>
            </v-col>
            <v-col cols="8" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="typeProduct"
                solo
                flat
                outlined
                v-model="unit"
                dense
                class="ma-0"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <!-- Type UTI -->
        <v-container v-show="this.selectedValue == 'UTI Meter'">
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">
                Deskripsi
              </span>
            </v-col>
            <v-col cols="8" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="deskripsi"
                solo
                flat
                outlined
                v-model="deskripsi"
                dense
                class="ma-0"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">
                Pre Calibration
              </span>
            </v-col>
            <v-col cols="8" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="preCalibration"
                solo
                flat
                outlined
                v-model="preCalibration"
                dense
                type="number"
                step="0.1"
                :rules="[validateInteger]"
                class="ma-0"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">
                Post Calibration
              </span>
            </v-col>
            <v-col cols="8" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="postCalibration"
                solo
                flat
                outlined
                v-model="postCalibration"
                dense
                class="ma-0"
                type="number"
                step="0.1"
                :rules="[validateInteger]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">
                Max Error Allowed
              </span>
            </v-col>
            <v-col cols="8" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="maximumErrorAllowed"
                solo
                flat
                outlined
                v-model="maximumErrorAllowed"
                dense
                type="number"
                step="0.1"
                :rules="[validateInteger]"
                class="ma-0"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="saveNewCalibration()">
            Save
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="$emit('cancel')">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";

export default {
  components: {
    isttoolbar,
  },
  props: ["dialog", "data", "selectedValue"],
  data: () => ({
    id: 0,
    noTube: "",
    gasType: "",
    concentration: "",
    unit: "",
    noInput: false,
    deskripsi: "",
    maximumErrorAllowed: "",
    preCalibration: "",
    postCalibration: "",
  }),
  watch: {
    dialog: {
      handler: function(after) {
        if (after) {
          console.log(true);
        }
      },
      deep: true,
    },
  },
  created() {
    console.log(this.selectedValue);
  },
  methods: {
    validateInteger(value) {
      const regex = /^\d+(\.\d{1})?$/;
      const isValid = regex.test(value);

      return isValid || "Input harus berupa angka dengan satu digit desimal";
    },
    async saveNewCalibration() {
      // input if value True or Cheklist
      // let selectedValuesProduct = localStorage.getItem('selectedItemValues')
      console.log(this.selectedValue);
      if (this.selectedValue === "UTI Meter") {
        if (
          this.deskripsi.length == 0 ||
          this.preCalibration.length == 0 ||
          this.postCalibration.length == 0 ||
          this.maximumErrorAllowed.length == 0
        ) {
          this.noInput = true;
          return;
        } else {
          this.noInput = false;
        }
        let obj = {
          id: 0,
          deskripsi: "",
          preCalibration: "",
          postCalibration: "",
          maximumErrorAllowed: "",
        };
        obj.deskripsi = this.deskripsi;
        obj.preCalibration = this.preCalibration;
        obj.postCalibration = this.postCalibration;
        obj.maximumErrorAllowed = this.maximumErrorAllowed;
        obj.id = this.data.length + 1;
        this.data.push(obj);
        this.deskripsi = "";
        this.preCalibration = "";
        this.postCalibration = "";
        this.maximumErrorAllowed = "";
        this.id = 0;
      } else if (this.selectedValue === "Gas") {
        if (
          this.noTube.length == 0 ||
          this.gasType.length == 0 ||
          this.concentration.length == 0 ||
          this.unit.length == 0
        ) {
          this.noInput = true;
          return;
        } else {
          this.noInput = false;
        }
        let obj = {
          id: 0,
          no: 0,
          noTube: "",
          gasType: "",
          concentration: "",
          unit: "",
        };
        obj.noTube = this.noTube;
        obj.gasType = this.gasType;
        obj.concentration = this.concentration;
        obj.unit = this.unit;
        obj.id = this.data.length + 1;
        obj.no = this.data.length + 1;
        this.data.push(obj);
        this.noTube = "";
        this.gasType = "";
        this.concentration = "";
        this.unit = "";
        this.id = 0;
      }
      this.$emit("save");
    },
  },
};
</script>
