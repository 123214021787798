<template>
  <v-card flat>
    <isttoolbar title="Work Form" dark color="secondary" icon="mdi-cog-outline">
      <v-spacer></v-spacer>
          <v-btn outlined color="white" @click="goToAdd">
            <v-icon left>mdi-plus</v-icon>Add Work Form
          </v-btn>
    </isttoolbar>
    <v-card>
      <v-card-title>
        Work Form List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
      >
        <template v-slot:[`item.statusCustomer`]="{ item }">
          {{item.statusCustomer ? "B2B" : "End User"}}
        </template>
        <template v-slot:[`item.statusOrder`]="{ item }">
          {{
          item.statusOrder === 1 ? "In Progress" :
          item.statusOrder === 2 ? "Approve" : 
          item.statusOrder === 3 ? "Reject" : 
          item.statusOrder === 4 ? "Final" : 
          "Entry"
          }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left :offset-x="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-show="item.statusDesc === 'Waiting for Client to approve Inspection'"
                class="pointer"
                @click="clickView(item)"
                >
                <v-list-item-title>View</v-list-item-title>
              </v-list-item>
              <v-list-item 
                v-show="item.statusDesc === 'Waiting for Client to approve Inspection'"
                class="pointer"
                @click="clickEdit(item)"
                >
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item class="pointer" @click="clickDelete(item)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data></template>
      </v-data-table>
    </v-card>

    <!-- DIALOG -->
    <v-row justify="center">
      <v-dialog v-model="dialogDelete" persistent width="unset" min-width="290px">
        <v-card min-width="290px">
          <v-card-title class="justify-center" style="word-break: inherit;"
            >Are you sure want to Delete ?</v-card-title
          >
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              @click="
                deleteData();
                dialogDelete = false;
              "
              outlined
              >Yes</v-btn
            >
            <v-btn
              color="red"
              min-width="100px"
              @click="dialogDelete = false"
              outlined
              >No</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Deleted</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Deleted</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="resetDialog"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { firebaseDB, ordersCollection } from "@/firebase"
export default {
  components: {
    isttoolbar
  },
  data: () => ({
    search: '',
    loading: false,
    headers: [
      { text: 'Order No', align: 'start', value: 'ordersNo'},
      { text: 'Customer', value: 'customerName' },
      { text: 'Status Customer', value: 'statusCustomer' },
      { text: 'Vessel', value: 'vesselName' },
      { text: 'Product', value: 'products' },
      // { text: 'Status', value: 'statusOrder' },
      { text: 'Status', value: 'statusDesc' },
      { text: 'Actions', value: 'actions', width: "100px", sortable: false, },
    ],
    items: [],
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    dialogDelete: false,
    item: {}
  }),
  watch: {},
  created() {
    this.getListData()
  },
  methods: {
    resetDialog() {
      this.dialog = { show: false, success: false, error: false, errorMessage: "" }
      this.item = {}
    },
    goToAdd() {
      this.$router.replace({ name: "workform-add" });
    },
    clickView(item) {
      this.$router.replace({ name: "workform-edit", query: { orderId: item.orderId, statusPage: 'view' } });
    },
    clickEdit(item) {
      this.$router.replace({ name: "workform-edit", query: { orderId: item.orderId, statusPage: 'edit' } });
    },
    clickDelete(item) {
      this.dialogDelete = true
      this.item = item
    },
    deleteData() {
      this.dialog.success = false;
      this.dialog.error = false;
      this.dialog.show = true;
      const newData = {
        isDeleted: true,
        deletedAt: new Date().toISOString(),
        deletedBy: "Admin",
      };
      firebaseDB.doc(`/orders/${this.item.orderId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Order not found"
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
          this.getListData()
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Something Went Wrong"
        });
    },


    getListData() {
      this.loading = true
      ordersCollection
      .where("statusDesc", "in", ["Waiting for Inspection process", "Waiting for Client to approve Inspection"])
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
          newData.push({
            orderId: doc.id,
            ordersNo: doc.data().ordersNo,
            customerId: doc.data().customerId,
            customerName: doc.data().customerName,
            vesselId: doc.data().vesselId,
            vesselName: doc.data().vesselName,
            statusCustomer: doc.data().statusCustomer,
            description: doc.data().description,
            products: doc.data().products,
            productsList: doc.data().productsList,
            statusOrder: doc.data().statusOrder,
            statusDesc: doc.data().statusDesc,
            createdAt: doc.data().createdAt,
          });
        })

        console.log(newData,'<< cek data orders')
        this.loading = false
        this.items = newData.sort(function(a, b) {
          return (a.createdAt > b.createdAt) ? -1 : ((a.createdAt < b.createdAt) ? 1 : 0);
        });
      })
      .catch(() => {
        this.loading = false
      });
    }
  }
};
</script>

<style scoped>
  .pointer {cursor: pointer;}
  .pointer:hover {
    background: #eeeeee;
  }
</style>