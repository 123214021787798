import Vue from 'vue';
const currencyHelper = Vue.mixin({
    methods: {
        formatDotCurrency: function (n, currency) {
            const fmt = new Intl.NumberFormat('id', {
                currency: "IDR"
            }).format(n);
            return currency + " " + fmt
        },

        formatCommaCurrency: function (n, currency) {
            const fmt = new Intl.NumberFormat('us', {
                currency: "USD"
            }).format(n);
            return currency + " " + fmt
        },
        formatMoney(amount, currency) {
            let newAmount = amount.toFixed(2)
            let numberString = newAmount.toString();
            let isMinus = false;
            if (numberString[0] === '-') {
                numberString = numberString.substr(1, numberString.length);
                isMinus = true;
            }
            const split = numberString.split('.');
            const overage = split[0].length % 3;
            const thousand = split[0].substr(overage).match(/\d{1,3}/gi);
            let decimal = split[1];
            let money = split[0].substr(0, overage);

            if (thousand) {
                const separator = overage ? ',' : '';
                money += separator + thousand.join(',');
            }

            if (decimal === undefined) {
                decimal = '00';
            } else if (decimal[1] === undefined) {
                decimal = `${decimal}0`;
            }

            let formatted = money;

            if (decimal !== undefined) {
                formatted = `${money}.${decimal}`;
            }
            
            if (isMinus) {
                formatted = `-${formatted}`;
            }

            if (currency) {
                switch (currency) {
                case 'IDR':
                    formatted = `IDR ${formatted}`;
                    break;
                default:
                    formatted = `${currency} ${formatted}`;
                    break;
                }
            }

            return formatted;
        }
    }
})
export default currencyHelper;