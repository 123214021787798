<template>
  <v-card flat>
    <isttoolbar
      title="Parameter Configuration"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2"
        tonal
        color="light-blue"
        @click="goToAdd"
        variant="tonal"
      >
        <v-icon left>mdi-plus</v-icon>Add Parameter Product
      </v-btn>
      <v-btn
        class="mr-2"
        tonal
        color="blue"
        @click="goToAdd('option-product-add')"
        variant="tonal"
      >
        <v-icon left>mdi-plus</v-icon>Add Option Product Type
      </v-btn>
      <!-- <v-btn
        tonal
        color="light-blue"
        @click="goToAdd('option-product-type-add')"
        variant="tonal"
      >
        <v-icon left>mdi-plus</v-icon>Add Option Product Type
      </v-btn> -->
    </isttoolbar>
    <v-card class="pa-5" elevation="5">
      <v-row>
        <v-col cols="3">
          <v-card-title>
            List Option Product
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            class="elevation-1"
            :headers="headersOptionProduct"
            :items="itemsOptionProduct"
            :search="search"
            :loading="loading"
          >
          </v-data-table>
        </v-col>
        <v-col cols="9">
          <v-row>
            <v-col>
              <v-card-title>
                List Option Product Type UTI Meter
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                class="elevation-1"
                :headers="headersOptionProductTypeUTI"
                :items="itemsOptionProductTypeUTI"
                :search="search"
                :loading="loading"
                :items-per-page="2"
              >
                <template v-slot:[`item.orderProductType`]="{ item }">
                  {{ item.orderProductType }}
                </template>
              </v-data-table>
            </v-col>
            <v-col>
              <v-card-title>
                List Option Product Type Gas Meter
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                class="elevation-1"
                :headers="headersOptionProductTypeGas"
                :items="itemsOptionProductTypeGas"
                :search="search"
                :loading="loading"
                :items-per-page="2"
              >
                <template v-slot:[`item.orderProductType`]="{ item }">
                  {{ item.orderProductType }}
                </template>
              </v-data-table>
            </v-col>

            <v-col>
              <v-card-title>
                List Option Product Others
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                class="elevation-1"
                :headers="headersOptionProductOthers"
                :items="itemsOptionProductTypeOthers"
                :search="search"
                :loading="loading"
                :items-per-page="3"
              >
                <template v-slot:[`item.orderProductType`]="{ item }">
                  {{ item.orderProductType }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <br />
      <br />
      <br />

      <v-divider></v-divider>
      <br />
      <v-card-title>
        List Parameter
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
      >
        <template v-slot:[`item.certificate`]="{ item }">
          <span
            class="mdi mdi-check-bold"
            v-if="item.certificate === 'OK'"
          ></span>
          <span class="mdi mdi-close" v-else></span>
        </template>
        <template v-slot:[`item.listGas`]="{ item }">
          <span
            class="mdi mdi-check-bold"
            v-if="item.certificate === 'OK'"
          ></span>
          <span class="mdi mdi-close" v-else></span>
        </template>
        <template v-slot:[`item.listCalibration`]="{ item }">
          <span
            class="mdi mdi-check-bold"
            v-if="item.certificate === 'OK'"
          ></span>
          <span class="mdi mdi-close" v-else></span>
        </template>
        <template v-slot:[`item.inspectionReport`]="{ item }">
          <span
            class="mdi mdi-check-bold"
            v-if="item.certificate === 'OK'"
          ></span>
          <span class="mdi mdi-close" v-else></span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{ item.status ? "Active" : "Inactive" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left :offset-x="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="pointer" @click="clickView(item)">
                <v-list-item-title>View</v-list-item-title>
              </v-list-item>
              <v-list-item class="pointer" @click="clickEdit(item)">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item class="pointer" @click="clickDelete(item)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data></template>
      </v-data-table>
    </v-card>

    <!-- DIALOG -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogDelete"
        persistent
        width="unset"
        min-width="290px"
      >
        <v-card min-width="290px">
          <v-card-title class="justify-center" style="word-break: inherit"
            >Are you sure want to Delete ?</v-card-title
          >
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              @click="
                deleteData();
                dialogDelete = false;
              "
              outlined
              >Yes</v-btn
            >
            <v-btn
              color="red"
              min-width="100px"
              @click="dialogDelete = false"
              outlined
              >No</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Deleted</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Deleted</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="resetDialog"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { firebaseDB, paramsCollection } from "../../../firebase";
export default {
  components: {
    isttoolbar,
  },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      {
        text: "Param Group",
        align: "start",
        value: "paramsGroup",
        width: "130px",
      },
      { text: "Params", value: "params", width: "130px" },
      { text: "Certificate", value: "certificate", width: "80px" },
      { text: "Gas List", value: "listGas", width: "80px" },
      { text: "Calibration List", value: "listCalibration", width: "80px" },
      { text: "Inspection Report", value: "inspectionReport", width: "80px" },
      { text: "Status", value: "status", width: "50px" },
      { text: "Actions", value: "actions", width: "50px", sortable: false },
    ],
    headersOptionProduct: [
      { text: "Product Name", value: "orderProduct", align: "center" },
    ],
    headersOptionProductTypeUTI: [
      { text: "Type", value: "orderProductType", align: "center" },
    ],
    headersOptionProductTypeGas: [
      { text: "Type", value: "orderProductType", align: "center" },
    ],
    headersOptionProductOthers: [
      { text: "Type", value: "orderProductType", align: "center" },
    ],
    items: [],
    itemsOptionProduct: [],
    itemsOptionProductTypeGas: [],
    itemsOptionProductTypeUTI: [],
    itemsOptionProductTypeOthers: [],
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    dialogDelete: false,
    item: {},
  }),

  watch: {},
  created() {
    this.getListData();
    this.getListDataProductOption();
    this.getListDataProductTypeOption();
  },

  methods: {
    resetDialog() {
      this.dialog = {
        show: false,
        success: false,
        error: false,
        errorMessage: "",
      };
      this.item = {};
    },

    goToAdd(payload) {
      console.log(payload);
      if (payload == "option-product-add") {
        return this.$router.replace({ name: `${payload}` });
      }
      if (payload == "option-product-type-add") {
        return this.$router.replace({ name: `${payload}` });
      }
      this.$router.replace({ name: "parameter-configuration-add" });
    },

    clickView(item) {
      this.$router.replace({
        name: "parameter-configuration-edit",
        query: { paramsId: item.paramsId, statusPage: "view" },
      });
    },

    clickEdit(item) {
      this.$router.replace({
        name: "parameter-configuration-edit",
        query: { paramsId: item.paramsId, statusPage: "edit" },
      });
    },

    clickDelete(item) {
      this.dialogDelete = true;
      this.item = item;
    },

    deleteData() {
      this.dialog.success = false;
      this.dialog.error = false;
      this.dialog.show = true;
      const newData = {
        isDeleted: true,
        deletedAt: new Date().toISOString(),
        deletedBy: "Admin",
      };
      firebaseDB
        .doc(`/params/${this.item.paramsId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Params not found";
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
          this.getListData();
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Something Went Wrong";
        });
    },

    getListData() {
      this.loading = true;
      paramsCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              paramsId: doc.id,
              paramsGroup: doc.data().paramsGroup,
              params: doc.data().params,
              certificate: this.convertCertificate(doc.data()),
              listGas: this.convertListGas(doc.data()),
              listCalibration: this.convertListCalibration(doc.data()),
              inspectionReport: this.convertInspectionReport(doc.data()),
              values: this.convertData(doc.data().values),
              status: doc.data().status,
              createdAt: doc.data().createdAt,
              createdBy: doc.data().createdBy,
              updatedAt: doc.data().updatedAt,
              updatedBy: doc.data().updatedBy,
            });
          });
          this.loading = false;
          this.items = newData.sort(function(a, b) {
            return a.createdAt > b.createdAt
              ? -1
              : a.createdAt < b.createdAt
              ? 1
              : 0;
          });
          localStorage.setItem("masterParams", JSON.stringify(newData));
        })
        .catch((err) => {
          this.loading = false;
          console.debug(err, "params list");
        });
    },
    getListDataProductOption() {
      this.loading = true;
      paramsCollection
        .where("params", "==", "Orders Product")
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              orderProduct: doc.data().values,
            });
          });
          this.loading = false;
          newData = JSON.parse(newData[0].orderProduct.replace(/\\"/g, '"'));
          this.itemsOptionProduct = newData;

          // localStorage.setItem(
          //   "masterParamsOptionProduct",
          //   JSON.stringify(newData)
          // );
        })
        .catch((err) => {
          this.loading = false;
          console.debug(err, "params list");
        });
    },
    getListDataProductTypeOption() {
      this.loading = true;
      paramsCollection
        .where("paramsGroup", "==", "Orders Product Type")
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              orderProductType: doc.data().values,
            });
          });
          this.loading = false;
          for (let i = 0; i < newData.length; i++) {
            newData[i].orderProductType = JSON.parse(
              newData[i].orderProductType.replace(/\\"/g, '"')
            );
          }
          this.itemsOptionProductTypeUTI = newData[0].orderProductType;
          this.itemsOptionProductTypeGas = newData[1].orderProductType;
          this.itemsOptionProductTypeOthers = newData[2].orderProductType;
          // localStorage.setItem(
          //   "masterParamsOptionProductType",
          //   JSON.stringify(newData)
          // );
        })
        .catch((err) => {
          this.loading = false;
          console.debug(err, "params list");
        });
    },

    convertCertificate(item) {
      let result = "";
      let data1 = JSON.parse(item.values);
      if (typeof data1 === "string") {
        try {
          let data2 = JSON.parse(data1);
          if (data2.certificate) {
            result = "OK";
          }
        } catch (error) {
          return "Data Broken";
        }
      }
      return result;
    },

    convertListGas(item) {
      let result = "";
      let data1 = JSON.parse(item.values);
      if (typeof data1 === "string") {
        try {
          let data2 = JSON.parse(data1);
          if (data2.listGas) {
            result = "OK";
          }
        } catch (error) {
          return "Data Broken";
        }
      }
      return result;
    },

    convertListCalibration(item) {
      let result = "";
      let data1 = JSON.parse(item.values);
      if (typeof data1 === "string") {
        try {
          let data2 = JSON.parse(data1);
          if (data2.listCalibration) {
            result = "OK";
          }
        } catch (error) {
          return "Data Broken";
        }
      }
      return result;
    },

    convertRandom(item) {
      let result = "";
      let data1 = JSON.parse(item.values);
      if (typeof data1 === "string") {
        try {
          let data2 = JSON.parse(data1);
          if (data2.listCalibration) {
            result = "OK";
          }
        } catch (error) {
          return "Data Broken";
        }
      }
      return result;
    },

    convertInspectionReport(item) {
      let result = "";
      let data1 = JSON.parse(item.values);
      if (typeof data1 === "string") {
        try {
          let data2 = JSON.parse(data1);
          if (data2.inspectionReport) {
            result = "OK";
          }
        } catch (error) {
          return "Data Broken";
        }
      }
      return result;
    },

    convertData(item) {
      let data = JSON.parse(item);
      if (typeof data === "string" || typeof data === "number") {
        return data;
      } else {
        return item;
      }
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.pointer:hover {
  background: #eeeeee;
}
</style>
