<template>
  <div v-if="!statusRequest == true" style="display: flex; justify-content: center;">
    <v-card class="mt-12" style="width: 70%;" elevation="14">
      <div
        class="d-flex justify-space-between"
        style=" background-color: #00529b;"
      >
        <v-card-title style="color: white;">
          User Request Form Activation
        </v-card-title>
        <v-card-title>
          <v-btn
            width="20"
            size="small"
            @click="goToHome"
            style="text-transform: capitalize;"
          >
            Back
          </v-btn>
        </v-card-title>
      </div>
      <div class="px-5 py-5 background-gradient">
        <v-form ref="formrequest">
          <form class="pa-4">
            <v-text-field
              ref="name"
              v-model="user.name"
              label="Customer Name"
              required
              disabled
            ></v-text-field>

            <v-text-field
              ref="email"
              v-model="user.email"
              label="Email"
              type="email"
              required
              disabled
            ></v-text-field>

            <v-text-field
              :rules="rules.phone_rules"
              ref="phone"
              v-model="state.phone"
              label="Phone"
            ></v-text-field>

            <v-text-field
              ref="telephone"
              v-model="state.telephone"
              label="Telephone (optional)"
            ></v-text-field>

            <!-- <v-text-field
              :rules="rules.npwp_rules"
              ref="npwp"
              v-model="state.npwp"
              label="NPWP"
              required
            ></v-text-field> -->

            <v-text-field
              :rules="rules.require"
              ref="address"
              v-model="state.address"
              label="Address"
              required
            ></v-text-field>
          </form>
        </v-form>
      </div>
      <v-card-actions
        class="justify-space-between"
        style="background-color: #00529b; color: white;"
      >
        <div>
          <v-btn
            @click="action('reset')"
            class="me-4"
            color="amber"
            size="small"
            style="text-transform: capitalize;"
          >
            Reset Form
          </v-btn>
        </div>
        <v-btn
          @click="action('request')"
          color="lime"
          style="text-transform: capitalize;"
        >
          Send Request Activation
        </v-btn>
      </v-card-actions>
      <v-row justify="center">
        <v-dialog v-model="dialog.show" persistent max-width="290">
          <v-card v-show="!dialog.success && !dialog.error">
            <v-card-title>
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-card-title>
          </v-card>
          <v-card v-show="dialog.success || dialog.error">
            <v-card-title
              v-if="dialog.success"
              class="justify-center"
              style="word-break: inherit"
              >Your Data has been Saved</v-card-title
            >
            <v-card-title
              v-else-if="dialog.error"
              class="justify-center"
              style="word-break: inherit"
              >Your Data Failed to Saved</v-card-title
            >
            <v-card-text
              v-if="dialog.error"
              class="d-flex justify-center error--text"
              style="word-break: inherit"
            >
              {{ dialog.errorMessage }}
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                color="primary"
                min-width="100px"
                outlined
                @click="dialog.success ? back() : (dialog.show = false)"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card>
  </div>
  <div
    v-else
    style="display: flex; flex-direction: column; align-items: center; align-content: center;"
    class="pa-5 mt-16"
  >
    <v-img
      style="height: auto; max-width: 20%;"
      src="@/assets/images/waiting_approval.svg"
      alt="My Image"
    ></v-img>

    <p class="text-h2 mt-16"> Terima Kasih !</p>

    <p class="text-h5 mt-5">
      Anda sudah melakukan aktivasi akun, Admin akan segera melakukan verifikasi
      !
    </p>
  </div>
</template>

<script>
import { customersCollection } from "../../firebase";
export default {
  name: "AdminIndosafetymarineFormActivationAccountCustomers",

  data() {
    return {
      statusRequest: false,
      orders: [],
      dataVessels: [],
      user: "",
      initialState: {
        name: "",
        email: "",
        select: null,
        checkbox: null,
      },
      state: {
        name: "",
        email: "",
        phone: "",
        telephone: "",
        // npwp: "",
        address: "",
      },
      dialog: { show: false, success: false, error: false, errorMessage: "" },
      rules: {
        require: [(v) => !!v || "field is required"],
        email_rules: [
          (v) => !!v || "email is required",
          (v) => /.+@.+\..+/.test(v) || "email must be valid",
        ],
        phone_rules: [
          (v) => !!v || "field is required",
          (v) => /^\d+$/g.test(v) || "Phone number must contain only numbers",
        ],
        // npwp_rules: [
        //   (v) => !!v || "field is required",
        //   (v) => /^\d+$/g.test(v) || "NPWP number must contain only numbers",
        // ],
      },
    };
  },
  async mounted() {
    let getUser = await localStorage.getItem("ismUser");
    this.user = JSON.parse(getUser);
    this.checkUser();
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  methods: {
    back() {
      this.$router.replace({
        name: "customers",
      });
    },
    checkUser() {
      let stateWaiting = JSON.parse(localStorage.getItem("waitingVerify"));
      if(stateWaiting == true) {
        this.statusRequest = stateWaiting
      } else {
        this.statusRequest = stateWaiting
      }
    },
    goToHome() {
      this.$router.push("/customers");
    },
    async action(payload) {
      if (payload == "request") {
        const valid = await this.$refs.formrequest.validate();
        if (valid) {
          this.dialog.success = false;
          this.dialog.error = false;
          this.dialog.show = true;
          await this.doAdd();
        }
      } else {
        this.reset();
        this.resetValidation();
      }
    },
    async doAdd() {
      this.state.name = this.user.name;
      this.state.email = this.user.email;

      // Periksa apakah akun dengan email yang sama sudah ada
      const existingAccount = await customersCollection
          .where("email", "==", this.state.email)
          .get();

      if (!existingAccount.empty) {
        // Akun dengan email yang sama sudah ada, tampilkan pesan kesalahan
        this.dialog.error = true;
        this.dialog.errorMessage = "Account with this email already exists";
        return;
      }

      const newData = {
        customerName: this.state.name,
        address: this.state.address,
        // npwp: this.state.npwp,
        email: this.state.email,
        telp: this.state.telephone,
        phone: this.state.phone,
        isActive: false,
        isVerify: false,
        isRegistered: true,
        isDeleted: false,
        createdAt: new Date().toISOString(),
        createdBy: "Self",
        updatedBy: "",
        updatedAt: "",
        deletedAt: "",
        deletedBy: "",
      };

      customersCollection
        .add(newData)
        .then(() => {
          this.dialog.success = true;
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Somthing Went Wrong";
        });
    },
    reset() {
      this.state = {
        phone: "",
        telephone: "",
        // npwp: "",
        address: "",
      };
    },
    resetValidation() {
      this.$refs.formrequest.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
.background-gradient {
  background: linear-gradient(to right, #e4f3ff, #3989cf80);
  /* Gunakan kode warna silver (#c0c0c0) dan hijau (#2ecc71) sesuai keinginan Anda */
  background-size: 200% 100%; /* Atur lebar dan tinggi gradasi */
}
</style>
