<template>
  <div class="surat-resmi">
    <v-container fluid>
      <v-row align="center" justify="space-between">
        <v-col cols="2">
          <img
            src="@/assets/images/logo-ism.png"
            contain
            alt="Indo Safety Marine Logo"
            width="100%"
            style="padding-left: 40px; margin-top: 20px"
          />
        </v-col>
        <v-col cols="6">
          <div class="header">
            <h1>PT. Indo Safety Marine</h1>
            <br />
            <p>
              Ruko Sentra Menteng<span
                >- Jl. MH Thamrin Blok B7 MN32 3rd Floor, Bintaro Jaya Sektor 7
                Tangerang Selatan 15224, Indonesia</span
              >
            </p>
            <p>Hotline : +62817-750838, Email : info@indosafetymarine.com</p>
            <p>www.indosafetymarine.com</p>
            <p>www.utimeterjakarta.com</p>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="header-right">
            <h1>CALIBRATION DATA</h1>
          </div>
          <div class="subheader-right">
            <p>ISM.15-02.122022</p>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="space-between" style="margin-top: -15px;">
        <v-col cols="8">
          <table id="customers">
            <tr>
              <th>Calibration Date</th>
              <th>Due Date</th>
            </tr>
            <tr>
              <td>
                {{ data.calibrationDate ? data[0].calibrationDate : "-" }}
              </td>
              <td>
                {{ data.calibrationDueDate ? data[0].calibrationDueDate : "-" }}
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="4">
          <table id="customers">
            <tr>
              <th>Vessel Name</th>
            </tr>
            <tr>
              <td>{{ data.vesselName ? data.vesselName : "-" }}</td>
            </tr>
            <tr>
              <th>P.O. NUMBER</th>
            </tr>
            <tr>
              <td>{{ data.ordersNo ? data.ordersNo : "-" }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-container>
    <div class="content">
      <v-row>
        <v-col>
          <h4>Instrument Details</h4>

          <table id="customersData">
            <tr>
              <th colspan="2">General Information</th>
            </tr>
            <tr>
              <td colspan="2">Device Type : {{ data.products }}</td>
            </tr>
            <tr>
              <td>
                Manufacturer : {{ data.productsList[0].orderProductType }}
              </td>
              <td>Serial Number : {{ data.productsList[0].orderProductSN }}</td>
            </tr>
            <tr>
              <td colspan="2">
                Model :
                {{
                  data.productsList[0].orderProductSeries
                    ? data.productsList[0].orderProductSeries
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <th colspan="2">Additional Information</th>
            </tr>
            <tr v-for="(tape, index) in this.data.productsList" :key="index">
              <td colspan="2">Tape Length : {{ tape.tapeLength }}</td>
             
            </tr>
            <tr v-for="(tape, index) in this.data.productsList" :key="index">
              
              <td colspan="2">Valve Connector : {{ tape.valveConector }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Calibration Standarts</h4>
          <table id="customersData">
            <tr>
              <th>Apparatus</th>
              <th>Manufacturer/Model</th>
              <th>Manuf no/Serial</th>
            </tr>
            <tr>
              <td>Water Bath</td>
              <td>MEMMERT / WNB 7</td>
              <td>60529</td>
            </tr>
            <tr>
              <td>Thermometer</td>
              <td>Thermo Scientific / TEMP-360</td>
              <td>4009255</td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Test Results</h4>
          <table id="customersData">
            <tr>
              <th colspan="7">Temperature readings</th>
            </tr>
            <tr>
              <th
                colspan="3"
                style="background-color: white; text-align: center; color: black; font-weight: bold;"
              >
                Pre Calibration Readings
              </th>
              <th
                colspan="4"
                style="background-color: white; text-align: center; color: black; font-weight: bold;"
              >
                Post Calibration Readings
              </th>
            </tr>
            <tr>
              <th
                style="background-color: white; text-align: center; color: black; font-weight: bold;"
              >
                Reference Thermometer
              </th>
              <th
                style="background-color: white; text-align: center; color: black; font-weight: bold;"
              >
                Device Under Test
              </th>
              <th
                style="background-color: white; text-align: center; color: black; font-weight: bold;"
              >
                Error
              </th>
              <th
                style="background-color: white; text-align: center; color: black; font-weight: bold;"
              >
                Reference Thermometer
              </th>
              <th
                style="background-color: white; text-align: center; color: black; font-weight: bold;"
              >
                Device Under Test
              </th>
              <th
                style="background-color: white; text-align: center; color: black; font-weight: bold;"
              >
                Error
              </th>
              <th
                style="background-color: white; text-align: center; color: black; font-weight: bold;"
              >
                Maximum Error Allowed
              </th>
            </tr>
            <tr style="text-align: center;">
              <td>50,1</td>
              <td>50,1</td>
              <td>0,0</td>
              <td>50,1</td>
              <td>50,1</td>
              <td>0,0</td>
              <td>± 0,2</td>
            </tr>
            <tr style="text-align: center;">
              <td>50,1</td>
              <td>50,1</td>
              <td>0,0</td>
              <td>50,1</td>
              <td>50,1</td>
              <td>0,0</td>
              <td>± 0,2</td>
            </tr>
            <tr style="text-align: center;">
              <td>50,1</td>
              <td>50,1</td>
              <td>0,0</td>
              <td>50,1</td>
              <td>50,1</td>
              <td>0,0</td>
              <td>± 0,2</td>
            </tr>
          </table>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <table id="customersData">
            <tr>
              <th colspan="2">Interface Test</th>
            </tr>
            <tr style="text-align: center;">
              <td>Ullage / Air</td>
              <td style="font-weight: bold;">PASS</td>
            </tr>
            <tr style="text-align: center;">
              <td>Oil</td>
              <td style="font-weight: bold;">PASS</td>
            </tr>
            <tr style="text-align: center;">
              <td>Water</td>
              <td style="font-weight: bold;">PASS</td>
            </tr>
          </table>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <table id="customersData">
            <tr>
              <th>Additional Service / Repair / Maintenance Details</th>
            </tr>
            <tr>
              <td>Repaired tape spliced at 34M, 70cm position</td>
            </tr>
          </table>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h4>Notes</h4>
          <table id="customersData">
            <tr>
              <th>Remarks</th>
            </tr>
            <tr>
              <td>
                All calibration and test results are applicable at the time of
                calibration
              </td>
            </tr>
            <tr>
              <td>
                Test and calibration are performed in environtmentally
                controlled area with temperature set at 23 ± 2° C and 40-60%
                relative humidhty
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3"></v-col>
        <v-col cols="3"></v-col>
        <v-col cols="6">
          <div class="signature">
            <p style="margin-bottom: 5vh;">Calibrated by :</p>
            <p><strong>Odilo Spot</strong></p>
          </div>
        </v-col>
      </v-row>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "certificate_vue",
  props: {
    data: Object,
  },
  data: () => ({
    headers: [
      {
        text: "Device",
        key: "device",
      },
      {
        text: "Result",
        key: "result",
      },
    ],
    dataTape: [
      {
        name: "Tape Length",
        value: "",
      },
      {
        name: "Valve Connector",
        value: "",
      },
    ],
  }),
  created() {
    this.dataTape.value = this.data.customerName;
  },
};
</script>

<style>
.surat-resmi {
  padding-left: 30px;
  padding-right: 30px;
}

.header {
  /* border-bottom: 1px solid #000; */
  padding-bottom: 20px;
  margin-top: 30px;
  margin-bottom: 5px;
  text-align: left;
}

.header-right {
  /* border-bottom: 1px solid #000; */
  padding-bottom: 20px;
  margin-top: 30px;
  text-align: center;
  background-color: black;
  color: white;
  padding: 6%;
}

.subheader-right {
  /* border-bottom: 1px solid #000; */
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  padding: 3%;
  border: 1px solid rgb(0, 0, 0);
}

.header h1 {
  font-size: 16px;
  margin: 0;
  line-height: 0.5;
}

.header p {
  margin: 0;
  font-size: 10px;
  line-height: 1.2;
}

/* Style untuk content surat */
.content {
  margin-top: 2px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 1.5;
}

/* Style untuk tanda tangan */
.signature {
  margin-top: -15px;
  margin-bottom: 30%;
  font-size: 9px;
}

.signature p {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  padding-left: 0;
}

.footer {
  position: absolute;
  background-color: none;
  bottom: 0;
  width: 93%;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 4px;
}

#customers th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: #000000;
  color: white;
}

#customersData {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 10px;
}

#customersData td,
#customersData th {
  border: 1px solid #ddd;
  padding: 4px;
}

#customersData th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: #000000;
  color: white;
}
</style>
