<template>
  <v-card flat class="mb-10">
    <isttoolbar
      title="Orders"
      dark
      color="secondary"
      icon="mdi-folder-account-outline"
    >
      <v-spacer />
      <v-btn
        v-show="this.$route.query.statusPage === 'view'"
        @click="edit"
        outlined
        class="mx-1"
        min-width="100px"
        >Edit</v-btn
      >
      <v-btn
        v-show="this.$route.query.statusPage !== 'view'"
        @click="save"
        outlined
        class="mx-1"
        min-width="100px"
        >Save</v-btn
      >
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>
    <isttoolbar
      :title="
        this.$route.query.statusPage === 'view' ? 'View Order' : 'Edit Order'
      "
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>
    <v-form
      ref="form"
      class="mb-8"
      :readonly="this.$route.query.statusPage === 'view'"
    >
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Customer Name
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-autocomplete
            hide-details="auto"
            label="Select Customer"
            @input="setDataCustomer"
            v-model="param.customerName"
            :items="dataCustomers"
            item-value="customerName"
            item-text="customerName"
            ref="customerName"
            class="ma-0"
            :loading="loadingCustomer"
            return-object
            flat
            outlined
            dense
            solo
            :clearable="$route.query.statusPage === 'edit'"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Vessel Name
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-autocomplete
            hide-details="auto"
            label="Select Vessel"
            @input="setDataVessel"
            v-model="param.vesselName"
            :items="dataVessels"
            item-value="vesselName"
            item-text="vesselName"
            ref="vesselName"
            class="ma-0"
            :loading="loadingVessel"
            return-object
            flat
            outlined
            dense
            solo
            :clearable="$route.query.statusPage === 'edit'"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Status Customer
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-switch
            ref="statusCustomer"
            name="statusCustomer"
            color="success"
            v-model="param.statusCustomer"
            inset
            :label="`${param.statusCustomer ? 'B2B' : 'End User'}`"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Description
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-textarea
            hide-details="auto"
            v-model="param.description"
            solo
            flat
            outlined
            dense
            ref="description"
            name="input-7-4"
            label="Description"
            :rows="6"
            :rules="[rules.required]"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-data-table
        class="elevation-1 mt-8"
        :headers="headers"
        :items="items"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>List Product</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogCRUD" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="$route.query.statusPage === 'edit'"
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                  v-bind="attrs"
                  @click="openDialog"
                >
                  New Product
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                          hide-details="auto"
                          label="Select Product"
                          @input="setDataProduct"
                          v-model="editedItem.orderProduct"
                          :items="orderProduct"
                          item-value="orderProduct"
                          item-text="orderProduct"
                          ref="orderProduct"
                          class="ma-0"
                          return-object
                          flat
                          outlined
                          dense
                          solo
                          :clearable="$route.query.statusPage === 'edit'"
                          :rules="[rules.required]"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <!-- <v-text-field
                        v-if="editedItem.orderProduct === 'Others'"
                        hide-details="auto"
                        v-model="editedItem.orderProductType"
                        label="Product Series"
                        ref="orderProductSeries"
                        class="ma-0"
                        flat
                        outlined
                        dense
                        solo
                        :rules="[rules.required]"
                      ></v-text-field> -->
                        <!-- v-else -->
                        <v-autocomplete
                          hide-details="auto"
                          label="Select Product Type"
                          @input="setDataProductType"
                          v-model="editedItem.orderProductType"
                          :items="orderProductType"
                          item-value="orderProductType"
                          item-text="orderProductType"
                          ref="orderProductType"
                          class="ma-0"
                          return-object
                          flat
                          outlined
                          dense
                          solo
                          :clearable="$route.query.statusPage === 'edit'"
                          :disabled="editedItem.orderProduct === ''"
                          :rules="[rules.required]"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <!-- <v-text-field
                        v-if="editedItem.orderProduct === 'Others'"
                        hide-details="auto"
                        v-model="editedItem.orderProductSeries"
                        label="Product Series"
                        ref="orderProductSeries"
                        class="ma-0"
                        flat
                        outlined
                        dense
                        solo
                        :rules="[rules.required]"
                      ></v-text-field> -->
                        <!-- v-else -->
                        <v-autocomplete
                          hide-details="auto"
                          label="Select Product Series"
                          @input="setDataProductSeries"
                          v-model="editedItem.orderProductSeries"
                          :items="orderProductSeries"
                          item-value="params"
                          item-text="params"
                          ref="orderProductSeries"
                          class="ma-0"
                          return-object
                          flat
                          outlined
                          dense
                          solo
                          :clearable="$route.query.statusPage === 'edit'"
                          :disabled="editedItem.orderProduct === ''"
                          :rules="[rules.required]"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          hide-details="auto"
                          v-model="editedItem.orderProductSN"
                          label="Product Serial Number"
                          ref="orderProductSN"
                          class="ma-0"
                          flat
                          outlined
                          dense
                          solo
                          :disabled="editedItem.orderProduct === ''"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-textarea
                          hide-details="auto"
                          v-model="editedItem.notes"
                          label="Notes"
                          ref="notes"
                          class="ma-0"
                          flat
                          outlined
                          dense
                          solo
                          :rows="3"
                          :disabled="editedItem.orderProduct === ''"
                          :rules="[rules.required]"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-show="$route.query.statusPage !== 'view'"
                    color="blue darken-1"
                    outlined
                    @click="newItem"
                  >
                    Save
                  </v-btn>
                  <v-btn color="blue darken-1" outlined @click="close">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              persistent
              width="unset"
              min-width="290px"
            >
              <v-card min-width="290px">
                <v-card-title
                  class="justify-center"
                  style="word-break: inherit;"
                  >Are you sure want to Delete ?</v-card-title
                >
                <v-card-actions class="justify-center">
                  <v-btn
                    color="primary"
                    min-width="100px"
                    @click="deleteItemConfirm"
                    outlined
                    >Yes</v-btn
                  >
                  <v-btn
                    color="red"
                    min-width="100px"
                    @click="closeDelete"
                    outlined
                    >No</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-show="$route.query.statusPage === 'view'"
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            v-show="$route.query.statusPage !== 'view'"
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-show="$route.query.statusPage !== 'view'"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data></template>
      </v-data-table>
    </v-form>
    


    <v-divider class="mt-10"></v-divider>
    <v-form class="mt-6 mb-6">
      <v-row>
        <v-col cols="6">
          <v-row align="center" class="ml-2 mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">Entry By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="create"
                v-model="param.createdBy"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="createDate"
                v-model="param.createdAt"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="ml-2 mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">Updated By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="create"
                v-model="param.updatedBy"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="createDate"
                v-model="param.updatedAt"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="ml-2 mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">In Progress By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="create"
                v-model="param.inProgressBy"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="createDate"
                v-model="param.inProgressAt"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row align="center" class="mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">Approved By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="close"
                v-model="param.approvedBy"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="closeDate"
                v-model="param.approvedAt"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">Rejected By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="close"
                v-model="param.rejectedBy"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="closeDate"
                v-model="param.rejectedAt"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">Final By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="close"
                v-model="param.finalBy"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="closeDate"
                v-model="param.finalAt"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      


    </v-form>
    <v-divider class="mb-10"></v-divider>

    <!-- DIALOG -->
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Updated</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Updated</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import {
  firebaseDB,
  customersCollection,
  vesselsCollection,
  ordersCollection,
} from "../../../firebase";

export default {
  components: {
    isttoolbar,
  },
  props: {
    source: String,
  },

  data: () => ({
    masterParams: [],
    orderProduct: [],
    orderProductType: [],
    orderProductSeries: [],
    loadingCustomer: false,
    dataCustomers: [],
    loadingVessel: false,
    dataVessels: [],
    param: {
      customerId: "",
      customerName: "",
      vesselId: "",
      vesselName: "",
      statusCustomer: true,
      description: "",
      products: "",
      productsList: [],
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    items: [],
    headers: [
      { text: "Product", align: "start", value: "orderProduct" },
      { text: "Product Type", value: "orderProductType" },
      { text: "Product Series", value: "orderProductSeries" },
      { text: "Product Serial Number", value: "orderProductSN" },
      { text: "Notes", value: "notes" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    dialogCRUD: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      orderProduct: "",
      orderProductType: "",
      orderProductSeries: "",
      orderProductSN: "",
      notes: "",
      statusWorkForm: false,
    },
    defaultItem: {
      orderProduct: "",
      orderProductType: "",
      orderProductSeries: "",
      orderProductSN: "",
      notes: "",
      statusWorkForm: false,
    },
    orders: [],
  }),
  computed: {
    form() {
      return {
        customerName: this.param.customerName,
        vesselName: this.param.vesselName,
        description: this.param.description,
        orderProduct: this.editedItem.orderProduct,
        orderProductType: this.editedItem.orderProductType,
        orderProductSeries: this.editedItem.orderProductSeries,
        orderProductSN: this.editedItem.orderProductSN,
        notes: this.editedItem.notes,
      };
    },
    formTitle() {
      return this.$route.query.statusPage === "view"
        ? "View Product"
        : this.editedIndex === -1
        ? "New Product"
        : "Edit Product";
    },
  },
  watch: {
    dialogCRUD(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.getDataOrders();
    this.getListCustomer();
    this.getListVessel();
    this.getListOrders();
    console.log(this.param,'<< param')

    this.masterParams = JSON.parse(localStorage.getItem("masterParams"));
    this.masterParams.forEach((element) => {
      if (
        element.paramsGroup === "Orders" &&
        element.params === "Orders Product"
      ) {
        this.orderProduct = JSON.parse(element.values);
      }
    });
  },
  methods: {
    edit() {
      this.$router.replace({
        name: "orders-edit",
        query: { orderId: this.$route.query.orderId, statusPage: "edit" },
      });
    },
    back() {
      this.$router.replace({
        name: "orders-list",
      });
    },
    getDataOrders() {
      this.dialog.success = false;
      this.dialog.error = false;
      this.dialog.show = true;
      let newData = {};
      firebaseDB
        .doc(`/orders/${this.$route.query.orderId}`)
        .get()
        .then(async (doc) => {
          newData = doc.data();
          console.log(newData,'<< data')
          newData.orderId = doc.id;
          newData.createdAt = doc
            .data()
            .createdAt.match(/([^T]+)/)[0]
            .split("-")
            .reverse()
            .join("/");
          newData.updatedAt =
            doc.data().updatedAt !== ""
              ? doc
                  .data()
                  .updatedAt.match(/([^T]+)/)[0]
                  .split("-")
                  .reverse()
                  .join("/")
              : "";
          this.param = newData;
          this.items = newData.productsList;
          
          this.dialog.success = false;
          this.dialog.error = false;
          this.dialog.show = false;
        })
        .catch(() => {
          this.dialog.success = false;
          this.dialog.error = false;
          this.dialog.show = false;
        });
    },
    getListOrders() {
      ordersCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              orderId: doc.id,
              ordersNo: doc.data().ordersNo,
            });
          });
          this.orders = newData.sort(function(a, b) {
            return a.createdAt > b.createdAt
              ? -1
              : a.createdAt < b.createdAt
              ? 1
              : 0;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async save() {
      Object.keys(this.form).forEach((f) => {
        if (f === "customerName" || f === "vesselName" || f === "description") {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
        }
      });
      if (!this.formHasErrors) {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        if (this.items.length === 0) {
          this.dialog.error = true;
          this.dialog.errorMessage = "Field list product must be filled";
        } else {
          await this.doAdd();
        }
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      let gasMeter = this.items.filter((x) => x.orderProduct === "Gas Meter");
      let utiMeter = this.items.filter((x) => x.orderProduct === "UTI Meter");
      let others = this.items.filter((x) => x.orderProduct === "Others");
      let productDesc = [];

      if (gasMeter.length > 0) {
        productDesc.push("Gas Meter");
      }
      if (utiMeter.length > 0) {
        productDesc.push("Uti Meter");
      }
      if (others.length > 0) {
        productDesc.push("Others");
      }

      const newData = {
        customerId: this.param.customerId,
        customerName: this.param.customerName,
        vesselId: this.param.vesselId,
        vesselName: this.param.vesselName,
        statusCustomer: this.param.statusCustomer,
        description: this.param.description,
        products: productDesc.toString(),
        productsList: this.items,
        updatedAt: new Date().toISOString(),
        updatedBy: "Admin",
      };
      firebaseDB
        .doc(`/orders/${this.$route.query.orderId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Orders not found";
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Somthing Went Wrong";
        });
    },
    setDataCustomer(item) {
      if (item === null) {
        this.param.customerId = "";
        this.param.customerName = "";
      } else {
        this.param.customerId = item.customerId;
        this.param.customerName = item.customerName;
      }
    },
    getListCustomer() {
      this.loadingCustomer = true;
      customersCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              customerId: doc.id,
              customerName: doc.data().customerName,
            });
          });
          this.loadingCustomer = false;
          this.dataCustomers = newData;
        })
        .catch(() => {
          this.loadingCustomer = false;
        });
    },
    setDataVessel(item) {
      if (item === null) {
        this.param.vesselId = "";
        this.param.vesselName = "";
      } else {
        this.param.vesselId = item.vesselId;
        this.param.vesselName = item.vesselName;
      }
    },
    getListVessel() {
      this.loadingVessel = true;
      vesselsCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              vesselId: doc.id,
              vesselName: doc.data().vesselName,
            });
          });
          this.loadingVessel = false;
          this.dataVessels = newData;
        })
        .catch(() => {
          this.loadingVessel = false;
        });
    },
    setDataProduct(item) {
      console.debug(item);
      if (item === null) {
        this.editedItem.orderProduct = "";
      } else {
        this.editedItem.orderProduct = item.orderProduct;
        this.masterParams.forEach((element) => {
          if (
            element.paramsGroup === "Orders Product Type" &&
            element.params === item.orderProduct
          ) {
            this.orderProductType = JSON.parse(element.values);
          }
        });
      }
      this.editedItem.orderProductType = "";
      this.editedItem.orderProductSeries = "";
    },
    setDataProductType(item) {
      if (item === null) {
        this.editedItem.orderProductType = "";
      } else {
        this.editedItem.orderProductType = item.orderProductType;
        let newData = this.masterParams.filter(function(el) {
          return el.paramsGroup === item.orderProductType;
        });
        this.orderProductSeries = newData;
      }
      this.editedItem.orderProductSeries = "";
    },
    setDataProductSeries(item) {
      if (item === null) {
        this.editedItem.orderProductSeries = "";
      } else {
        this.editedItem.orderProductSeries = item.params;
        const obj = JSON.parse(item.values)
        Object.assign(this.editedItem, JSON.parse(obj));
        // Object.assign(this.editedItem, JSON.parse(item.values));
      }
    },
    openDialog() {
      this.dialogCRUD = true;
      setTimeout(() => {
        Object.keys(this.form).forEach((f) => {
          if (
            f === "orderProduct" ||
            f === "orderProductType" ||
            f === "orderProductSeries" ||
            f === "orderProductSN" ||
            f === "notes"
          ) {
            this.$refs[f].reset();
          }
        });
        this.editedItem = Object.assign({}, this.defaultItem);
      }, 50);
    },
    editItem(item) {
      console.debug(item, "editItem");
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.masterParams.forEach((element) => {
        if (
          element.paramsGroup === "Orders Product Type" &&
          element.params === item.orderProduct
        ) {
          this.orderProductType = JSON.parse(element.values);
        }
      });
      let newData = this.masterParams.filter(function(el) {
        return el.paramsGroup === item.orderProductType;
      });
      this.orderProductSeries = newData;
      this.dialogCRUD = true;
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.dialogCRUD = false;
      this.formHasErrors = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    newItem() {
      Object.keys(this.form).forEach((f) => {
        if (
          f === "orderProduct" ||
          f === "orderProductType" ||
          f === "orderProductSeries" ||
          f === "orderProductSN" ||
          f === "notes"
        ) {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
        }
      });
      if (!this.formHasErrors) {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
        } else {
          this.items.push(this.editedItem);
        }
        this.close();
      } else {
        this.formHasErrors = false;
      }
    },
  },
};
</script>
