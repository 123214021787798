<template>
  <div class="d-flex justify-center pa-5 pb-16">
    <v-row>
      <v-col
        style="display: block !important;"
        class="d-flex flex-column px-12"
      >
        <v-btn
          width="20"
          size="small"
          @click="goToHome"
          style="text-transform: capitalize;"
        >
          Back
        </v-btn>
        <v-divider class="mb-5 mt-5"></v-divider>
        <v-row style="min-height: 20vh !important;">
          <v-col cols="2" style="display: flex; justify-content: center">
            <v-avatar size="64">
              <v-img
                alt="Avatar"
                src="https://cdn.dribbble.com/users/4018190/screenshots/14193143/media/364a05f83df9a2ea9a5fd144390cc7cc.jpg?resize=400x300&vertical=center"
              />
            </v-avatar>
          </v-col>
          <v-col cols="10">
            <p class="text-h6 font-weight-bold">Data User</p>
            <v-row style="max-height: 18vh;">
              <v-col cols="2">
                <p class="text-body font-weight-bold">
                  PIC Name
                </p>
                <p class="text-body font-weight-bold">
                  Email
                </p>
                <p class="text-body font-weight-bold">
                  Status
                </p>
              </v-col>
              <v-col>
                <p class="font-weight-regular ml-10">: {{ user.name }}</p>
                <p class="font-weight-regular ml-10">: {{ user.email }}</p>
                <p class="font-weight-regular ml-10">: {{ user.role }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider class="mb-5"></v-divider>
        <p class="text-h6 font-weight-bold">Information Order</p>
        <v-data-table
          :headers="headers"
          :items="orders"
          :loading="loading"
          :items-per-page="5"
          class="elevation-1"
          @click:row="handleClick"
          style="cursor: pointer;"
        >
          <template v-slot:item.no="{ index }">
            <p>{{ index + 1 }}</p>
          </template>

          <template v-slot:item.productsList="{ item }">
            <dl v-for="(product, index) in item.productsList" :key="index">
              <dt class="text-caption font-weight-bold">{{ product.orderProduct }}</dt>
              <dd class="ml-2">- {{ product.orderProductSeries }}</dd>
            </dl>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <p>{{ formatCustomDate(item.createdAt) }}</p>
          </template>
          <template v-slot:item.finalAt="{ item }">
            <p>{{ item.finalAt ? formatCustomDate(item.finalAt) : '-' }}</p>
          </template>
          <template v-slot:item.statusDesc="{ item }">
            <v-chip
              class="text-body-2"
              style="color: rgb(94, 94, 94);"
              :color="getStatusChipClass(item.statusDesc)"
            >
              {{ item.statusDesc }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
      <v-col class="d-flex flex-column ps-12" style="align-items: center;">
        <br />

        <v-card style="width: 40vw;" elevation="14">
          <div
            class="d-flex justify-space-between"
            style=" background-color: #00529b;"
          >
            <v-card-title style="color: white;">
              Create New Order
            </v-card-title>
            <!-- <v-card-title style="color: #CDDC39; font-style: italic;">
              Form Order #001
            </v-card-title> -->
          </div>
          <div class="px-5 py-5 background-gradient">
          
              <v-form ref="formrequest" class="pa-4">
                <v-row>
                  <v-col>
                    <v-text-field
                      ref="company_name"
                      v-model="state.company_name"
                      label="Company Name"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      ref="vessel_name"
                      v-model="state.vessel_name"
                      label="Vessel Name"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  ref="name"
                  v-model="user.name"
                  label="PIC Name"
                  disabled
                ></v-text-field>

                <v-row>
                  <v-col>
                    <v-text-field
                      ref="email"
                      v-model="user.email"
                      label="Email"
                      type="email"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      ref="telephone"
                      v-model="state.telephone"
                      label="Telephone"
                      :rules="rules.telephone_rules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <div class="text-right">
                  <div>
                    <form-order-dialog
                      @sharingListOrder="getListings"
                    ></form-order-dialog>
                  </div>
                </div>
                
                <small class="font-weight-bold">List Order Product</small>
                <v-data-table
                  class="elevation-5 mt-1"
                  :headers="headersOrder"
                  :items="itemsOrder"
                  hide-default-footer
                  :loading="loading"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>

                <br />
                <br />
                <small>Buat catatan order <span style="font-style: italic;">(Optional)</span></small>
                <v-text-field
                  v-model="state.remarks"
                  label="Remarks"
                ></v-text-field>
                <v-checkbox
                  v-model="state.checkbox"
                  label="*Menyetujui Syarat dan Ketentuan"
                  :rules="rules.required"
                ></v-checkbox>
              </v-form>
          </div>
          <v-card-actions
            class="justify-space-between"
            style="background-color: #00529b; color: white;"
          >
            <div>
              <v-btn
                @click="action('reset')"
                class="me-4"
                color="amber"
                size="small"
                style="text-transform: capitalize;"
              >
                Reset Form
              </v-btn>
            </div>
            <v-btn
              :disabled="!state.checkbox"
              @click="action('request')"
              color="lime"
              style="text-transform: capitalize;"
            >
              Send Request Order
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- DIALOG  -->
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Saved</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Saved</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>



    <v-dialog v-model="dialogFormDetail" persistent width="800"> 
      <orderDetailByCustomerAccount :dataDetails = detailOrderPreview @closeDialogForm = closingDialogForm />
    </v-dialog>
  </div>
</template>

<script>
import {
  vesselsCollection,
  ordersCollection,
  customersCollection,
} from "../../firebase";
import formOrderDialog from "../../components/dialog/orderByCustomerAccount.vue";
import orderDetailByCustomerAccount from '../../components/dialog/orderDetailByCustomerAccount.vue';

export default {
  name: "AdminIndosafetymarineFormOrderCustomers",
  components: {
    formOrderDialog,
    orderDetailByCustomerAccount
  },
  data() {
    return {
      dialog: { show: false, success: false, error: false, errorMessage: "" },
      user: "",
      loading: false,
      dataCustomers: [],
      dialogForm: false,
      dialogFormDetail: false,
      listOrderProductItems: "",
      detailOrderPreview: [],
      orders: [],
      itemsOrder: [],
      dataVessels: [],
      masterParams: [],
      orderProduct: [],
      state: {
        name: "",
        email: "",
        company_name: "",
        vessel_name: "",
        telephone: "",
        select_type_product: null,
        brand: "",
        serial_number: "",
        type: "",
        remarks: "",
        checkbox: null,
      },
      headersOrder: [
        { text: "Product", align: "start", value: "orderProduct" },
        { text: "Product Type", value: "orderProductType" },
        { text: "Product Series", value: "orderProductSeries" },
        { text: "Actions", value: "actions", width: "100px", sortable: false },
      ],
      headers: [
        { text: "No", value: "no" },
        { text: "Order Number", value: "ordersNo" },
        { text: "Product", value: "productsList", width: "100" },
        { text: "Order Date", value: "createdAt", width: "100" },
        { text: "Order Finish", value: "finalAt", width: "100" },
        { text: "Status", value: "statusDesc", align: "center" },
      ],
      rules: {
        required: [(v) => !!v || "field is required"],
        telephone_rules: [
          (v) => !!v || "telephone number is required",
          (v) => /^\+?\d{8,}$/g.test(v) || "telephone number must be valid",
          (v) =>
            /^08\d{8,}$/g.test(v) ||
            "Telephone number must start with '08' and be valid",
        ],
      },
    };
  },

  async mounted() {
    let getUser = await localStorage.getItem("ismUser");
    this.user = JSON.parse(getUser);
    this.getListVessel();
    this.getListOrders();
    this.getListCustomer();
    this.masterParams = JSON.parse(localStorage.getItem("masterParams"));
    this.masterParams.forEach((element) => {
      if (
        element.paramsGroup === "Orders" &&
        element.params === "Orders Product"
      ) {
        this.orderProduct = JSON.parse(element.values);
        const object1 = { orderProduct: "Others" };
        this.orderProduct.push(object1);
      }
    });
  },

  methods: {
    closingDialogForm() {
      this.detailOrderPreview = []
      this.dialogFormDetail = false
    },
    handleClick(item) {
      this.detailOrderPreview = item
      this.dialogFormDetail = true
    },
    getStatusChipClass(statusDesc) {
      switch (statusDesc) {
        case "Waiting for Inspection process":
          return "yellow darken-2"; // Ganti dengan warna Vuetify yang sesuai
        case "Waiting for Inspection Approval":
          return "blue lighten-1"; // Ganti dengan warna Vuetify yang sesuai
        case "Waiting for Quotation process":
          return "green lighten-2"; // Ganti dengan warna Vuetify yang sesuai
        case "Waiting for PO":
          return "red lighten-1"; // Ganti dengan warna Vuetify yang sesuai
        case "Waiting for Certificate process":
          return "purple darken-2"; // Ganti dengan warna Vuetify yang sesuai
        case "Waiting for Delivery Order":
          return "indigo darken-1"; // Ganti dengan warna Vuetify yang sesuai
        case "Rejected":
          return "deep-orange darken-2"; // Ganti dengan warna Vuetify yang sesuai
        case "Done":
          return "green darken-2"; // Ganti dengan warna Vuetify yang sesuai
        default:
          return "grey lighten-2"; // Ganti dengan warna Vuetify yang sesuai
      }
    },
    formatDate(isoDateString, format = "default") {
      const dateObject = new Date(isoDateString);

      switch (format) {
        case "local":
          return dateObject.toLocaleString();
        case "custom":
          return `${dateObject.getDate()}/${dateObject.getMonth() +
            1}/${dateObject.getFullYear()} ${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()}`;
        default:
          return dateObject.toString();
      }
    },
    formatCustomDate(isoDateString) {
      const dateObject = new Date(isoDateString);
      const day = dateObject.getDate();
      const month = dateObject.toLocaleString("en-US", { month: "short" });
      const year = dateObject.getFullYear();

      return `${day} ${month} ${year}`;
    },
    getListings(payload) {
      // Check if the item already exists in the array based on some unique properties
      const existingItem = this.itemsOrder.find((item) => {
        return item.orderProductSeries === payload.orderProductSeries;
      });

      // If the item does not exist, add it to the array
      if (!existingItem) {
        this.itemsOrder.push(payload);
      } else {
        // If the item already exists, do nothing or log a message if needed
        console.log("Item already exists:", payload);
      }
    },
    deleteItem(item) {
      const index = this.itemsOrder.indexOf(item);
      if (index !== -1) {
        this.itemsOrder.splice(index, 1);
      }
    },
    closeDialogComponent() {
      this.dialogForm = false;
    },
    openDialog() {
      this.dialogForm = true;
    },
    getListOrders() {
      this.loading = true;
      const username = this.user.name;
      ordersCollection
        .where("isDeleted", "==", false)
        .where("customerName", "==", username)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              orderId: doc.id,
              ordersNo: doc.data().ordersNo,
              customerName: doc.data().customerName,
              companyName: doc.data().companyName,
              productsList: doc.data().productsList,
              vesselName: doc.data().vesselName,
              statusDesc: doc.data().statusDesc,
              createdAt: doc.data().createdAt,
              finalAt: doc.data().finalAt
            });
          });
          this.orders = newData.sort(function(a, b) {
            return a.createdAt > b.createdAt
              ? -1
              : a.createdAt < b.createdAt
              ? 1
              : 0;
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getListVessel() {
      this.loadingVessel = true;
      vesselsCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              vesselId: doc.id,
              vesselName: doc.data().vesselName,
            });
          });
          this.loadingVessel = false;
          this.dataVessels = newData;
        })
        .catch(() => {
          this.loadingVessel = false;
        });
    },
    getListCustomer() {
      customersCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              customerId: doc.id,
              customerName: doc.data().customerName,
            });
          });
          this.dataCustomers = newData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goToHome() {
      this.$router.push("/customers");
    },

    async action(payload) {
      this.dialog.error = false
      this.dialog.show = false
      const valid = this.$refs.formrequest.validate();
      const itemsOrderValid = this.itemsOrder.length > 0;
      if(!valid || !itemsOrderValid){
        this.dialog.error = true
        this.dialog.errorMessage= "Periksa kembali form anda"
        this.dialog.show = true
        return
      }

      if (payload == "request") {
        this.dialog.show = true;
          let customerId;
          const targetCustomerName = this.user.name;
          const foundCustomer = this.dataCustomers.find(
            (customer) => customer.customerName === targetCustomerName
          );

          // Check if a customer was found
          if (foundCustomer) {
            customerId = foundCustomer.customerId;
          } else {
            this.dialog.error = true;
            this.dialog.errorMessage = `Customer with name ${targetCustomerName} not found`;
          }

          this.state.name = this.user.name;

          let d = new Date();
          let month = d.getMonth();
          let year = d.getFullYear();
          let lastNo = this.orders.length;
          let ordersNo = `10${lastNo + 1}/OR/${month + 1}/${year}`;

          let gasMeter = this.itemsOrder.filter(
            (x) => x.orderProduct === "Gas Meter"
          );
          let utiMeter = this.itemsOrder.filter(
            (x) => x.orderProduct === "UTI Meter"
          );
          let others = this.itemsOrder.filter(
            (x) => x.orderProduct === "Others"
          );
          let productDesc = [];

          if (gasMeter.length > 0) {
            productDesc.push("Gas Meter");
          }
          if (utiMeter.length > 0) {
            productDesc.push("Uti Meter");
          }
          if (others.length > 0) {
            productDesc.push("Others");
          }

          let newData = {
            ordersNo: ordersNo,
            customerId: customerId,
            customerName: this.state.name,
            companyName: this.state.company_name,
            vesselId: 999,
            vesselName: this.state.vessel_name,
            statusCustomer: true,
            description: this.state.remarks,
            products: productDesc.toString(),
            productsList: this.itemsOrder,
            statusOrder: 1,
            statusDesc: "Waiting for Inspection process",
            isDeleted: false,
            createdAt: new Date().toISOString(),
            createdBy: "Customers",
            updatedAt: "",
            updatedBy: "",
            inProgressBy: "",
            inProgressAt: "",
            approvedBy: "",
            approvedAt: "",
            rejectedBy: "",
            rejectedAt: "",
            finalBy: "",
            finalAt: "",
            deletedBy: "",
            deletedAt: "",
          };

          ordersCollection
            .add(newData)
            .then(() => {
              this.getListOrders();
              this.$refs.formrequest.reset();
              this.dialog.success = true;
            })
            .catch(() => {
              this.dialog.error = true;
              this.dialog.errorMessage = "Something Went Wrong";
            });
      }
    },
    async back() {
      this.dialog.show = false;
      this.dialog.success = false;
      this.dialog.error = false;
      this.loading = false;
      this.dialog.errorMessage = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.background-gradient {
  background: linear-gradient(to right, #e4f3ff, #3989cf80);
  /* Gunakan kode warna silver (#c0c0c0) dan hijau (#2ecc71) sesuai keinginan Anda */
  background-size: 200% 100%; /* Atur lebar dan tinggi gradasi */
}
</style>
