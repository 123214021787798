<template>
  <v-card flat>
    <isttoolbar
      title="Orders"
      dark
      color="secondary"
      icon="mdi-folder-account-outline"
    >
      <v-spacer />
      <v-btn @click="save" outlined class="mx-1" min-width="100px">Save</v-btn>
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>
    <isttoolbar
      title="New Order"
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>
    <v-form ref="form" class="mb-10">
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Customer Name
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-autocomplete
            hide-details="auto"
            label="Select Customer"
            @input="setDataCustomer"
            v-model="param.customerName"
            :items="dataCustomers"
            item-value="customerName"
            item-text="customerName"
            ref="customerName"
            class="ma-0"
            :loading="loadingCustomer"
            return-object
            flat
            outlined
            dense
            solo
            clearable
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Vessel Name
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-autocomplete
            hide-details="auto"
            label="Select Vessel"
            @input="setDataVessel"
            v-model="param.vesselName"
            :items="dataVessels"
            item-value="vesselName"
            item-text="vesselName"
            ref="vesselName"
            class="ma-0"
            :loading="loadingVessel"
            return-object
            flat
            outlined
            dense
            solo
            clearable
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Status Customer
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-switch
            ref="statusCustomer"
            name="statusCustomer"
            color="success"
            v-model="param.statusCustomer"
            inset
            :label="`${param.statusCustomer ? 'B2B' : 'End User'}`"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Description
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-textarea
            hide-details="auto"
            v-model="param.description"
            solo
            flat
            outlined
            dense
            ref="description"
            name="input-7-4"
            label="Description"
            :rows="6"
            :rules="[rules.required]"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-data-table
        class="elevation-1 mt-8"
        :headers="headers"
        :items="items"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>List Product</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogCRUD" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                  v-bind="attrs"
                  @click="openDialog"
                >
                  New Product
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                          hide-details="auto"
                          label="Select Product"
                          @input="setDataProduct"
                          v-model="editedItem.orderProduct"
                          :items="orderProduct"
                          item-value="orderProduct"
                          item-text="orderProduct"
                          ref="orderProduct"
                          class="ma-0"
                          return-object
                          flat
                          outlined
                          dense
                          solo
                          clearable
                          :rules="[rules.required]"
                        ></v-autocomplete>
                      </v-col>

                      <v-text-field
                        v-if="openFieldInputProduct === true"
                        hide-details="auto"
                        v-model="editedItem.orderProduct"
                        label="Product Name"
                        ref="orderProduct"
                        class="ma-0 mt-3 pr-3 pl-3"
                        flat
                        outlined
                        dense
                        solo
                        :rules="[rules.required]"
                      ></v-text-field>
                      

                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                        v-if="openFieldInputProduct === true"
                        hide-details="auto"
                        v-model="editedItem.orderProductType"
                        label="Product Series"
                        ref="orderProductType"
                        class="ma-0"
                        flat
                        outlined
                        dense
                        solo
                        :rules="[rules.required]"
                      ></v-text-field>
                        <!-- v-else -->
                        <v-autocomplete
                        v-if="openFieldInputProduct === false"
                          hide-details="auto"
                          label="Select Product Type"
                          @input="setDataProductType"
                          v-model="editedItem.orderProductType"
                          :items="orderProductType"
                          item-value="orderProductType"
                          item-text="orderProductType"
                          ref="orderProductType"
                          class="ma-0"
                          return-object
                          flat
                          outlined
                          dense
                          solo
                          clearable
                          :disabled="editedItem.orderProduct === ''"
                          :rules="[rules.required]"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                        v-if="openFieldInputProduct === true"
                        hide-details="auto"
                        v-model="editedItem.orderProductSeries"
                        label="Product Series"
                        ref="orderProductSeries"
                        class="ma-0"
                        flat
                        outlined
                        dense
                        solo
                        :rules="[rules.required]"
                      ></v-text-field>
                        <!-- v-else -->
                        <v-autocomplete
                        v-if="openFieldInputProduct === false"
                          hide-details="auto"
                          label="Select Product Series"
                          @input="setDataProductSeries"
                          v-model="editedItem.orderProductSeries"
                          :items="orderProductSeries"
                          item-value="params"
                          item-text="params"
                          ref="orderProductSeries"
                          class="ma-0"
                          return-object
                          flat
                          outlined
                          dense
                          solo
                          clearable
                          :disabled="editedItem.orderProduct === ''"
                          :rules="[rules.required]"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          hide-details="auto"
                          v-model="editedItem.orderProductSN"
                          label="Product Serial Number"
                          ref="orderProductSN"
                          class="ma-0"
                          flat
                          outlined
                          dense
                          solo
                          :disabled="editedItem.orderProduct === ''"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-textarea
                          hide-details="auto"
                          v-model="editedItem.notes"
                          label="Notes"
                          ref="notes"
                          class="ma-0"
                          flat
                          outlined
                          dense
                          solo
                          :rows="3"
                          :disabled="editedItem.orderProduct === ''"
                          :rules="[rules.required]"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined @click="newItem">
                    Save
                  </v-btn>
                  <v-btn color="blue darken-1" outlined @click="close">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              persistent
              width="unset"
              min-width="290px"
            >
              <v-card min-width="290px">
                <v-card-title
                  class="justify-center"
                  style="word-break: inherit;"
                  >Are you sure want to Delete ?</v-card-title
                >
                <v-card-actions class="justify-center">
                  <v-btn
                    color="primary"
                    min-width="100px"
                    @click="deleteItemConfirm"
                    outlined
                    >Yes</v-btn
                  >
                  <v-btn
                    color="red"
                    min-width="100px"
                    @click="closeDelete"
                    outlined
                    >No</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data></template>
      </v-data-table>
    </v-form>

    <!-- DIALOG -->
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Saved</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Saved</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import {
  customersCollection,
  vesselsCollection,
  ordersCollection,
} from "../../../firebase";

export default {
  components: {
    isttoolbar,
  },
  props: {
    source: String,
  },

  data: () => ({
    openFieldInputProduct: false,
    masterParams: [],
    orderProduct: [],
    orderProductType: [],
    orderProductSeries: [],
    loadingCustomer: false,
    dataCustomers: [],
    loadingVessel: false,
    dataVessels: [],
    param: {
      customerId: "",
      customerName: "",
      vesselId: "",
      vesselName: "",
      statusCustomer: true,
      description: "",
      products: "",
      productsList: [],
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    items: [],
    headers: [
      { text: "Product", align: "start", value: "orderProduct" },
      { text: "Product Type", value: "orderProductType" },
      { text: "Product Series", value: "orderProductSeries" },
      { text: "Product Serial Number", value: "orderProductSN" },
      { text: "Notes", value: "notes" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    dialogCRUD: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      orderProduct: "",
      orderProductType: "",
      orderProductSeries: "",
      orderProductSN: "",
      notes: "",
      statusWorkForm: false,
    },
    defaultItem: {
      orderProduct: "",
      orderProductType: "",
      orderProductSeries: "",
      orderProductSN: "",
      notes: "",
      statusWorkForm: false,
    },
    orders: [],
  }),
  computed: {
    form() {
      return {
        customerName: this.param.customerName,
        vesselName: this.param.vesselName,
        description: this.param.description,
        orderProduct: this.editedItem.orderProduct,
        orderProductType: this.editedItem.orderProductType,
        orderProductSeries: this.editedItem.orderProductSeries,
        orderProductSN: this.editedItem.orderProductSN,
        notes: this.editedItem.notes,
      };
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Product" : "Edit Product";
    },
  },
  watch: {
    dialogCRUD(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.getListCustomer();
    this.getListVessel();
    this.getListOrders();
    this.masterParams = JSON.parse(localStorage.getItem("masterParams"));
    this.masterParams.forEach((element) => {
      if (
        element.paramsGroup === "Orders" &&
        element.params === "Orders Product"
      ) {
        this.orderProduct = JSON.parse(element.values);
        const object1 = {"orderProduct" : 'Others'}
        console.log('cek product :', this.orderProduct)
        this.orderProduct.push(object1)
      }
    });
  },
  methods: {
    back() {
      this.$router.replace({
        name: "orders-list",
      });
    },
    getListOrders() {
      ordersCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              orderId: doc.id,
              ordersNo: doc.data().ordersNo,
            });
          });
          this.orders = newData.sort(function(a, b) {
            return a.createdAt > b.createdAt
              ? -1
              : a.createdAt < b.createdAt
              ? 1
              : 0;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async save() {
      Object.keys(this.form).forEach((f) => {
        if (f === "customerName" || f === "vesselName" || f === "description") {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
        }
      });
      if (!this.formHasErrors) {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        if (this.items.length === 0) {
          this.dialog.error = true;
          this.dialog.errorMessage = "Field list product must be filled";
        } else {
          await this.doAdd();
        }
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
     let d = new Date();
          let month = d.getMonth();
          let year = d.getFullYear();
          let lastNo = this.orders.length;
          let ordersNo = `10${lastNo + 1}/OR/${month + 1}/${year}`;

          let gasMeter = this.items.filter((x) => x.orderProduct === "Gas Meter");
          let utiMeter = this.items.filter((x) => x.orderProduct === "UTI Meter");
          let others = this.items.filter((x) => x.orderProduct === "Others");
          let productDesc = [];

          if (gasMeter.length > 0) {
            productDesc.push("Gas Meter");
          }
          if (utiMeter.length > 0) {
            productDesc.push("Uti Meter");
          }
          if (others.length > 0) {
            productDesc.push("Others");
          }

          const newData = {
            ordersNo: ordersNo,
            customerId: this.param.customerId,
            customerName: this.param.customerName,
            vesselId: this.param.vesselId,
            vesselName: this.param.vesselName,
            statusCustomer: this.param.statusCustomer,
            description: this.param.description,
            products: productDesc.toString(),
            productsList: this.items,
            statusOrder: 1,
            statusDesc: "Waiting for Inspection process",
            isDeleted: false,
            createdAt: new Date().toISOString(),
            createdBy: "Admin",
            updatedAt: "",
            updatedBy: "",
            inProgressBy: "",
            inProgressAt: "",
            approvedBy: "",
            approvedAt: "",
            rejectedBy: "",
            rejectedAt: "",
            finalBy: "",
            finalAt: "",
            deletedBy: "",
            deletedAt: "",
          };
          ordersCollection
            .add(newData)
            .then(() => {
              this.dialog.success = true;
            })
            .catch(() => {
              this.dialog.error = true;
              this.dialog.errorMessage = "Something Went Wrong";
            });
    },
    setDataCustomer(item) {
      if (item === null) {
        this.param.customerId = "";
        this.param.customerName = "";
      } else {
        this.param.customerId = item.customerId;
        this.param.customerName = item.customerName;
      }
    },
    getListCustomer() {
      this.loadingCustomer = true;
      customersCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              customerId: doc.id,
              customerName: doc.data().customerName,
            });
          });
          this.loadingCustomer = false;
          this.dataCustomers = newData;
        })
        .catch(() => {
          this.loadingCustomer = false;
        });
    },
    setDataVessel(item) {
      if (item === null) {
        this.param.vesselId = "";
        this.param.vesselName = "";
      } else {
        this.param.vesselId = item.vesselId;
        this.param.vesselName = item.vesselName;
      }
    },
    getListVessel() {
      this.loadingVessel = true;
      vesselsCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              vesselId: doc.id,
              vesselName: doc.data().vesselName,
            });
          });
          this.loadingVessel = false;
          this.dataVessels = newData;
        })
        .catch(() => {
          this.loadingVessel = false;
        });
    },
    setDataProduct(item) {
      if (item === null) {
        this.editedItem.orderProduct = "";
      } else {
        if(item.orderProduct === "Others"){
          this.editedItem.orderProduct = ''
          this.openFieldInputProduct = true
        } else {
          this.openFieldInputProduct = false
          this.editedItem.orderProduct = item.orderProduct;
          this.masterParams.forEach((element) => {
            if (
              element.paramsGroup === "Orders Product Type" &&
              element.params === item.orderProduct
            ) {
              this.orderProductType = JSON.parse(element.values);
            }
          });
        }
      }
      this.editedItem.orderProductType = "";
      this.editedItem.orderProductSeries = "";
    },
    setDataProductType(item) {
      if (item === null) {
        this.editedItem.orderProductType = "";
      } else {
        this.editedItem.orderProductType = item.orderProductType;
        let newData = this.masterParams.filter(function(el) {
          return el.paramsGroup === item.orderProductType;
        });
        this.orderProductSeries = newData;
      }
      this.editedItem.orderProductSeries = "";
    },
    setDataProductSeries(item) {
      if (item === null) {
        this.editedItem.orderProductSeries = "";
      } else {
        this.editedItem.orderProductSeries = item.params;
        const obj = JSON.parse(item.values)
        Object.assign(this.editedItem, JSON.parse(obj));
        // Object.assign(this.editedItem, JSON.parse(item.values));
      }
    },
    openDialog() {
      this.dialogCRUD = true;
      setTimeout(() => {
        Object.keys(this.form).forEach((f) => {
          if (
            f === "orderProduct" ||
            f === "orderProductType" ||
            f === "orderProductSeries" ||
            f === "orderProductSN" ||
            f === "notes"
          ) {
            this.$refs[f].reset();
          }
        });
        this.editedItem = Object.assign({}, this.defaultItem);
      }, 50);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogCRUD = true;
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.dialogCRUD = false;
      this.formHasErrors = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    newItem() {
      Object.keys(this.form).forEach((f) => {
        if (
          f === "orderProduct" ||
          f === "orderProductType" ||
          f === "orderProductSeries" ||
          f === "orderProductSN" ||
          f === "notes"
        ) {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
        }
      });
      if (!this.formHasErrors) {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
        } else {
          this.items.push(this.editedItem);
        }
        this.close();
      } else {
        this.formHasErrors = false;
      }
    },
  },
};
</script>