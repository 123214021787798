<template>
  <v-dialog v-model="dialog" persistent max-width="1200px">
    <v-form ref="form" :readonly="this.$route.query.statusPage === 'view'">
      <v-card>
        <isttoolbar
          title="Work Form Detail"
          dark
          color="secondary"
          icon="mdi-briefcase"
        />
        <v-container>
          <v-row class="mt-1">
            <v-col cols="6">
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProduct"
                    :value="data.orderProduct"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product Type </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProductType"
                    :value="data.orderProductType"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product Series </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProductSeries"
                    :value="data.orderProductSeries"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product Serial Number </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProductSN"
                    :value="data.orderProductSN"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pa-1">
                  <span class="title"> Calibration </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="calibrationName"
                    :value="data.calibrationName"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mb-4 mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Inspection </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="inspeactionName"
                    :value="data.inspectionName"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
             
            </v-col>
            <v-col cols="6">
              <!-- JIKA DATANYA ADALAH GAS METER -->
              <div v-if="data.orderProduct == 'Gas Meter'">
                <v-row align="center">
                  <v-col cols="12" class="pa-1" style="text-align: center">
                    <span class="title"> Reference Gas </span>
                  </v-col>
                </v-row>
                <v-row align="center" class="mr-2 ml-2">
                  <v-col cols="12" class="pa-1">
                    <v-data-table
                      :headers="headers"
                      :items="dataGas"
                      class="elevation-1"
                      :options.sync="options"
                      :server-items-length="Number(data.totalGas)"
                      hide-default-footer
                    >
                      <template v-slot:[`item.lot`]="{ item }">
                        <v-text-field
                          v-model="item.lot"
                          hide-details="auto"
                          class="ma-0"
                          ref="lot"
                          flat
                          outlined
                          dense
                          solo
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.cyl`]="{ item }">
                        <v-text-field
                          v-model="item.cyl"
                          hide-details="auto"
                          class="ma-0"
                          ref="cyl"
                          flat
                          outlined
                          dense
                          solo
                        ></v-text-field>
                      </template>
                      <template v-slot:no-data></template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>

              <!-- JIKA DATANYA ADALAH UTI METER -->
              <div v-if="data.orderProduct == 'UTI Meter'">
                <v-row align="center" class="mr-2 ml-2">
                  <v-col cols="4" class="pa-1">
                    <span class="title"> Tape Length </span>
                  </v-col>
                  <v-col cols="8" align-self="start" class="pa-1">
                    <v-row style="display: flex; flex-direction: column">
                      <v-col
                        style="display: flex; flex-direction: row; gap: 20px"
                      >
                        <v-checkbox
                          dense
                          v-model="checkbox.tl10"
                          label="10 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl15"
                          label="15 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl20"
                          label="20 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl25"
                          label="25 M"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        style="display: flex; flex-direction: row; gap: 20px"
                      >
                        <v-checkbox
                          dense
                          v-model="checkbox.tl30"
                          label="30 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl35"
                          label="35 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl40"
                          label="40 M"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <br />

                <v-row align="center" class="mr-2 ml-2">
                  <v-col cols="4" class="pa-1">
                    <span class="title"> Valve Connection </span>
                  </v-col>
                  <v-col cols="8" align-self="start" class="pa-1">
                    <v-row style="display: flex; flex-direction: column">
                      <v-col
                        style="display: flex; flex-direction: row; gap: 20px"
                      >
                        <v-checkbox
                          dense
                          v-model="checkbox.vcf50"
                          label="F/C50"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.vcmb"
                          label="MB"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.vcb"
                          label="B"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        style="display: flex; flex-direction: row; gap: 20px"
                      >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <isttoolbar
          title="Inspection Result"
          class="mb-2 mt-2"
          dark
          color="primary"
        ></isttoolbar>

        <v-data-table
          class="elevation-1 mt-2 mb-2 w-md-80 pa pa-md mr-10 ml-10"
          :headers="headersInspector"
          :items="dataInspector"
        >
          <template v-slot:item.checkbox="{ item }">
            <v-checkbox v-model="item.checked" />
          </template>
          <template v-slot:no-data></template>
        </v-data-table>

        <v-row align="center" class="mr-8 ml-8 mt-4 mb-4">
          <v-col cols="2" class="pa-1">
            <span class="title">Report Type </span>
          </v-col>
          <v-col align-self="start" class="pa-1">
            <v-textarea
              hide-details="auto"
              v-model="reportType"
              ref="engineer-notes"
              class="ma-0"
              flat
              outlined
              dense
              solo
              :rows="3"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-card-actions class="mr-8">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="saveNewInspection()">
            Save
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="$emit('cancel')">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";

export default {
  components: {
    isttoolbar,
  },
  props: ["dialog", "data"],
  data: () => ({
    checkbox: {
      tl10: false,
      tl15: false,
      tl20: false,
      tl25: false,
      tl30: false,
      tl35: false,
      tl40: false,
      vcf50: false,
      vcmb: false,
      vcb: false,
    },
    workformInspection: [],
    headers: [
      {
        text: "Lot",
        align: "start",
        sortable: false,
        value: "lot",
      },
      {
        text: "Cyl",
        align: "center",
        sortable: false,
        value: "cyl",
      },
      {
        text: "Gas Type",
        align: "center",
        sortable: false,
        value: "gasType",
      },
    ],
    inspectorDescription: "",
    dataGas: [],
    options: {
      page: 0,
      size: -1,
    },
    headersInspector: [
      {
        text: "No",
        align: "start",
        value: "no",
      },
      // {
      //   text: "Jenis",
      //   value: "type",
      // },
      {
        text: "Report Types",
        value: "reportType",
        width: "60%",
      },
      {
        text: "Option",
        value: "checkbox",
      },
    ],
    dataInspector: [],
    reportType: ""
  }),
  watch: {
    dialog: {
      handler: function (after) {
        if (after) {
          let newArray = [];
          let product = this.data.orderProduct;
          if (product == "Gas Meter") {
            this.data.listGas.forEach((element) => {
              if(!element.lot && !element.cyl){
                element.lot = "";
                element.cyl = "";
              }
              newArray.push({ ...element });
            });
            this.dataGas = newArray;
          }

          if (product == "UTI Meter") {
            if (this.data.tapeLength == "10") {
              this.checkbox.tl10 = true;
            } else if (this.data.tapeLength == "15") {
              this.checkbox.tl15 = true;
            } else if (this.data.tapeLength == "20") {
              this.checkbox.tl20 = true;
            } else if (this.data.tapeLength == "25") {
              this.checkbox.tl25 = true;
            } else if (this.data.tapeLength == "30") {
              this.checkbox.tl30 = true;
            } else if (this.data.tapeLength == "35") {
              this.checkbox.tl35 = true;
            } else if (this.data.tapeLength == "40") {
              this.checkbox.tl40 = true;
            }

            if (this.data.valveConector == "20") {
              this.checkbox.vcf50 = true;
            } else if (this.data.tapeLength == "MB") {
              this.checkbox.vcmb = true;
            } else if (this.data.tapeLength == "B") {
              this.checkbox.vcb = true;
            }
          }

          if (product == "Other") {
            console.log(this.data);
          }

          let newArrayInspector = [];
          this.data.inspectionReport.forEach((el) => {
            if (el.checked != true) {
              el.checked = "";
            }
            el.jenis = "inspection";
            newArrayInspector.push({ ...el });
          });
          this.dataInspector = newArrayInspector;
        }
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    saveNewInspection() {
      this.data.reportType = this.reportType
      this.data.listGas = this.dataGas
      this.data.inspectionReport = this.dataInspector;
      this.$emit("save");
    },
  },
};
</script>
