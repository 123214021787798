<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" :readonly="this.$route.query.statusPage === 'view'">
      <v-card>
        <isttoolbar
          title="Edit Certificate"
          dark
          color="secondary"
          icon="mdi-format-list-checkbox"
        />
        <v-container>
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">
                Certificate Name
              </span>
            </v-col>
            <v-col cols="8" align-self="start" class="pa-1">
              <v-text-field
                v-model="certificateType"
                hide-details="auto"
                ref="orderProduct"
                solo
                flat
                outlined
                dense
                class="ma-0"
                @keydown.enter="saveEditCertificate()"
              ></v-text-field>
              <span class="error--text" v-if="noInput"
                >Please insert to the field!</span
              >
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="saveEditCertificate()">
            Save
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="$emit('cancel')">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";

export default {
  components: {
    isttoolbar,
  },
  props: ["dialog", "data"],
  data: () => ({
    certificateType: "",
    noInput: false,
  }),
  watch: {
    dialog: {
      handler: function(after) {
        if (after) {
          this.certificateType = this.data.certificateType;
        }
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    saveEditCertificate() {
      event.preventDefault();
      if (!this.certificateType.trim()) {
        this.noInput = true;
        return;
      } else {
        this.noInput = false;
      }
      this.data.certificateType = this.certificateType;
      this.certificateType = "";
      this.noInput = false;
      this.$emit("save");
    },
  },
};
</script>
