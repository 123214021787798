<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <img
            src="@/assets/images/vector-login.png"
            style="right: 0; bottom: 0; position: absolute"
          />
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-0">
              <v-img
                src="@/assets/images/logo-ism.png"
                aspect-ratio="3"
                contain
              ></v-img>
              <v-spacer></v-spacer>
              <v-toolbar color="white" dark flat>
                <v-toolbar-title style="width: 100%" class="text-center">
                  <span class="primary--text text-xl-h4"
                    >Create New Account</span
                  >
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-0">
                <v-form v-on:submit.prevent="login()">
                  <v-text-field
                    label="Name"
                    placeholder="Input your Name"
                    name="name"
                    type="text"
                    ref="name"
                    v-model="param.name"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    label="Username"
                    placeholder="Input your Name"
                    name="username"
                    type="text"
                    ref="username"
                    v-model="param.username"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    label="Email"
                    placeholder="Input your Email"
                    name="email"
                    type="email"
                    ref="email"
                    v-model="param.email"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    label="Password"
                    ref="password"
                    name="password"
                    v-model="param.password"
                    :value="param.password"
                    :type="statusPassword ? 'password' : 'text'"
                    :append-icon="statusPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (statusPassword = !statusPassword)"
                    @input="(_) => (param.password = _)"
                    :rules="[rules.required, rules.password]"
                  ></v-text-field>
                  <v-text-field
                    label="Confirm Password"
                    ref="confirmPassword"
                    name="confirmPassword"
                    hint="At least 8 characters"
                    v-model="param.confirmPassword"
                    :value="param.confirmPassword"
                    :type="statusConfirm ? 'password' : 'text'"
                    :append-icon="statusConfirm ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (statusConfirm = !statusConfirm)"
                    @input="(_) => (param.confirmPassword = _)"
                    :rules="[rules.required, rules.password]"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions style="justify-content: center; display: flex;">
                <v-btn
                  color="primary"
                  style=" width: 15vw;"
                  :rounded="true"
                  @click="save"
                  :loading="isLoading"
                >
                  Register
                </v-btn>
              </v-card-actions>
              <br />
              <p
                class="clickLogin text-body-1"
                style="justify-content: center; display: flex; cursor: pointer;"
                @click="toLogin"
              >
                Back to Login
              </p>
            </v-card>
            <v-row justify="center">
              <v-dialog v-model="dialog.show" persistent max-width="290">
                <v-card v-show="!dialog.success && !dialog.error">
                  <v-card-title>
                    <v-progress-linear
                      indeterminate
                      color="primary"
                    ></v-progress-linear>
                  </v-card-title>
                </v-card>
                <v-card v-show="dialog.success || dialog.error">
                  <v-card-title
                    v-if="dialog.success"
                    class="justify-center"
                    style="word-break: inherit"
                    >Your Data has been Saved</v-card-title
                  >
                  <v-card-title
                    v-else-if="dialog.error"
                    class="justify-center"
                    style="word-break: inherit"
                    >Your Data Failed to Saved</v-card-title
                  >
                  <v-card-text
                    v-if="dialog.error"
                    class="d-flex justify-center error--text"
                    style="word-break: inherit"
                  >
                    {{ dialog.errorMessage }}
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      color="primary"
                      min-width="100px"
                      outlined
                      @click="dialog.success ? back() : (dialog.show = false)"
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { firebaseDB, firebaseAuth } from "../../firebase";

export default {
  props: {
    source: String,
  },
  data() {
    return {
      statusUserTaken: false,
      statusPassword: true,
      statusConfirm: true,
      param: {
        role: "",
        name: "",
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      show1: false,
      formHasErrors: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        password: (value) => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/;
          return (
            pattern.test(value) ||
            "Min. 8 characters with at least one capital letter, a number and a special character."
          );
        },
      },
      isLoading: false,
      dialog: { show: false, success: false, error: false, errorMessage: "" },
    };
  },
  computed: {
    form() {
      return {
        name: this.param.name,
        username: this.param.username,
        email: this.param.email,
        password: this.param.password,
        confirmPassword: this.param.confirmPassword,
      };
    },
  },
  created() {},
  methods: {
    toLogin() {
      this.$router.replace({ name: "loginCust" });
    },
    async save() {
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        console.log(this.$refs);
        this.$refs[f].validate(true);
      });
      if (!this.formHasErrors) {
        if (this.param.password !== this.param.confirmPassword) {
          this.dialog.show = true;
          this.dialog.error = true;
          this.dialog.errorMessage = "Password does not match";
        } else {
          this.dialog.success = false;
          this.dialog.error = false;
          this.dialog.show = true;
          await this.doAdd();
        }
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      const newData = {
        email: this.param.email,
        password: this.param.password,
        confirmPassword: this.param.confirmPassword,
        username: this.param.username,
        name: this.param.name,
        role: "customer",
        isActive: true,
        createdAt: new Date().toISOString(),
        createdBy: "Customer",
        updatedBy: "",
        updatedAt: "",
      };
      firebaseDB
        .doc(`/users/${newData.username}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.statusUserTaken = true;
          } else {
            return firebaseAuth.createUserWithEmailAndPassword(
              newData.email,
              newData.password
            );
          }
        })
        .then((data) => {
          let userId = data.user.uid;
          newData.userId = userId;
          return firebaseDB.doc(`/users/${newData.username}`).set(newData);
        })
        .then(() => {
          this.dialog.success = true;
          setTimeout(() => {
            this.$router.replace({ name: "loginCust" });
          }, 500);
        })
        .catch(() => {
          if (this.statusUserTaken) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Username is already taken";
          } else {
            this.dialog.error = true;
            this.dialog.errorMessage = "Somthing Went Wrong";
          }
        });
    },
  },
};
</script>

<style scoped>
.clickLogin:hover {
  color: #0d3c86 !important;
}
</style>
