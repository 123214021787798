import Vue from 'vue';
import Vuex from 'vuex';

import invoice from './modules/invoice/invoice';
import claim from './modules/claim/claim';
import polis from './modules/polis/polis';
import remittance from "./modules/remittance/remittance"
import premiumFunding from "./modules/premiumFunding/premiumFunding"
import snackbars from "./modules/snackbars/snackbars"

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        invoice,
        claim,
        polis,
        remittance,
        premiumFunding,
        snackbars
    }
});