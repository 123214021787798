import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "@/core/routes";
import store from "./store/store";
import currencyHelper from "@/helper/currencyHelper";
import authHelper from "@/helper/authHelper";

Vue.config.productionTip = false;

new Vue({
  mixins: [currencyHelper, authHelper],
  vuetify,
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
