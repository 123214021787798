<template>
  <v-dialog v-model="dialog" persistent width="1024">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" @click="openDialog">
        Add Product Order
      </v-btn>
    </template>

    <v-card>
      <isttoolbar
        title="Add Product"
        dark
        color="secondary"
        icon="mdi-package-variant-plus"
      />
      <v-card-text>
        <v-container>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    hide-details="auto"
                    label="Select Product"
                    @input="setDataProduct"
                    v-model="editedItem.orderProduct"
                    :items="orderProduct"
                    item-value="orderProduct"
                    item-text="orderProduct"
                    ref="orderProduct"
                    class="ma-0"
                    return-object
                    flat
                    outlined
                    dense
                    solo
                    clearable
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>

                <v-text-field
                  v-if="openFieldInputProduct === true"
                  hide-details="auto"
                  v-model="editedItem.orderProduct"
                  label="Product Name"
                  ref="orderProduct"
                  class="ma-0 mt-3 pr-3 pl-3"
                  flat
                  outlined
                  dense
                  solo
                  :rules="[rules.required]"
                ></v-text-field>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-if="openFieldInputProduct === true"
                    hide-details="auto"
                    v-model="editedItem.orderProductType"
                    label="Product Series"
                    ref="orderProductType"
                    class="ma-0"
                    flat
                    outlined
                    dense
                    solo
                    :rules="[rules.required]"
                  ></v-text-field>
                  <!-- v-else -->
                  <v-autocomplete
                    v-if="openFieldInputProduct === false"
                    hide-details="auto"
                    label="Select Product Type"
                    @input="setDataProductType"
                    v-model="editedItem.orderProductType"
                    :items="orderProductType"
                    item-value="orderProductType"
                    item-text="orderProductType"
                    ref="orderProductType"
                    class="ma-0"
                    return-object
                    flat
                    outlined
                    dense
                    solo
                    clearable
                    :disabled="editedItem.orderProduct === ''"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-if="openFieldInputProduct === true"
                    hide-details="auto"
                    v-model="editedItem.orderProductSeries"
                    label="Product Series"
                    ref="orderProductSeries"
                    class="ma-0"
                    flat
                    outlined
                    dense
                    solo
                    :rules="[rules.required]"
                  ></v-text-field>
                  <!-- v-else -->
                  <v-autocomplete
                    v-if="openFieldInputProduct === false"
                    hide-details="auto"
                    label="Select Product Series"
                    @input="setDataProductSeries"
                    v-model="editedItem.orderProductSeries"
                    :items="orderProductSeries"
                    item-value="params"
                    item-text="params"
                    ref="orderProductSeries"
                    class="ma-0"
                    return-object
                    flat
                    outlined
                    dense
                    solo
                    clearable
                    :disabled="editedItem.orderProduct === ''"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    hide-details="auto"
                    v-model="editedItem.orderProductSN"
                    label="Product Serial Number"
                    ref="orderProductSN"
                    class="ma-0"
                    flat
                    outlined
                    dense
                    solo
                    :disabled="editedItem.orderProduct === ''"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="closeDialog">
          Close
        </v-btn>
        <v-btn color="blue-darken-1" variant="text" @click="saveChanges">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
export default {
  name: "AdminIndosafetymarineOrderByCustomerAccount",
  components: {
    isttoolbar,
  },
  computed: {
    form() {
      return {
        orderProduct: this.editedItem.orderProduct,
        orderProductType: this.editedItem.orderProductType,
        orderProductSeries: this.editedItem.orderProductSeries,
        orderProductSN: this.editedItem.orderProductSN,
      };
    },
  },
  data() {
    return {
      openFieldInputProduct: false,
      editedItem: {
        orderProduct: "",
        orderProductType: "",
        orderProductSeries: "",
        orderProductSN: "",
        notes: "",
        statusWorkForm: false,
      },
      dialog: false,
      // ... other data properties ...
      rules: {
        required: (value) => !!value || "Required.",
      },
      masterParams: [],
      orderProduct: [],
      orderProductType: [],
      orderProductSeries: [],
    };
  },
  created() {
    this.masterParams = JSON.parse(localStorage.getItem("masterParams"));
    this.masterParams.forEach((element) => {
      if (
        element.paramsGroup === "Orders" &&
        element.params === "Orders Product"
      ) {
        this.orderProduct = JSON.parse(element.values);
        const object1 = { orderProduct: "Others" };
        this.orderProduct.push(object1);
      }
    });
  },
  mounted() {},

  methods: {
    setDataProduct(item) {
      if (item === null) {
        this.editedItem.orderProduct = "";
      } else {
        if (item.orderProduct === "Others") {
          this.editedItem.orderProduct = "";
          this.openFieldInputProduct = true;
        } else {
          this.openFieldInputProduct = false;
          this.editedItem.orderProduct = item.orderProduct;
          this.masterParams.forEach((element) => {
            if (
              element.paramsGroup === "Orders Product Type" &&
              element.params === item.orderProduct
            ) {
              this.orderProductType = JSON.parse(element.values);
            }
          });
        }
      }
      this.editedItem.orderProductType = "";
      this.editedItem.orderProductSeries = "";
    },
    setDataProductType(item) {
      if (item === null) {
        this.editedItem.orderProductType = "";
      } else {
        this.editedItem.orderProductType = item.orderProductType;
        let newData = this.masterParams.filter(function(el) {
          return el.paramsGroup === item.orderProductType;
        });
        this.orderProductSeries = newData;
      }
      this.editedItem.orderProductSeries = "";
    },
    setDataProductSeries(item) {
      if (item === null) {
        this.editedItem.orderProductSeries = "";
      } else {
        this.editedItem.orderProductSeries = item.params;
        const obj = JSON.parse(item.values);
        Object.assign(this.editedItem, JSON.parse(obj));
      }
    },
    openDialog() {
      this.dialog = true;
      this.resetForm();
    },
    closeDialog() {
      this.dialog = false;
    },
    saveChanges() {
      this.$emit("sharingListOrder", this.editedItem);
      this.dialog = false;
    },
    resetForm() {
      this.openFieldInputProduct = false;
      this.editedItem = {
        orderProduct: "",
        orderProductType: "",
        orderProductSeries: "",
        orderProductSN: ""
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
